import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const CORELINE = 'coreline';
const MANAGER = 'manager'

//#region GET
export const getCoreLines = async ({ header }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MANAGER}/${CORELINE}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getCoreLineById = async ({ header, coreLineId }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MANAGER}/${CORELINE}/${coreLineId}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region POST
export const createCoreLine = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MANAGER}/${CORELINE}`; 
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateCoreLine = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MANAGER}/${CORELINE}`; 
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

//#region DELETE
export const deleteCoreLine = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MANAGER}/${CORELINE}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};
//#endregion

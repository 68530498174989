import * as docs from './docs';
import * as classification from './classification';
import * as category from './category';
import * as author from './author';


export default {
  docs,
  classification,
  category,
  author,
};

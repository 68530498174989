import React              from 'react';
import BlockUi            from 'react-block-ui';
import Loader             from 'react-loaders';
import Grid               from '@material-ui/core/Grid';
import { withStyles }     from '@material-ui/core/styles';

import palettes   from '@Constants/palletes';
import { styles } from './style';

const AppAsyncLoading = props => {
  const { classes, error, timedOut, pastDelay } = props;

  if (error) {
    return <div className={ classes.errorPage }> Ocorreu um erro! :( <br/> tente recarregar a página Novamente!</div>
  }
  else if (timedOut) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <BlockUi
            tag="div"
            message="Esta demorando mais do que o previsto, aguarde...."
            className={ classes.blockUi }
            blocking
            loader={ <Loader active type="line-scale" color={palettes.colors.first[500]} /> }
          />
        </Grid>
      </Grid>
    );
  }
  else if (pastDelay) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <BlockUi
            tag="div"
            message="Carregando Pré Configurações...."
            className={ classes.blockUi }
            blocking
            loader={ <Loader active type="line-scale" color={palettes.colors.first[500]} /> }
          />
        </Grid>
      </Grid>
    );
  }
  else {
    return null;
  }
};

export default withStyles(styles)(AppAsyncLoading);
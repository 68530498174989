import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Moment from 'moment';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale-provider/pt_BR';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { IntlProvider } from 'react-intl'
import messages from '@Util/messages'


import store from './state';
import { history } from './state';
import theme from './themeMaterial';

import 'typeface-roboto';
import 'moment/locale/pt-br';
import 'semantic-ui-css/semantic.css';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { unregister } from './registerServiceWorker';
import registerTimeZone from './registerTimeZone';
import App from './App';

Moment.locale('pt-br');

const rootElement = document.getElementById('app-root');
const locale = navigator.language || navigator.userLanguage;


const Root = props => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <IntlProvider>
          <App />
        </IntlProvider>
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>
);

render(<Root />, rootElement);
unregister();
registerTimeZone();

if (process.env.NODE_ENV !== "production") {
  if (module.hot) {
    module.hot.accept('./App', () => render(<Root />, rootElement))
  }
}

import * as endpoints     from '@App/constants/endpoints';
import interceptor        from '../interceptor';
import { get }            from '../util';
import { post }           from '../util';
import { put }            from '../util';

const FIELD               = 'field';
const RADIO               = 'radio';
const CHECK               = 'checkbox';
const MODEL               = 'model';
const GROUP               = 'group';
const VERSION             = 'version';
const INFO_LABEL          = 'infolabel';
const HISTORY             = 'history';
const NUMERIC             = 'numeric';
const SCORES              = 'scores';
const PATIENT             = 'patient';
const CHECKBOX_GROUP      = 'checkbox_group';
const CHECK_ONLY          = 'check_only';
const CHECK_AVALIABILITY  = 'availability';
const MODEL_FIELDS        = 'modelFields';
const MANAGER             = 'manager';

//#region GET
export const getFields = async ({header}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getModelFields = async ({header}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${MODEL_FIELDS}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelById = async ({header, idVersionField}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${VERSION}/${idVersionField}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdFromManager = async ({header, idVersionField}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${VERSION}/${idVersionField}/${MANAGER}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryNumeric = async ({header, fieldModelId, patientId}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldModelId}/${HISTORY}/${NUMERIC}/${PATIENT}/${patientId}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryScores = async ({header, fieldModelId, patientId}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldModelId}/${HISTORY}/${SCORES}/${PATIENT}/${patientId}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryRadioGroup = async ({header, fieldModelId, patientId}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldModelId}/${HISTORY}/${RADIO}/${PATIENT}/${patientId}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryCheckboxGroup = async ({header, fieldModelId, patientId}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldModelId}/${HISTORY}/${CHECKBOX_GROUP}/${PATIENT}/${patientId}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
  };

  export const getFieldModelByIdHistoryCheckOnly = async ({header, fieldModelId, patientId}) => {
    const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldModelId}/${HISTORY}/${CHECK_ONLY}/${PATIENT}/${patientId}`;
    const axios   = interceptor(header);
    return await get(axios, pathUrl);
    };
//#endregion

//#region POST
export const saveField = async ({header, params}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${MANAGER}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const saveFieldModelVersion = async ({header, params}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${VERSION}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const saveFieldInfo = async ({header, params}) => {
  const pathUrl = `${endpoints.API_FORMS}/${INFO_LABEL}/${MANAGER}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const saveRadioGroup = async ({header, params}) => {
  const pathUrl = `${endpoints.API_FORMS}/${RADIO}/${GROUP}/${MODEL}/${MANAGER}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const saveCheckboxGroup = async ({header, params}) => {
  const pathUrl = `${endpoints.API_FORMS}/${CHECK}/${GROUP}/${MODEL}/${MANAGER}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const checkFieldModelAvailability = async ({header, params}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${CHECK_AVALIABILITY}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const checkFieldModelAvailabilityFromManager = async ({header, params}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${CHECK_AVALIABILITY}/${MANAGER}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

//#endregion

//#region PUT
export const putFieldModelById = async ({header, params}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};
//#endregion
import initialState from './initialState';

export const FORMS_ANSWER_SET_NAME_CONTEXT = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: { ...initialState }
    }
  });
};

export const FORMS_ANSWER_SET_FORM_BUILD = (section, state, action) => {
  const {
    targetForm,
    form,
    sections,
    modules,
    fields,
    examDataValueId,
    search,
    examsFieldNames,
    reportsIndexed,
    scoresCode
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        isFirstLoading: false,
        form,
        sections,
        modules,
        fields,
        examDataValueId,
        reportsIndexed,
        scoresCode,
        api: {
          ...state[section][targetForm].api,
          search,
          examsFieldNames
        }
      }
    }
  });
};

export const FORMS_ANSWER_UPDATE_FIELD_BY_ID = (section, state, action) => {
  const {fields, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        isFirstLoading: false,
        fields
      }
    }
  });
};

export const FORMS_ANSWER_UPDATE_FIELD_PATIENT_BY_ID = (section, state, action) => {
  const {patientId, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        isFirstLoading: false,
        form: {
          ...state[section][targetForm].form,
          patientId,
          fieldPatientIsError: false
        }
      }
    }
  });
};

export const FORMS_ANSWER_GET_FORM_BY_ID_FETCH_REQUEST = (section, state, action) => {
  const {targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        enableStatusBar: false,
        statusBarMessage: '',
        fetching: {
          ...state[section][targetForm].fetching,
          getFormById: true,
        },
        form: initialState.form,
        sections: initialState.sections,
        modules: initialState.modules,
        fields: initialState.fields,
        api: {
          search: {},
          examSearch: [],
          examHistory: {
            listRows: [],
            listKeys: {
              withHour  : [],
              default   : []
            }
          }
        }
      }
    }
  });
};

export const FORMS_ANSWER_SAVE_FORMS = (section, state, action) => {
  const {done, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          saveAnswerForm: done,
        }
      }
    }
  });
};

export const FORMS_ANSWER_GET_FORM_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        enableStatusBar: true,
        isFirstLoading: false,
        fetching: {
          ...state[section][targetForm].fetching,
          getFormById: false,
        }
      }
    }
  });
};

export const FORMS_ANSWER_GET_FORM_BY_ID_FETCH_ERROR = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          getFormById: false,
        }
      }
    }
  });
};

export const FORMS_ANSWER_GET_SEARCH_FETCH_REQUEST = (section, state, action) => {
  const {idField, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          search: true,
        },
        api: {
          ...state[section][targetForm].api,
          search: {
            ...state[section][targetForm].api.search,
            [idField]: {
              data: []
            }
          }
        }
      }
    }
  });
};

export const FORMS_ANSWER_GET_SEARCH_FETCH_SUCCESS = (section, state, action) => {
  const {search, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          search: false,
        },
        api: {
          ...state[section][targetForm].api,
          search
        }
      }
    }
  });
};

export const FORMS_ANSWER_GET_SEARCH_FETCH_ERROR = (section, state, action) => {
  const {idField, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          search: false,
        },
        api: {
          ...state[section][targetForm].api,
          search: {
            ...state[section][targetForm].api.search,
            [idField]: {
              data: []
            }
          }
        }
      }
    }
  });
};

export const FORMS_ANSWER_SET_RESET_SEARCH = (section, state, action) => {
  const {idField, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        api: {
          ...state[section][targetForm].api,
          search: {
            ...state[section][targetForm].api.search,
            [idField]: {
              data: []
            }
          }
        }
      }
    }
  });
};

export const FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_REQUEST = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          saveProfessional: true,
        }
      }
    }
  });
};

export const FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_SUCCESS = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          saveProfessional: false,
        }
      }
    }
  });
};

export const FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_ERROR = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          saveProfessional: false,
        }
      }
    }
  });
};

export const FORMS_ANSWER_CREATE_PATIENT_FETCH_REQUEST = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          savePatient: true,
        }
      }
    }
  });
};

export const FORMS_ANSWER_CREATE_PATIENT_FETCH_SUCCESS = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          savePatient: false,
        }
      }
    }
  });
};

export const FORMS_ANSWER_CREATE_PATIENT_FETCH_ERROR = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          savePatient: false,
        }
      }
    }
  });
};

export const FORMS_ANSWER_SET_SEARCH_EXAM_RESET = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        api: {
          ...state[section][targetForm].api,
          examSearch: []
        }
      }
    }
  });
};

export const FORMS_ANSWER_EXAM_FETCH_REQUEST = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          examSearch: true,
        }
      }
    }
  });
};

export const FORMS_ANSWER_EXAM_FETCH_SUCCESS = (section, state, action) => {
  const {exams, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          examSearch: false,
        },
        api: {
          ...state[section][targetForm].api,
          examSearch: exams
        }
      }
    }
  });
};

export const FORMS_ANSWER_EXAM_FETCH_ERROR = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          examSearch: false,
        }
      }
    }
  });
};

export const FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_REQUEST = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          addExamDynamic: true,
        }
      }
    }
  });
};

export const FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_SUCCESS = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          addExamDynamic: false,
        }
      }
    }
  });
};

export const FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_ERROR = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          addExamDynamic: false,
        }
      }
    }
  });
};

export const FORMS_ANSWER_SET_ERROR_FIELD_BY_ID = (section, state, action) => {
  const {idField, isError, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fields: {
          ...state[section][targetForm].fields,
          [idField]: {
            ...state[section][targetForm].fields[idField],
            isError
          }
        }
      }
    }
  });
};

export const FORMS_ANSWER_SET_ERROR_FIELD_PATIENT = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        form: {
          ...state[section][targetForm].form,
          fieldPatientIsError: true
        }
      }
    }
  });
};

export const FORMS_ANSWER_ON_CHANGE_REPORT = (section, state, action) => {
  const {inputReport, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        reportsIndexed: {
          ...state[section][targetForm].reportsIndexed,
          [inputReport.id]: {...inputReport}
        }
      }
    }
  });
};

export const FORMS_ANSWER_SET_NEW_REPORT_BY_ID = (section, state, action) => {
  const {reportsIndexed, idField, field, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        reportsIndexed,
        fields: {
          ...state[section][targetForm].fields,
          [idField]: {...field}
        }
      }
    }
  });
};

export const FORMS_ANSWER_SET_REMOVE_REPORT_BY_ID = (section, state, action) => {
  const {idField, field, reportsIndexed, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        reportsIndexed,
        fields: {
          ...state[section][targetForm].fields,
          [idField]: {...field}
        }
      }
    }
  });
};

export const FORMS_ANSWER_SET_STATUS_BAR_MESSAGE = (section, state, action) => {
  const {message, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        enableStatusBar: true,
        statusBarMessage: message
      }
    }
  });
};

export const FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_REQUEST = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          getExamHistory: true,
        }
      }
    }
  });
};

export const FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_SUCCESS = (section, state, action) => {
  const {listKeys, listRows, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          getExamHistory: false,
        },
        api: {
          ...state[section][targetForm].api,
          examHistory: {
            ...state[section][targetForm].api.examHistory,
            listKeys,
            listRows
          }
        }
      }
    }
  });
};

export const FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_ERROR = (section, state, action) => {
  const { targetForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        fetching: {
          ...state[section][targetForm].fetching,
          getExamHistory: false,
        }
      }
    }
  });
};

export const FORMS_ANSWER_SET_ENABLE_HOUR_TABLE = (section, state, action) => {
  const {enableHour, targetForm} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetForm]: {
        ...state[section][targetForm],
        enableHour
      }
    }
  });
};
import * as util from "@Util";
import { actions as actionConfig } from "@State/ducks/config";
import formOrigin from "@Constants/formOrigin";
import { selector as selectorLogin } from "@State/ducks/login/";
import types from "./constant";
import * as actionModal from "@Common/modalRedux/action";
import * as actionModalFields from "@Components/ModalFields/action";

const getFieldsFetchRequest = () => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_REQUEST,
});
const getFieldsFetchSuccess = (data) => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_SUCCESS,
  payload: data,
});
const getFieldsFetchError = () => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_ERROR,
});

export const getFieldModelByIdFetchRequest = () => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_REQUEST,
});
export const getFieldModelByIdFetchSuccess = () => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_SUCCESS,
});
export const getFieldModelByIdFetchError = () => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_ERROR,
});

export const getFields =
  () =>
  async (dispatch, getState, { apiForms }) => {
    const state = getState();
    let header = selectorLogin.getHeaderConfig(state);

    header = {
      ...header,
      formOrigin: formOrigin["MANAGER"],
    };

    try {
      dispatch(getFieldsFetchRequest());

      let resResponse = (await apiForms.field.getModelFields({ header })).data;
      let _tableFields = [];
      let count = 1;

      for (let item of resResponse) {
        _tableFields.push({
          key: count,
          ...item,
        });
        ++count;
      }

      dispatch(getFieldsFetchSuccess({ tableFields: _tableFields }));
    } catch (error) {
      const { msg } = error;
      const customMsg = !!msg;

      dispatch(getFieldsFetchError());

      util.managerExceptionURIMessage({ error, customMsg });

      if (error && error.status === 401)
        dispatch(actionConfig.routeRedirect("/"));
      // window.location = systemConfigs.AUTH_DOMAIN;
    }
  };

export const getFieldModelById =
  (idVersionField, props = {}) =>
  async (dispatch, getState, { apiForms }) => {
    const state = getState();
    const { viewOnly } = props;
    let header = selectorLogin.getHeaderConfig(state);
    
    header = {
      ...header,
      formOrigin: formOrigin["MANAGER"],
    };

    try {
      dispatch([
        actionModal.onOpen({
          modal: types.MODAL_CONFIGURATION_MEDICALRECORD_FIELDS_UPDATE,
        }),
        getFieldModelByIdFetchRequest(),
        actionModalFields.setGetFieldByIdLoading(),
      ]);

      const response = await apiForms.field.getFieldModelByIdFromManager({
        header,
        idVersionField,
      });
      const result = response.data;

      dispatch([
        getFieldModelByIdFetchSuccess(),
        actionModalFields.editFieldById({
          modal: types.MODAL_CONFIGURATION_MEDICALRECORD_FIELDS_UPDATE,
          context: types.CONTEXT_CONFIGURATION_MEDICALRECORD_FIELDS_UPDATE,
          formOrigin: formOrigin["MANAGER"],
          getFieldById: false,
          viewOnly,
          field: {
            ...result,
            name: result.fieldName,
            displayName: result.displayName,
            size: 12,
          },
        }),
      ]);
    } catch (error) {
      const { msg } = error;
      const customMsg = !!msg;

      dispatch(getFieldModelByIdFetchError());

      util.managerExceptionURIMessage({ error, customMsg });

      if (error && error.status === 401)
        dispatch(actionConfig.routeRedirect("/"));
      // window.location = systemConfigs.AUTH_DOMAIN;
    }
  };

import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const QUALIFIER = 'qualifier';
const MANAGER = 'manager'

//#region GET
export const getQualifiers = async ({ header }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${QUALIFIER}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getQualifierById = async ({ header, qualifierId }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${QUALIFIER}/${qualifierId}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region POST
export const createQualifier = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${QUALIFIER}`; 
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateQualifier = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${QUALIFIER}`; 
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

//#region DELETE
export const deleteQualifier = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${QUALIFIER}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};
//#endregion

export default ({
  // MODALFIELDS_CONTEXT : 'modalFields:context',
  CONTEXT_MODALFIELDS: 'context:modalFields',
  CONTEXT_CONFIGURATION_MEDICALRECORD_FIELDS_UPDATE : 'context:configuration:medicalRecord:fields:update',

  MODAL_CONFIGURATION_MEDICALRECORD_FIELDS        : 'modal:configuration:medicalRecord:fields',
  MODAL_CONFIGURATION_MEDICALRECORD_FIELDS_UPDATE : 'modal:configuration:medicalRecord:fields:update',

  CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_REQUEST  : 'CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_REQUEST',
  CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_SUCCESS  : 'CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_SUCCESS',
  CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_ERROR    : 'CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_ERROR',

  CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_REQUEST  : 'CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_REQUEST',
  CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_SUCCESS  : 'CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_SUCCESS',
  CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_ERROR    : 'CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_ERROR',
});
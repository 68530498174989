import { createSelector } from 'reselect';

//#region INITIAL_STATE
const INITIAL_STATE = {
  drawer: {
    done: false
  },
  error: {
    done    : false,
    messages: []
  },
  fetching: {
  },
  appBar: {
    context: null,
    goBackValue: null
  }
};
//#endregion

//#region Actions
export const types = {
  DRAWER_OPEN   : 'DRAWER_OPEN',
  DRAWER_CLOSED : 'DRAWER_CLOSED',
  DRAWER_TOGGLE : 'DRAWER_TOGGLE',
  
  DRAWER_SET_MANAGER_APP_BAR : 'DRAWER_SET_MANAGER_APP_BAR',
  DRAWER_RESET_APP_BAR : 'DRAWER_RESET_APP_BAR'
};
//#endregion

//#region Action Creators
export const openDrawer           = () => ({ type: types.DRAWER_OPEN });
export const closeDrawer          = () => ({ type: types.DRAWER_CLOSED });
export const toggleDrawer         = () => ({ type: types.DRAWER_TOGGLE });

export const setManagerAppBar = data => ({ type: types.DRAWER_SET_MANAGER_APP_BAR, payload: data });

export const resetAppBar = () => ({ type: types.DRAWER_RESET_APP_BAR });

export const actions = {
  openDrawer, closeDrawer, toggleDrawer, setManagerAppBar, resetAppBar
};
//#endregion

//#region Selectors
export const selectors = ({
  getDrawerIsDone: createSelector(
    state => state.main.drawer.done,
    state => state
  ),

  // getIsFetching: createSelector(
  //   state => state.main.fetching.isStateAndCity,
  //   state => state
  // ),

  getAppBarContext: createSelector(
    state => state.main.appBar.context,
    state => state
  ),

  getAppBarGoBackValue: createSelector(
    state => state.main.appBar.goBackValue,
    state => state
  )
});
//#endregion

//#region Reducers
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DRAWER_OPEN: {
      return {
        ...state,
        drawer: {
          ...state.drawer,
          done: true
        }
      };
    }
    case types.DRAWER_CLOSED: {
      return { ...state, drawer: { ...state.drawer, done: false } };
    }
    case types.DRAWER_TOGGLE: {
      return { ...state, drawer: { ...state.drawer, done: !state.drawer.done } };
    }
    case types.DRAWER_SET_MANAGER_APP_BAR: {
      return ({
        ...state,
        appBar: {
          ...state.appBar,
          ...action.payload
        }
      });
    }

    case types.DRAWER_RESET_APP_BAR: {
      return ({
        ...state,
        appBar: {
          context: null,
          goBackValue: null
        }
      });
    }
    default:
      return state;
  }
};
//#endregion
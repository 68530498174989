import { toastr }         from 'react-redux-toastr';

import { actions as actionConfig } from '../config/index';
// import * as selector      from '@Components/login/selector';
// import * as actionSignIn  from '@Components/login/signIn/action';
import { initialStateSignIn, actions as actionSignIn } from './signIn/';
import { createSelector } from 'reselect';

//#region login

//#region INITIAL_STATE
export const INITIAL_STATE = {
  signIn  : { ...initialStateSignIn },
  auth    : {
    id    : 0,
    name      : '',
    email     : '',
    // intercomUserHash: '',
    company   : {
      id : 0,
      fantasyName: '',
      permissionProfileId: 0,
      permissionProfileName: ''
    },
    business: {
      id: 0,
      accountId: 0,
      workspaceId: 0,
      userId: 0,
      name: '',
      url: '',
      userApps: []
    },
    permissions : {}
  }
};
//#endregion

//#region Actions
export const types = ({
  LOGIN_SIGNIN_INVALID_EMAIL                     : 'LOGIN_SIGNIN_INVALID_EMAIL',
  LOGIN_SIGNIN_INVALID_EMAIL_FORMAT              : 'LOGIN_SIGNIN_INVALID_EMAIL_FORMAT',
  LOGIN_SIGNIN_INVALID_PASSWORD                  : 'LOGIN_SIGNIN_INVALID_PASSWORD',
  LOGIN_SIGNIN_INVALID_PASSWORD_FORMAT           : 'LOGIN_SIGNIN_INVALID_PASSWORD_FORMAT',

  LOGIN_SIGNIN_FETCH_REQUEST                     : 'LOGIN_SIGNIN_FETCH_REQUEST',
  LOGIN_SIGNIN_FETCH_ERROR                       : 'LOGIN_SIGNIN_FETCH_ERROR',
  LOGIN_SIGNIN_FETCH_SUCCESS                     : 'LOGIN_SIGNIN_FETCH_SUCCESS',

  LOGIN_SIGNIN_CLEAR_FORM_ERROR                  : 'LOGIN_SIGNIN_CLEAR_FORM_ERROR',
  LOGIN_SIGNIN_AUTHENTICATED                     : 'LOGIN_SIGNIN_AUTHENTICATED',
  LOGIN_SIGNIN_LOGOUT                            : 'LOGIN_SIGNIN_LOGOUT',

  LOGIN_SET_AUTH                                 : 'LOGIN_SET_AUTH',
  LOGIN_VERIFY_TOKEN                             : 'LOGIN_VERIFY_TOKEN',

  LOGIN_SET_USER_NAME                            : 'LOGIN_SET_USER_NAME',

  LOGIN_SET_AUTH_PERMISSION                      : 'LOGIN_SET_AUTH_PERMISSION'
});
//#endregion

//#region Action Creators
export const verifyToken = () => (dispatch, getState, api) => {
  const token = selector.getToken(getState());
  return api.login.verifyToken(token).then( response => {
    if ( response && response.success )
      return;

    localStorage.removeItem('auth');
    dispatch(actionSignIn.setSignInFetchError());
    toastr.warning('Atenção', 'A sua sessão expirou!');
    dispatch(actionConfig.routeRedirect('/'));
  }).catch( error => {
    localStorage.removeItem('auth');
    dispatch(actionSignIn.setSignInFetchError());
    toastr.warning('Atenção', 'A sua sessão expirou!');
    dispatch(actionConfig.routeRedirect('/'));
  });
};

export const actions = {
  verifyToken
}
//#endregion

//#region Selectors
const getLoginContextByKey        = key => state => state.login[key];
const getSignInApiContextByKey    = key => state => state.login.signIn.api[key];
const getAuthContextByKey         = key => state => state.login.auth[key];
const getAuthBusinessContextByKey = key => state => state.login.auth.business[key];

export const selector = ({
  getAuth           : getLoginContextByKey('auth'),
  getSignIn         : getLoginContextByKey('signIn'),
  getAuthToken      : getAuthContextByKey('token'),
  getPermissions    : getAuthContextByKey('permissions'),

  getAuthUserId: createSelector(
    getAuthContextByKey('id'),
    state => state
  ),
  getAuthCompanyId: createSelector(
    getAuthBusinessContextByKey('id'),
    state => state
  ),
  getAuthUserName: createSelector(
    getAuthContextByKey('name'),
    state => state
  ),
  getToken: createSelector(
    getSignInApiContextByKey('signIn'),
    state => state.token
  ),
  getAuthBusinessWorkspaceId: createSelector(
    getAuthBusinessContextByKey('workspaceId'),
    state => state
  ),
  getAuthBusinessAccountId: createSelector(
    getAuthBusinessContextByKey('accountId'),
    state => state
  ),
  getHeaderConfigAccount: createSelector(
    getAuthContextByKey('token'),
    getAuthBusinessContextByKey('accountId'),
    (token, accountId) => ({token, accountId})
  ),
  getHeaderConfig: createSelector(
    getAuthContextByKey('token'),
    getAuthBusinessContextByKey('accountId'),
    getAuthBusinessContextByKey('workspaceId'),
    getAuthBusinessContextByKey('userId'),
    (token, accountId, workspaceId, userId) => ({token, accountId, workspaceId, userId})
  )
});
//#endregion

//#region Reducers
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN_SIGNIN_INVALID_EMAIL : {
      return {
        ...state,
        signIn : {
          ...state.signIn,
          message: '',
          validation : {
            ...state.signIn.validation,
            ...initialStateSignIn.validation,
            email : true
          }
        }
      };
    }
    case types.LOGIN_SIGNIN_INVALID_EMAIL_FORMAT : {
      return {
        ...state,
        signIn : {
          ...state.signIn,
          message: '',
          validation : {
            ...state.signIn.validation,
            ...initialStateSignIn.validation,
            emailFormat : true
          }
        }
      };
    }
    case types.LOGIN_SET_USER_NAME: {
      const { newName } = action.payload;
      return {
        ...state,
        auth: {
          ...state.auth,
          name: newName
        }
      };
    }
    case types.LOGIN_SIGNIN_INVALID_PASSWORD : {
      return {
        ...state,
        signIn : {
          ...state.signIn,
          message: '',
          validation : {
            ...state.signIn.validation,
            ...initialStateSignIn.validation,
            password : true
          }
        }
      };
    }
    case types.LOGIN_SIGNIN_INVALID_PASSWORD_FORMAT : {
      return {
        ...state,
        signIn : {
          ...state.signIn,
          message: '',
          validation : {
            ...state.signIn.validation,
            ...initialStateSignIn.validation,
            passwordFormat : true
          }
        }
      };
    }
    case types.LOGIN_SIGNIN_FETCH_REQUEST: {
      return {
        ...state,
        signIn : {
          ...state.signIn,
          fetching : true,
          fetchingError: false,
          message: ''
        }
      };
    }
    case types.LOGIN_SIGNIN_FETCH_ERROR: {
      return {
        ...state,
        signIn : {
          ...state.signIn,
          message: action.payload,
          fetching : false,
          fetchingError: true,
          authenticated: false,
          checkedLocalStorage: false
        }
      };
    }
    case types.LOGIN_SIGNIN_FETCH_SUCCESS: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          fetching: false,
          authenticated: true,
          checkedLocalStorage: true,
          api: {
            ...state.signIn.api,
            signIn: {
              ...state.signIn.api.signIn,
              token     : action.payload.token,
              userId    : action.payload.userId,
              name      : action.payload.name,
              email     : action.payload.email,
              companys  : action.payload.companys
            }
          }
        }
      };
    }
    case types.LOGIN_SIGNIN_CLEAR_FORM_ERROR: {
      return {
        ...state,
        signIn : {
          ...state.signIn,
          validation : {
            ...state.signIn.validation,
            ...initialStateSignIn.validation
          }
        }
      };
    }
    case types.LOGIN_SIGNIN_AUTHENTICATED: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          fetching: false,
          authenticated: true,
          checkedLocalStorage: true,
          api: {
            ...state.signIn.api,
            signIn: {
              ...state.signIn.api.signIn,
              token     : action.payload.token,
              userId    : action.payload.userId,
              name      : action.payload.name,
              email     : action.payload.email,
            }
          }
        }
      };
    }
    case types.LOGIN_SIGNIN_LOGOUT: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          authenticated: false,
          checkedLocalStorage: false,
        }
      };
    }
    case types.LOGIN_SET_AUTH: {
      const { permissions, ...auth } = action.payload;
      return {
        ...state,
        auth: {
          ...state.auth,
          ...auth,
          permissions: {
            ...state.auth.permissions,
            ...permissions
          }
        }
      };
    }
    case types.LOGIN_SET_AUTH_PERMISSION: {
      const {permissions} = action.payload;
      return {
        ...state,
        auth: {
          ...state.auth,
          permissions: {
            ...state.auth.permissions,
            ...permissions
          }
        }
      };
    }
    default : return state;
  }
};
//#endregion

//#endregion


import { createSelector } from 'reselect';
import { push } from 'connected-react-router';

//#region INITIAL_STATE
const INITIAL_STATE = ({
  route: {
    name: '',
    nameDrawer: ''
  },
  business: {
    id: 0
  },
  menu: {
    done: false,
    dataSource: []
  }
});
//#endregion

//#region Actions
export const types = ({
  ROUTE_CHANGE            : 'ROUTE_CHANGE',
  CONFIG_MENU_SET_UPDATE  : 'CONFIG_MENU_SET_UPDATE',
  CONFIG_MENU_TOGGLE_DONE : 'CONFIG_MENU_TOGGLE_DONE'
});
//#endregion

//#region Action Creators
const setMenuUpdate = data => ({ type: types.CONFIG_MENU_SET_UPDATE, payload: data });

export const setToggleMenu  = data => ({ type: types.CONFIG_MENU_TOGGLE_DONE, payload: data });
export const routeChange    = data  => ({ type: types.ROUTE_CHANGE, payload: data });
export const routeRedirect  = path  => dispatch => dispatch(push(path));

/**
 * Gerenciar Menu Secundario baseado no Menu Primário.
 * @param {Object[]} dataSource - Configuração para o menu secundario.
 */
export const setManagerDrawerChild = props => (dispatch, getState, api) => {
  const {dataSource} = props;
  const _dataSource = dataSource || [];

  const params = ({
    done: false,
    dataSource: _dataSource
  });

  dispatch(setMenuUpdate(params));
};

export const toggleMenuSecondary = () => (dispatch, getState, api) => {
  const state = getState();
  const done  = selectors.getMenuIsDone(state);
  dispatch(setToggleMenu({done: !done}));
};

export const actions = {
  setToggleMenu, routeChange, routeRedirect, setManagerDrawerChild, toggleMenuSecondary
}
//#endregion

//#region Selectors
export const selectors = ({
  getMenuDataSource: state => state.config.menu.dataSource,

  getNameRoute: createSelector(
    state => state.config.route.name,
    state => state
  ),

  getNameDrawer: createSelector(
    state => state.config.route.nameDrawer,
    state => state
  ),

  getMenuIsDone: createSelector(
    state => state.config.menu.done,
    state => state
  )
});
//#endregion

//#region Reducers
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ROUTE_CHANGE: {
      const {name, nameDrawer} = action.payload;
      return ({
        ...state,
        route: {
          ...state.route,
          name,
          nameDrawer
        }
      });
    }
    case types.CONFIG_MENU_SET_UPDATE: {
      const {done, dataSource} = action.payload;
      return ({
        ...state,
        menu: {
          ...state.menu,
          done,
          dataSource
        }
      });
    }
    case types.CONFIG_MENU_TOGGLE_DONE: {
      const {done} = action.payload;
      return ({
        ...state,
        menu: {
          ...state.menu,
          done: done
        }
      });
    }
    default:
      return state;
  }
};
//#endregion
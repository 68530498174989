import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SignInPage from '@Pages/login/signIn';

// import LazyRouteName                from '@Common/lazyRouteName';
import LazyRouteProtectedName from '@Common/lazyRouteProtectedName';
import Messages from '@Common/messages';
import SnackBar from '@Common/snackBar';
import * as routes from '@App/constants/router';
import FieldModelDialog from '@Common/FieldModelDialog';

import 'rc-table/assets/index.css';
import 'rc-pagination/assets/index.css';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import 'react-image-lightbox/style.css';
import '@Assets/css/app.css';

const App = props => {
    return (
        <>
            <Switch>
                <Route exact path={'/'} component={SignInPage} />
                <LazyRouteProtectedName
                    path={`/${routes.MANAGER}`}
                    component={() => import('@Pages/manager')}
                    drawer={'default'}
                />

                <Route
                    render={props => (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: { from: props.location },
                            }}
                        />
                    )}
                />
            </Switch>
            <Messages />
            <SnackBar />
            <FieldModelDialog />
        </>
    );
};

export default App;

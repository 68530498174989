// import { toastr }         from 'react-redux-toastr';
import { Modal } from 'antd';
import { selector as selectorLogin } from "@State/ducks/login";
import { actions as actionConfig } from "@State/ducks/config";
import { actions as actionSnack } from "@State/ducks/common/snackbar";
import { actions as actionsModal, selectors as selectorsModal } from '@State/ducks/common/modal';
import moment from 'moment';
import { createSelector } from 'reselect';
// import moment from 'moment';
import * as util from '@Util/';
const ModalConfirm = Modal.confirm;

//#region INITIAL_STATE
const INITIAL_STATE = {
  currentPackage: {
    packageId: 0,
    name: null,
    defaultWorkspacesLimit: null,
    defaultUsersLimit: null,
    basePrice: 0,
    pricePerUser: null,
    pricePerWorkspace: null,
    signatureTypeId: null
  },
  newPackageData: {
    packageId: 0,
    name: null,
    defaultWorkspacesLimit: null,
    defaultUsersLimit: null,
    basePrice: 0,
    pricePerUser: null,
    pricePerWorkspace: null,
    signatureTypeId: null
  },
  currentClientData: {
    accountPlan: {},
    paymentHistory: []
  },
  currentClientInvoices: {
    accountData: {},
    nextBilling: {},
    paymentHistory: []
  },
  currentClientInvoiceData: {

  },
  formData: {
    email: null,
    password: null
  },
  passwordData: {
    oldPassword: null,
    newPassword: null,
    newPassword2: null,
  },
  api: {
    hasLoadedInitialUsers: false,
    searchedUsers: []
    // {
    //   userId: 0,
    //   name: null,
    //   email: null,
    //   cpf: null
    // }
    ,
    coreLines:[],
    accountsCount: 0,
    activeAccountsCount: 0,
    inactiveAccountsCount: 0,
    activeUsersCount: 0,
    inactiveUsersCount: 0,
    accountModal: {
      accountId: 0,
      date: null,
    },
    userModal: {
      userId: 0,
      name: null,
      email: null,
    },
    accountsPackageId: {},
    accounts: [],
    invoices: [],
    managerUsers: [],
    groupsPermission: [],
    packageModels: [],
    userCount: 0,
    hasSearched: false,
    tableLoaded: false,
    userToken: null
  },
  fetching: {
    isSearching: false,
    fetchingAccounts: false,
    isFetchingPackages: false,
    packagesFetched: false,
    updatingManagerAccess: false,
    updatingActiveDate: false,
    updatingAccountPackage: false,
    loggingUser: false,
    registeringManager: false,
    isPasswordChanging: false,
    isLoadingClientData: false,
    isLoadingInvoices: false,
    isLoadingInvoiceStatus: false,
    isLoadingPdf: false,
    isLoadingXml: false,
    isCancelingInvoice: false,
    isSendingEmail: false,
    isResendingEmail: false,
    isConfirmingRegistration: false,
    isLoadingClientInvoiceData: false,
    isDeletingUser: false,
    isEmittingInvoice: false
  }
};
//#endregion

//#region Actions Types
export const types = ({
  MANAGER_LOGIN_AS_USER_REQUEST: 'MANAGER_LOGIN_AS_USER_REQUEST',
  MANAGER_LOGIN_AS_USER_ERROR: 'MANAGER_LOGIN_AS_USER_ERROR',
  MANAGER_LOGIN_AS_USER_SUCCESS: 'MANAGER_LOGIN_AS_USER_SUCCESS',

  MANAGER_DELETE_USER_REQUEST: 'MANAGER_DELETE_USER_REQUEST',
  MANAGER_DELETE_USER_ERROR: 'MANAGER_DELETE_USER_ERROR',
  MANAGER_DELETE_USER_SUCCESS: 'MANAGER_DELETE_USER_SUCCESS',

  MANAGER_REGISTER_FETCH_REQUEST: 'MANAGER_REGISTER_FETCH_REQUEST',
  MANAGER_REGISTER_FETCH_ERROR: 'MANAGER_REGISTER_FETCH_ERROR',
  MANAGER_REGISTER_FETCH_SUCCESS: 'MANAGER_REGISTER_FETCH_SUCCESS',

  MANAGER_SEARCH_USER_FETCH_REQUEST: 'MANAGER_SEARCH_USER_FETCH_REQUEST',
  MANAGER_SEARCH_USER_FETCH_ERROR: 'MANAGER_SEARCH_USER_FETCH_ERROR',
  MANAGER_SEARCH_USER_FETCH_SUCCESS: 'MANAGER_SEARCH_USER_FETCH_SUCCESS',

  MANAGER_CHANGE_PASSWORD_FETCH_REQUEST: 'MANAGER_CHANGE_PASSWORD_FETCH_REQUEST',
  MANAGER_CHANGE_PASSWORD_FETCH_ERROR: 'MANAGER_CHANGE_PASSWORD_FETCH_ERROR',
  MANAGER_CHANGE_PASSWORD_FETCH_SUCCESS: 'MANAGER_CHANGE_PASSWORD_FETCH_SUCCESS',

  MANAGER_GET_ACCOUNTS_FETCH_REQUEST: 'MANAGER_GET_ACCOUNTS_FETCH_REQUEST',
  MANAGER_GET_ACCOUNTS_FETCH_ERROR: 'MANAGER_GET_ACCOUNTS_FETCH_ERROR',
  MANAGER_GET_ACCOUNTS_FETCH_SUCCESS: 'MANAGER_GET_ACCOUNTS_FETCH_SUCCESS',

  MANAGER_GET_MANAGERS_FETCH_REQUEST: 'MANAGER_GET_MANAGERS_FETCH_REQUEST',
  MANAGER_GET_MANAGERS_FETCH_ERROR: 'MANAGER_GET_MANAGERS_FETCH_ERROR',
  MANAGER_GET_MANAGERS_FETCH_SUCCESS: 'MANAGER_GET_MANAGERS_FETCH_SUCCESS',

  MANAGER_UPDATE_ACTIVE_DATE_FETCH_REQUEST: 'MANAGER_UPDATE_ACTIVE_DATE_FETCH_REQUEST',
  MANAGER_UPDATE_ACTIVE_DATE_FETCH_ERROR: 'MANAGER_UPDATE_ACTIVE_DATE_FETCH_ERROR',
  MANAGER_UPDATE_ACTIVE_DATE_FETCH_SUCCESS: 'MANAGER_UPDATE_ACTIVE_DATE_FETCH_SUCCESS',

  MANAGER_INACTIVATE_ACCOUNT_FETCH_REQUEST: 'MANAGER_INACTIVATE_ACCOUNT_FETCH_REQUEST',
  MANAGER_INACTIVATE_ACCOUNT_FETCH_ERROR: 'MANAGER_INACTIVATE_ACCOUNT_FETCH_ERROR',
  MANAGER_INACTIVATE_ACCOUNT_FETCH_SUCCESS: 'MANAGER_INACTIVATE_ACCOUNT_FETCH_SUCCESS',

  MANAGER_ACTIVATE_USER_REGISTRATION_FETCH_REQUEST: 'MANAGER_ACTIVATE_USER_REGISTRATION_FETCH_REQUEST',
  MANAGER_ACTIVATE_USER_REGISTRATION_FETCH_ERROR: 'MANAGER_ACTIVATE_USER_REGISTRATION_FETCH_ERROR',
  MANAGER_ACTIVATE_USER_REGISTRATION_FETCH_SUCCESS: 'MANAGER_ACTIVATE_USER_REGISTRATION_FETCH_SUCCESS',

  MANAGER_ACTIVATE_ACCOUNT_FETCH_REQUEST: 'MANAGER_ACTIVATE_ACCOUNT_FETCH_REQUEST',
  MANAGER_ACTIVATE_ACCOUNT_FETCH_ERROR: 'MANAGER_ACTIVATE_ACCOUNT_FETCH_ERROR',
  MANAGER_ACTIVATE_ACCOUNT_FETCH_SUCCESS: 'MANAGER_ACTIVATE_ACCOUNT_FETCH_SUCCESS',

  MANAGER_UPDATE_ACCOUNT_PACKAGE_FETCH_REQUEST: 'MANAGER_UPDATE_ACCOUNT_PACKAGE_FETCH_REQUEST',
  MANAGER_UPDATE_ACCOUNT_PACKAGE_FETCH_ERROR: 'MANAGER_UPDATE_ACCOUNT_PACKAGE_FETCH_ERROR',
  MANAGER_UPDATE_ACCOUNT_PACKAGE_FETCH_SUCCESS: 'MANAGER_UPDATE_ACCOUNT_PACKAGE_FETCH_SUCCESS',

  MANAGER_SET_USER_AS_MANAGER_FETCH_REQUEST: 'MANAGER_SET_USER_AS_MANAGER_FETCH_REQUEST',
  MANAGER_SET_USER_AS_MANAGER_FETCH_ERROR: 'MANAGER_SET_USER_AS_MANAGER_FETCH_ERROR',
  MANAGER_SET_USER_AS_MANAGER_FETCH_SUCCESS: 'MANAGER_SET_USER_AS_MANAGER_FETCH_SUCCESS',

  MANAGER_REMOVE_USER_AS_MANAGER_FETCH_REQUEST: 'MANAGER_REMOVE_USER_AS_MANAGER_FETCH_REQUEST',
  MANAGER_REMOVE_USER_AS_MANAGER_FETCH_ERROR: 'MANAGER_REMOVE_USER_AS_MANAGER_FETCH_ERROR',
  MANAGER_REMOVE_USER_AS_MANAGER_FETCH_SUCCESS: 'MANAGER_REMOVE_USER_AS_MANAGER_FETCH_SUCCESS',

  MANAGER_GET_PACKAGE_MODELS_FETCH_REQUEST: 'MANAGER_GET_PACKAGE_MODELS_FETCH_REQUEST',
  MANAGER_GET_PACKAGE_MODELS_FETCH_ERROR: 'MANAGER_GET_PACKAGE_MODELS_FETCH_ERROR',
  MANAGER_GET_PACKAGE_MODELS_FETCH_SUCCESS: 'MANAGER_GET_PACKAGE_MODELS_FETCH_SUCCESS',
  MANAGER_SET_ACCOUNT_CURRENT_PACKAGE: 'MANAGER_SET_ACCOUNT_CURRENT_PACKAGE',

  MANAGER_LOAD_CLIENT_REQUEST: 'MANAGER_LOAD_CLIENT_REQUEST',
  MANAGER_LOAD_CLIENT_SUCCESS: 'MANAGER_LOAD_CLIENT_SUCCESS',
  MANAGER_LOAD_CLIENT_ERROR: 'MANAGER_LOAD_CLIENT_ERROR',

  MANAGER_LOAD_CLIENT_INVOICES_REQUEST: 'MANAGER_LOAD_CLIENT_INVOICES_REQUEST',
  MANAGER_LOAD_CLIENT_INVOICES_SUCCESS: 'MANAGER_LOAD_CLIENT_INVOICES_SUCCESS',
  MANAGER_LOAD_CLIENT_INVOICES_ERROR: 'MANAGER_LOAD_CLIENT_INVOICES_ERROR',

  MANAGER_GET_INVOICE_STATUS_REQUEST: 'MANAGER_GET_INVOICE_STATUS_REQUEST',
  MANAGER_GET_INVOICE_STATUS_SUCCESS: 'MANAGER_GET_INVOICE_STATUS_SUCCESS',
  MANAGER_GET_INVOICE_STATUS_ERROR: 'MANAGER_GET_INVOICE_STATUS_ERROR',

  MANAGER_GET_PDF_INVOICE_REQUEST: 'MANAGER_GET_PDF_INVOICE_REQUEST',
  MANAGER_GET_PDF_INVOICE_SUCCESS: 'MANAGER_GET_PDF_INVOICE_SUCCESS',
  MANAGER_GET_PDF_INVOICE_ERROR: 'MANAGER_GET_PDF_INVOICE_ERROR',

  MANAGER_GET_XML_INVOICE_REQUEST: 'MANAGER_GET_XML_INVOICE_REQUEST',
  MANAGER_GET_XML_INVOICE_SUCCESS: 'MANAGER_GET_XML_INVOICE_SUCCESS',
  MANAGER_GET_XML_INVOICE_ERROR: 'MANAGER_GET_XML_INVOICE_ERROR',

  MANAGER_CANCEL_INVOICE_REQUEST: 'MANAGER_CANCEL_INVOICE_REQUEST',
  MANAGER_CANCEL_INVOICE_SUCCESS: 'MANAGER_CANCEL_INVOICE_SUCCESS',
  MANAGER_CANCEL_INVOICE_ERROR: 'MANAGER_CANCEL_INVOICE_ERROR',

  MANAGER_SEND_INVOICE_TO_EMAIL_REQUEST: 'MANAGER_SEND_INVOICE_TO_EMAIL_REQUEST',
  MANAGER_SEND_INVOICE_TO_EMAIL_SUCCESS: 'MANAGER_SEND_INVOICE_TO_EMAIL_SUCCESS',
  MANAGER_SEND_INVOICE_TO_EMAIL_ERROR: 'MANAGER_SEND_INVOICE_TO_EMAIL_ERROR',

  MANAGER_EDIT_AND_RESEND_CONFIRMATION_EMAIL_REQUEST: 'MANAGER_EDIT_AND_RESEND_CONFIRMATION_EMAIL_REQUEST',
  MANAGER_EDIT_AND_RESEND_CONFIRMATION_EMAIL_SUCCESS: 'MANAGER_EDIT_AND_RESEND_CONFIRMATION_EMAIL_SUCCESS',
  MANAGER_EDIT_AND_RESEND_CONFIRMATION_EMAIL_ERROR: 'MANAGER_EDIT_AND_RESEND_CONFIRMATION_EMAIL_ERROR',

  MANAGER_GET_CLIENT_INVOICE_DATA_REQUEST: 'MANAGER_GET_CLIENT_INVOICE_DATA_REQUEST',
  MANAGER_GET_CLIENT_INVOICE_DATA_SUCCESS: 'MANAGER_GET_CLIENT_INVOICE_DATA_SUCCESS',
  MANAGER_GET_CLIENT_INVOICE_DATA_ERROR: 'MANAGER_GET_CLIENT_INVOICE_DATA_ERROR',
  MANAGER_SET_CLIENT_INVOICE_DATA: 'MANAGER_SET_CLIENT_INVOICE_DATA',

  MANAGER_EMIT_INVOICE_REQUEST: 'MANAGER_EMIT_INVOICE_REQUEST',
  MANAGER_EMIT_INVOICE_SUCCESS: 'MANAGER_EMIT_INVOICE_SUCCESS',
  MANAGER_EMIT_INVOICE_ERROR: 'MANAGER_EMIT_INVOICE_ERROR',

  MANAGER_SET_LIST_GROUP_ACCOUNT: 'MANAGER_SET_LIST_GROUP_ACCOUNT',

  MANAGER_SET_LIST_QUALIFIERS: 'MANAGER_SET_LIST_QUALIFIERS',

  MANAGER_SET_LIST_CORE_LINES: 'MANAGER_SET_LIST_CORE_LINES',



  MANAGER_SET_PASSWORD_DATA: 'MANAGER_SET_PASSWORD_DATA',
  MANAGER_SET_REGISTER_DATA: 'MANAGER_SET_REGISTER_DATA',
  MANAGER_SET_USER_COUNT: 'MANAGER_SET_USER_COUNT',
  MANAGER_SET_ACCOUNT_COUNT: 'MANAGER_SET_ACCOUNT_COUNT',
  MANAGER_SET_ACCOUNT_PACKAGE_ID: 'MANAGER_SET_ACCOUNT_PACKAGE_ID',
  MANAGER_SET_ACCOUNT_PACKAGE_DATA: 'MANAGER_SET_ACCOUNT_PACKAGE_DATA',
  MANAGER_PACKAGES_FETCHED: 'MANAGER_PACKAGES_FETCHED',
  MANAGER_CHANGE_ACCOUNT_MODAL: 'MANAGER_CHANGE_ACCOUNT_MODAL',
  MANAGER_CHANGE_USER_MODAL: 'MANAGER_CHANGE_USER_MODAL',
  MANAGER_CLEAR_SEARCH_USER: 'MANAGER_CLEAR_SEARCH_USER',
  MANAGER_CLEAR_REGISTER_FORM: 'MANAGER_CLEAR_REGISTER_FORM',
  MANAGER_CLEAR_PASSWORD_FORM: 'MANAGER_CLEAR_PASSWORD_FORM',
  MODAL_DATE: 'modal:date',
  MODAL_PACKAGE: 'modal:package',
  MODAL_USERS_LIMIT: 'modal:users_limit',
  MODAL_INVOICES: 'modal:invoices',
  MODAL_EMIT_INVOICE: 'modal:emit_invoice',
  MODAL_CONFIRMATION_EMAIL: 'modal:confirmation_email',
  MODAL_CONFIRM_USER_REGISTRATION: 'modal:confirm_user_registration',
  MODAL_DELETE_USER: 'modal:delete_user',
});
//#endregion

//#region #### Action Creators ####

//#region Internal Action Creators
const clearForm = () => ({ type: types.MANAGER_CLEAR_REGISTER_FORM });
const clearPasswordForm = () => ({ type: types.MANAGER_CLEAR_PASSWORD_FORM });

const setFormData = data => ({ type: types.MANAGER_SET_REGISTER_DATA, payload: data });
const setPasswordData = data => ({ type: types.MANAGER_SET_PASSWORD_DATA, payload: data });
const setAccountPackageData = data => ({ type: types.MANAGER_SET_ACCOUNT_PACKAGE_DATA, payload: data });
const setAccountPackageId = data => ({ type: types.MANAGER_SET_ACCOUNT_PACKAGE_ID, payload: data });
const setAccountCurrentPackage = data => ({ type: types.MANAGER_SET_ACCOUNT_CURRENT_PACKAGE, payload: data });
const setUserCount = data => ({ type: types.MANAGER_SET_USER_COUNT, payload: data });
const setAccountCount = data => ({ type: types.MANAGER_SET_ACCOUNT_COUNT, payload: data });
const setPackagesFetched = () => ({ type: types.MANAGER_PACKAGES_FETCHED });

const changeAccountModal = data => ({ type: types.MANAGER_CHANGE_ACCOUNT_MODAL, payload: data });

const changeUserModal = data => ({ type: types.MANAGER_CHANGE_USER_MODAL, payload: data });

const loginAsUserRequest = () => ({ type: types.MANAGER_LOGIN_AS_USER_REQUEST });
const loginAsUserError = data => ({ type: types.MANAGER_LOGIN_AS_USER_ERROR });
const loginAsUserSuccess = data => ({ type: types.MANAGER_LOGIN_AS_USER_SUCCESS, payload: data });

const deleteUserRequest = () => ({ type: types.MANAGER_DELETE_USER_REQUEST });
const deleteUserError = data => ({ type: types.MANAGER_DELETE_USER_ERROR });
const deleteUserSuccess = data => ({ type: types.MANAGER_DELETE_USER_SUCCESS, payload: data });

const changePasswordFetchRequest = () => ({ type: types.MANAGER_CHANGE_PASSWORD_FETCH_REQUEST });
const changePasswordFetchError = data => ({ type: types.MANAGER_CHANGE_PASSWORD_FETCH_ERROR, payload: data });
const changePasswordFetchSuccess = data => ({ type: types.MANAGER_CHANGE_PASSWORD_FETCH_SUCCESS });

const registerManagerFetchRequest = () => ({ type: types.MANAGER_REGISTER_FETCH_REQUEST });
const registerManagerFetchError = data => ({ type: types.MANAGER_REGISTER_FETCH_ERROR, payload: data });
const registerManagerFetchSuccess = data => ({ type: types.MANAGER_REGISTER_FETCH_SUCCESS });

const updateAccountPackageRequest = () => ({ type: types.MANAGER_UPDATE_ACCOUNT_PACKAGE_FETCH_REQUEST });
const updateAccountPackageError = data => ({ type: types.MANAGER_UPDATE_ACCOUNT_PACKAGE_FETCH_ERROR, payload: data });
const updateAccountPackageSuccess = data => ({ type: types.MANAGER_UPDATE_ACCOUNT_PACKAGE_FETCH_SUCCESS });

const updateAccountActiveDateRequest = () => ({ type: types.MANAGER_UPDATE_ACTIVE_DATE_FETCH_REQUEST });
const updateAccountActiveDateError = data => ({ type: types.MANAGER_UPDATE_ACTIVE_DATE_FETCH_ERROR, payload: data });
const updateAccountActiveDateSuccess = data => ({ type: types.MANAGER_UPDATE_ACTIVE_DATE_FETCH_SUCCESS });

const updateInactivateAccountRequest = () => ({ type: types.MANAGER_INACTIVATE_ACCOUNT_FETCH_REQUEST });
const updateInactivateAccountError = data => ({ type: types.MANAGER_INACTIVATE_ACCOUNT_FETCH_ERROR, payload: data });
const updateInactivateAccountSuccess = data => ({ type: types.MANAGER_INACTIVATE_ACCOUNT_FETCH_SUCCESS });

const updateUserRegistrationStatusRequest = () => ({ type: types.MANAGER_ACTIVATE_USER_REGISTRATION_FETCH_REQUEST });
const updateUserRegistrationStatusError = data => ({ type: types.MANAGER_ACTIVATE_USER_REGISTRATION_FETCH_ERROR, payload: data });
const updateUserRegistrationStatusSuccess = data => ({ type: types.MANAGER_ACTIVATE_USER_REGISTRATION_FETCH_SUCCESS });

const updateActivateAccountRequest = () => ({ type: types.MANAGER_ACTIVATE_ACCOUNT_FETCH_REQUEST });
const updateActivateAccountError = data => ({ type: types.MANAGER_ACTIVATE_ACCOUNT_FETCH_ERROR, payload: data });
const updateActivateAccountSuccess = data => ({ type: types.MANAGER_ACTIVATE_ACCOUNT_FETCH_SUCCESS });

const setUserAsManagerFetchRequest = () => ({ type: types.MANAGER_SET_USER_AS_MANAGER_FETCH_REQUEST });
const setUserAsManagerFetchError = data => ({ type: types.MANAGER_SET_USER_AS_MANAGER_FETCH_ERROR, payload: data });
const setUserAsManagerFetchSuccess = () => ({ type: types.MANAGER_SET_USER_AS_MANAGER_FETCH_SUCCESS });

// const removeUserAsManagerFetchRequest       = ()    => ({ type: types.MANAGER_REMOVE_USER_AS_MANAGER_FETCH_REQUEST });
// const removeUserAsManagerFetchError         = data  => ({ type: types.MANAGER_REMOVE_USER_AS_MANAGER_FETCH_ERROR, payload: data });
// const removeUserAsManagerFetchSuccess       = ()  => ({ type: types.MANAGER_REMOVE_USER_AS_MANAGER_FETCH_SUCCESS });

const searchUserFetchRequest = () => ({ type: types.MANAGER_SEARCH_USER_FETCH_REQUEST });
const searchUserFetchError = data => ({ type: types.MANAGER_SEARCH_USER_FETCH_ERROR, payload: data });
const searchUserFetchSuccess = data => ({ type: types.MANAGER_SEARCH_USER_FETCH_SUCCESS, payload: data });

const getManagersFetchRequest = () => ({ type: types.MANAGER_GET_MANAGERS_FETCH_REQUEST });
const getManagersFetchError = data => ({ type: types.MANAGER_GET_MANAGERS_FETCH_ERROR, payload: data });
const getManagersFetchSuccess = data => ({ type: types.MANAGER_GET_MANAGERS_FETCH_SUCCESS, payload: data });

const getAccountsFetchRequest = () => ({ type: types.MANAGER_GET_ACCOUNTS_FETCH_REQUEST });
const getAccountsFetchError = data => ({ type: types.MANAGER_GET_ACCOUNTS_FETCH_ERROR, payload: data });
const getAccountsFetchSuccess = data => ({ type: types.MANAGER_GET_ACCOUNTS_FETCH_SUCCESS, payload: data });

const setListGroupAccountFetchSuccess = data => ({ type: types.MANAGER_SET_LIST_GROUP_ACCOUNT, payload: data });

const setListQualifiersFetchSuccess = data => ({ type: types.MANAGER_SET_LIST_QUALIFIERS, payload: data });

const setListCoreLinesFetchSuccess = data => ({ type: types.MANAGER_SET_LIST_CORE_LINES, payload: data });

const packageModelsFetchRequest = () => ({ type: types.MANAGER_GET_PACKAGE_MODELS_FETCH_REQUEST });
const packageModelsFetchError = data => ({ type: types.MANAGER_GET_PACKAGE_MODELS_FETCH_ERROR, payload: data });
const packageModelsFetchSuccess = data => ({ type: types.MANAGER_GET_PACKAGE_MODELS_FETCH_SUCCESS, payload: data });

const loadClientRequest = () => ({ type: types.MANAGER_LOAD_CLIENT_REQUEST });
const loadClientSuccess = data => ({ type: types.MANAGER_LOAD_CLIENT_SUCCESS, payload: data });
const loadClientError = data => ({ type: types.MANAGER_LOAD_CLIENT_ERROR, payload: data });

const loadClientInvoicesRequest = () => ({ type: types.MANAGER_LOAD_CLIENT_INVOICES_REQUEST });
const loadClientInvoicesSuccess = data => ({ type: types.MANAGER_LOAD_CLIENT_INVOICES_SUCCESS, payload: data });
const loadClientInvoicesError = data => ({ type: types.MANAGER_LOAD_CLIENT_INVOICES_ERROR, payload: data });


const getInvoiceStatusRequest = () => ({ type: types.MANAGER_GET_INVOICE_STATUS_REQUEST });
const getInvoiceStatusSuccess = data => ({ type: types.MANAGER_GET_INVOICE_STATUS_SUCCESS, payload: data });
const getInvoiceStatusError = data => ({ type: types.MANAGER_GET_INVOICE_STATUS_ERROR, payload: data });

const getPdfInvoiceRequest = () => ({ type: types.MANAGER_GET_PDF_INVOICE_REQUEST });
const getPdfInvoiceSuccess = data => ({ type: types.MANAGER_GET_PDF_INVOICE_SUCCESS, payload: data });
const getPdfInvoiceError = data => ({ type: types.MANAGER_GET_PDF_INVOICE_ERROR, payload: data });

const getXmlInvoiceRequest = () => ({ type: types.MANAGER_GET_XML_INVOICE_REQUEST });
const getXmlInvoiceSuccess = data => ({ type: types.MANAGER_GET_XML_INVOICE_SUCCESS, payload: data });
const getXmlInvoiceError = data => ({ type: types.MANAGER_GET_XML_INVOICE_ERROR, payload: data });

const cancelInvoiceRequest = () => ({ type: types.MANAGER_CANCEL_INVOICE_REQUEST });
const cancelInvoiceSuccess = data => ({ type: types.MANAGER_CANCEL_INVOICE_SUCCESS, payload: data });
const cancelInvoiceError = data => ({ type: types.MANAGER_CANCEL_INVOICE_ERROR, payload: data });

const sendInvoiceToEmailRequest = () => ({ type: types.MANAGER_SEND_INVOICE_TO_EMAIL_REQUEST });
const sendInvoiceToEmailSuccess = data => ({ type: types.MANAGER_SEND_INVOICE_TO_EMAIL_SUCCESS, payload: data });
const sendInvoiceToEmailError = data => ({ type: types.MANAGER_SEND_INVOICE_TO_EMAIL_ERROR, payload: data });

const editAndResendConfirmationEmailRequest = () => ({ type: types.MANAGER_EDIT_AND_RESEND_CONFIRMATION_EMAIL_REQUEST });
const editAndResendConfirmationEmailSuccess = data => ({ type: types.MANAGER_EDIT_AND_RESEND_CONFIRMATION_EMAIL_SUCCESS, payload: data });
const editAndResendConfirmationEmailError = data => ({ type: types.MANAGER_EDIT_AND_RESEND_CONFIRMATION_EMAIL_ERROR, payload: data });

const getClientInvoiceDataRequest = () => ({ type: types.MANAGER_GET_CLIENT_INVOICE_DATA_REQUEST });
const getClientInvoiceDataSuccess = data => ({ type: types.MANAGER_GET_CLIENT_INVOICE_DATA_SUCCESS, payload: data });
const getClientInvoiceDataError = data => ({ type: types.MANAGER_GET_CLIENT_INVOICE_DATA_ERROR, payload: data });
const setClientInvoiceData = data => ({ type: types.MANAGER_SET_CLIENT_INVOICE_DATA, payload: data });

const emitInvoiceRequest = () => ({ type: types.MANAGER_EMIT_INVOICE_REQUEST });
const emitInvoiceSuccess = data => ({ type: types.MANAGER_EMIT_INVOICE_SUCCESS, payload: data });
const emitInvoiceError = data => ({ type: types.MANAGER_EMIT_INVOICE_ERROR, payload: data });

const onCloseModal = (modal) => {
  actionsModal.onClose({modal})
}


//#endregion

//#region External Action Creators
export const manageInitialUserAccess = () => async (dispatch, getState, { apiAccount }) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);
  try {
    const responseUsersCount = await apiAccount.account.getUserCount({ header });
    dispatch(setUserCount({
      userCount: responseUsersCount.data.userCount,
      activeUsersCount: responseUsersCount.data.activeUsersCount,
      inactiveUsersCount: responseUsersCount.data.inactiveUsersCount
    }))
  } catch(error) {
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
  dispatch([searchUser(null), clearSearch()]);
}

export const clearSearch = () => ({ type: types.MANAGER_CLEAR_SEARCH_USER });

export const loginAsUser = (userId) => async (dispatch, getState, api) => {
  const state = getState();
  const token = selectorLogin.getAuthToken(state);
  dispatch(loginAsUserRequest());
  try {
    const response = await api.login.loginAsUser(userId, token);

    dispatch([
      actionSnack.showSnackbar({ message: "Acessando a conta do usuário...", variant: 'success', autoHideDuration: 3500 }),
      loginAsUserSuccess(response.token)
    ]);

  } catch (error) {
    // const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: "Não foi possível acessar a conta do usuário"
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      loginAsUserError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const updateActiveDate = (accountDetails) => async (dispatch, getState, { apiAccount }) => {
  const state = getState();
  const accountInfo = !accountDetails ? selector.getAccountModalInfo(state) : accountDetails;

  dispatch(updateAccountActiveDateRequest());
  try {
    const { accountId, date } = accountInfo;

    const params = { accountId, activeUntil: date };

    await apiAccount.account.setAccountActiveUntil(params);

    dispatch([
      actionSnack.showSnackbar({ message: "A data de vencimento foi alterada", variant: 'success', autoHideDuration: 3500 }),
      updateAccountActiveDateSuccess()
    ]);
  } catch (error) {
    // const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: "Não foi possível alterar a data de vencimento"
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      updateAccountActiveDateError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const inactivateAccount = (accountId) => async (dispatch, getState, { apiAccount }) => {
  dispatch(updateInactivateAccountRequest());
  try {
    const params = { accountId };
    const disable = window.confirm('Tem certeza que deseja inativar esse negócio ?');
    if (disable) {
      await apiAccount.account.inactivateAccount(params);

      dispatch([
        actionSnack.showSnackbar({ message: "A conta foi inativada", variant: 'success', autoHideDuration: 3500 }),
        updateInactivateAccountSuccess()
      ]);
    }

  } catch (error) {
    // const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: "Não foi possível inativar o negócio"
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      updateInactivateAccountError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const confirmUserRegistration = (userId) => async (dispatch, getState, { apiAccount }) => {
  dispatch(updateUserRegistrationStatusRequest());
  try {
    const user = await apiAccount.account.confirmUserRegistration(userId);

    dispatch([
      actionSnack.showSnackbar({ message: `Registro de ${user.name} foi confirmado com sucesso`, variant: 'success', autoHideDuration: 3500 }),
      updateUserRegistrationStatusSuccess()
    ]);

  } catch (error) {
    // const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: "Não foi possível confirmar o registro"
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      updateUserRegistrationStatusError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const createUser = (params) => async (dispatch, getState, { apiAccount }) => {
  try {
    const userCreated = await apiAccount.account.createUser(params);

    dispatch([
      actionSnack.showSnackbar({ message: "O usuário foi criado com sucesso", variant: 'success', autoHideDuration: 3500 }),
    ]);
    return { status: 200, userId: userCreated.data.userId }
  } catch (error) {
    // const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: "Não foi possível criar usuário"
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));

    return { status: 400, user: error.user }
  }
}

export const createAccount = (params) => async (dispatch, getState, { apiAccount }) => {
  try {
    const account = await apiAccount.account.createAccount(params);

    dispatch([
      actionSnack.showSnackbar({ message: "A conta foi criada com sucesso", variant: 'success', autoHideDuration: 3500 }),
    ]);

  } catch (error) {
    // const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: "Não foi possível criar a conta"
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const activateAccount = (accountId) => async (dispatch, getState, { apiAccount }) => {
  dispatch(updateActivateAccountRequest());
  try {
    const params = { accountId };
    const disable = window.confirm('Tem certeza que deseja ativar esse negócio ?');
    if (disable) {
      await apiAccount.account.activateAccount(params);

      dispatch([
        actionSnack.showSnackbar({ message: "A conta foi ativada", variant: 'success', autoHideDuration: 3500 }),
        updateActivateAccountSuccess()
      ]);
    }

  } catch (error) {
    // const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: "Não foi possível ativar o negócio"
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      updateActivateAccountError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const loadAccountCurrentPackage = (packageId) => async (dispatch, getState, api) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  try {
    const params = { packageId, header };
    const response = await api.apiAccount.account.getPackage(params); //Plano do negócio


    dispatch([
      setAccountCurrentPackage(response.data.pack[0])
    ]);

  } catch (error) {
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const loadClientData = (clientId) => async (dispatch, getState, api) => {
  dispatch(loadClientRequest());
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  try {
    const params = { header, clientId };
    const response = await api.apiAccount.account.getClientData(params);


    dispatch([loadClientSuccess(response.data)]);

  } catch (error) {
    debugger
    dispatch([loadClientError(error.message)]);
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const loadClientInvoices = () => async (dispatch, getState, api) => {
  dispatch(loadClientInvoicesRequest());
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  try {
    const params = { header };
    const response = await api.apiAccount.account.getClientInvoices(params);
    debugger

    const invoices = response.data.map((inv) => {
      const isOverdue = (inv.billingDate) ? moment.utc(inv.billingDate).isAfter(moment().add(1, 'days').utc()) : false;
      const newInv = {
        ...inv,
        status: (isOverdue) ? 'Pendente' : 'Liquidado',
        invoiceId: (inv.invoiceId) ? inv.invoiceId : null,
        activeUntil: inv.activeUntil ? moment.utc(inv.activeUntil).format('DD/MM/YYYY') : 'Não definido',
        paidAt: inv.paidAt ? moment.utc(inv.paidAt).format('DD/MM/YYYY') : moment.utc(inv.billingDate).format('DD/MM/YYYY'),
        invoice: {
          numero: (inv.invoice && inv.invoice.numero) ? inv.invoice.numero : null,
          dataAutorizacao: (inv.invoice && inv.invoice.dataAutorizacao) ? moment.utc(inv.invoice.dataAutorizacao).format('DD/MM/YYYY') : null,
          dataCompetencia: (inv.invoice && inv.invoice.dataCompetencia) ? moment.utc(inv.invoice.dataCompetencia).format('DD/MM/YYYY') : null,
          status: (inv.invoice && inv.invoice.status) ? inv.invoice.status : null,
          linkDownloadPDF: (inv.invoice && inv.invoice.linkDownloadPDF) ? inv.invoice.linkDownloadPDF : null,
          linkDownloadXML: (inv.invoice && inv.invoice.linkDownloadXML) ? inv.invoice.linkDownloadXML : null
        }
      };
      return newInv;
    });
    dispatch([
      loadClientInvoicesSuccess(invoices)
    ]);

  } catch (error) {
    dispatch([loadClientInvoicesError(error.message)]);
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}


export const getPdfInvoice = (params) => async (dispatch, getState, api) => {
  dispatch(getPdfInvoiceRequest());
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);
  try {
    params = { ...params, header };
    const response = await api.apiAccount.account.getPdfInvoice(params);
    // console.log(response);
    util.createLinkToFile({ base64: response, type: 'stream', filename: params.filename });
    dispatch([
      getPdfInvoiceSuccess()
    ]);

  } catch (error) {
    dispatch([getPdfInvoiceError(error.message)]);
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}


export const getXmlInvoice = (params) => async (dispatch, getState, api) => {
  dispatch(getXmlInvoiceRequest());
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);
  try {
    params = { ...params, header };
    const response = await api.apiAccount.account.getXmlInvoice(params);
    util.createLinkToFile({ base64: response.data.invoiceXML, type: 'xml', filename: params.filename });
    // console.log(response);

    dispatch([
      getXmlInvoiceSuccess()
    ]);

  } catch (error) {
    dispatch([getXmlInvoiceError(error.message)]);
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const getInvoiceStatus = (params) => async (dispatch, getState, api) => {
  dispatch(getInvoiceStatusRequest());
  const state = getState();
  let header = selectorLogin.getHeaderConfig(state);
  header = { ...header, account: params.accountId };
  try {
    params = { ...params, header };
    const response = await api.apiAccount.account.getInvoiceStatus(params);
    const invoices = selector.getInvoices(state);
    const indexInvoice = invoices.findIndex(inv => inv.invoiceId === params.invoiceId);
    invoices[indexInvoice].invoice.status = response.invoice.status;
    dispatch([
      actionSnack.showSnackbar({ message: "O status da nota foi atualizado com sucesso", variant: 'success', autoHideDuration: 3500 }),
      getInvoiceStatusSuccess(),
      loadClientInvoicesSuccess(invoices)
    ]);

  } catch (error) {
    dispatch([getInvoiceStatusError(error.message)]);
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const cancelInvoice = (params) => async (dispatch, getState, api) => {
  dispatch(cancelInvoiceRequest());
  const state = getState();
  let header = selectorLogin.getHeaderConfig(state);
  header = { ...header, account: params.accountId };
  try {
    params = { ...params, header };
    const responseCancel = await api.apiAccount.account.cancelInvoice(params);
    const response = await api.apiAccount.account.getInvoiceStatus(params);
    const invoices = selector.getInvoices(state);
    const indexInvoice = invoices.findIndex(inv => inv.invoiceId === params.invoiceId);
    invoices[indexInvoice].invoice.status = response.invoice.status;
    dispatch([
      actionSnack.showSnackbar({ message: "O Cancelamento da nota fiscal foi solicitado", variant: 'success', autoHideDuration: 3500 }),
      getInvoiceStatusSuccess(),
      cancelInvoiceSuccess(),
      loadClientInvoicesSuccess(invoices)
    ]);

  } catch (error) {
    dispatch([cancelInvoiceError(error.message)]);
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const getClientInvoiceData = (params) => async (dispatch, getState, api) => {
  dispatch(getClientInvoiceDataRequest());
  const state = getState();
  let header = selectorLogin.getHeaderConfig(state);
  header = { ...header, account: params.accountId };
  try {
    const response = await api.apiAccount.account.getClientInvoiceData(params);
    let data = {};
    data = {
      signaturePaymentControlId: params.signaturePaymentControlId,
      signatureTypeId: params.signatureTypeId,
      invoice: {
        cliente: response.invoice.cliente,
        servico: { descricao: response.invoice.servico.descricao },
        dataCompetencia: response.invoice.dataCompetencia,
        valorTotal: response.invoice.valorTotal
      }
    }
    dispatch([
      //actionSnack.showSnackbar({message: "O Cancelamento da nota fiscal foi solicitado", variant: 'success', autoHideDuration: 3500}),
      getClientInvoiceDataSuccess(data),
      cancelInvoiceSuccess()
    ]);

  } catch (error) {
    dispatch([getClientInvoiceDataError(error.message)]);
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const emitInvoice = () => async (dispatch, getState, api) => {
  dispatch(emitInvoiceRequest());
  const state = getState();
  let data = selector.getCurrentClientInvoiceData(state);
  try {
    const responseEmit = await api.apiAccount.account.emitInvoice(data);
    const invoices = selector.getInvoices(state);
    const indexInvoice = invoices.findIndex(inv => inv.id === data.signaturePaymentControlId);
    invoices[indexInvoice].invoiceId = responseEmit.data.nfeId;
    dispatch([
      actionSnack.showSnackbar({ message: "Emissão de nota fiscal solicitada com sucesso", variant: 'success', autoHideDuration: 3500 }),
      getInvoiceStatusSuccess(),
      emitInvoiceSuccess(),
      loadClientInvoicesSuccess(invoices)
    ]);

  } catch (error) {
    dispatch([emitInvoiceError(error.message)]);
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}


export const sendInvoiceToEmail = (params) => async (dispatch, getState, api) => {
  dispatch(sendInvoiceToEmailRequest());
  const state = getState();
  let header = selectorLogin.getHeaderConfig(state);
  header = { ...header, account: params.accountId };
  try {
    params = { ...params, header };
    const response = await api.apiAccount.account.sendInvoiceToEmail(params);
    dispatch([
      actionSnack.showSnackbar({ message: "A nota fiscal foi enviada para " + response.email, variant: 'success', autoHideDuration: 3500 }),
      sendInvoiceToEmailSuccess()
    ]);

  } catch (error) {
    dispatch([sendInvoiceToEmailError(error.message)]);
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const editAndResendConfirmationEmail = (params) => async (dispatch, getState, api) => {
  dispatch(editAndResendConfirmationEmailRequest());
  const state = getState();
  let header = selectorLogin.getHeaderConfig(state);
  try {
    const response = await api.apiAccount.account.updateAndResendConfirmationEmail(params);
    dispatch([
      actionSnack.showSnackbar({ message: "Email de confirmação reenviado com sucesso", variant: 'success', autoHideDuration: 3500 }),
      editAndResendConfirmationEmailSuccess()
    ]);

  } catch (error) {
    dispatch([editAndResendConfirmationEmailError(error.message)]);
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const loadPackagesInfo = () => async (dispatch, getState, api) => {
  dispatch(packageModelsFetchRequest());
  try {
    const response = await api.plans.getPlansModel(); //Planos do sistema

    dispatch([
      packageModelsFetchSuccess(response.data.packages)
    ]);

  } catch (error) {
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      packageModelsFetchError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const updateManagerPassword = () => async (dispatch, getState, { apiAccount }) => {
  const state = getState();
  const managerId = selectorLogin.getAuthUserId(state);
  const passwordData = selector.getManagerPasswordForm(state);
  const { newPassword, newPassword2 } = passwordData;
  if (newPassword !== newPassword2) {
    dispatch(
      actionSnack.showSnackbar({ message: "A senha confirmada e a nova senha devem ser iguais!", variant: 'error', autoHideDuration: 3500 })
    );
    return;
  }
  dispatch(changePasswordFetchRequest());
  try {
    const params = { ...passwordData };
    await apiAccount.account.updateManagerPassword(managerId, params);

    dispatch([
      actionSnack.showSnackbar({ message: "Senha Alterada com sucesso!", variant: 'success', autoHideDuration: 3500 }),
      dispatch(changePasswordFetchSuccess())
    ]);

  } catch (error) {
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      changePasswordFetchError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const registerManager = (params) => async (dispatch, getState, { apiAccount }) => {
  const state = getState();
  const formData = selector.getFormData(state);

  dispatch(registerManagerFetchRequest());
  try {
    const params = { ...formData };
    await apiAccount.account.registerManagerUser(params);

    dispatch([
      actionSnack.showSnackbar({ message: "Administrador cadastrado com sucesso!", variant: 'success', autoHideDuration: 3500 }),
      dispatch(registerManagerFetchSuccess())
    ]);

  } catch (error) {
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      registerManagerFetchError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const updatePackageData = () => async (dispatch, getState, { apiAccount }) => {
  const state = getState();
  const newPackage = selector.getAccountModifiedPackage(state);
  // console.log('New Package ', newPackage)

  dispatch(updateAccountPackageRequest());
  try {
    const params = { ...newPackage };
    await apiAccount.account.updatePackage(params);

    dispatch([
      actionSnack.showSnackbar({ message: "O pacote foi alterado com sucesso", variant: 'success', autoHideDuration: 3500 }),
      dispatch(updateAccountPackageSuccess())
    ]);

  } catch (error) {
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      updateAccountPackageError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const deletePendingRegistrationUser = (userId) => async (dispatch, getState, { apiAccount }) => {
  dispatch(deleteUserRequest());
  const state = getState();

  try {
    const params = { userId };
    await apiAccount.account.deletePendingRegistrationUser(params)

    dispatch([
      actionSnack.showSnackbar({ message: "O usuário foi apagado com sucesso", variant: 'success', autoHideDuration: 3500 }),
      dispatch(deleteUserSuccess())
    ]);

  } catch (error) {
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      deleteUserError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const searchUser = (value) => async (dispatch, getState, { apiAccount }) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);
  const isEmailSearch = (value === null) || util.email(value) ? false : true;
  dispatch(searchUserFetchRequest());
  try {
    let searchResult;
    const userSet = new Set();
    if (isEmailSearch) {
      const response = await apiAccount.account.getUserByEmail({ email: value, header });
      searchResult = response.data.users;
    } else {
      const response = await apiAccount.account.getByName({ name: value, header });
      searchResult = response.data.users;
      // console.log('RESPONSE DATA ', response.data)
      // for (let responseType in response.data) {
      //   // console.log('RESPONSE TYPE ', responseType)
      //   if (response.data[responseType] === response.data.users) {
      //     // console.log('TYPE ', responseType)
			// 		for (let user of response.data[responseType]) {
      //       if (!userSet.has(user.userId)) {
      //         userSet.add(user.userId);
			// 				searchResult.push(user);
			// 			}
			// 		}
			// 	} else {
      //     // console.log('TYPE ', responseType)
      //     if (response.data[responseType].length) {
			// 			for (let user of response.data[responseType]) {
			// 				if (!userSet.has(user.userId)) {
      //           userSet.add(user.userId);
      //           searchResult.push(user);
      //         }
      //         if (!activeUsers.has(user.userId)) {
      //           activeUsers.add(user.userId)
      //         }
			// 			}
			// 		}
			// 	}
      // }
    }

    dispatch(searchUserFetchSuccess(searchResult))
  } catch (error) {
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      searchUserFetchError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const getInitialCounters = () => async (dispatch, getState, { apiAccount }) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  try {
    const response1 = await apiAccount.account.getAccounts({ header }); //Quantidade de negócios no sistema
    const response2 = await apiAccount.account.getUserCount({ header }); //Quantidade de usuários no sistema

    const accounts = response1.data.updtdAccs
    let activeAccounts = 0
    let inactiveAccounts = 0
    for (const account of accounts) {
      account.active ? ++activeAccounts : ++inactiveAccounts
    }

    dispatch([setAccountCount({
      accountsCount: accounts.length,
      activeAccountsCount: activeAccounts,
      inactiveAccountsCount: inactiveAccounts
    }), setUserCount({
      userCount: response2.data.userCount,
      activeUsersCount: response2.data.activeUsersCount,
      inactiveUsersCount: response2.data.inactiveUsersCount
    })]);

  } catch (error) {
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const getAccounts = () => async (dispatch, getState, { apiAccount }) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);
  const isPackagesFetched = selector.isPackagesFetched(state);

  dispatch(getAccountsFetchRequest());
  try {
    const response = await apiAccount.account.getAccounts({ header });
    const accounts = response.data.updtdAccs;

    let activeAccounts = 0
    let inactiveAccounts = 0
    for (let i = 0; i < accounts.length; i++) {
      accounts[i].active ? ++activeAccounts : ++inactiveAccounts
      accounts[i].active = accounts[i].active ? "Ativo" : "Inativo";

      if (!isPackagesFetched)
        dispatch(setAccountPackageId({ [accounts[i].accountId]: accounts[i].packageId }));
    }

    dispatch([
      getAccountsFetchSuccess(response.data.updtdAccs),
      setPackagesFetched(),
      setAccountCount({
        accountsCount: accounts.length,
        activeAccountsCount: activeAccounts,
        inactiveAccountsCount: inactiveAccounts
      })
    ]);

  } catch (error) {
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      getAccountsFetchError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const createGroupAccount = (params) => async (dispatch, getState, { apiAccount }) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);
  const groups = selector.getListGroupPermission(state);

  try {
    await apiAccount.groupPermission.createGroupAccount({ header, params });

    dispatch([
      setListGroupAccountFetchSuccess([...groups, params.groupPermission]),
      getListGroupAccount(),
    ]);

  } catch (error) {
    const { userFeedback } = error;

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      getAccountsFetchError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const createQualifier = (params, modal) => async (dispatch, getState, { apiForms }) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  try {
    await apiForms.qualifier.createQualifier({ header, params });

    dispatch([
      getListQualifier(),
      actionsModal.onClose({modal}),
        actionSnack.showSnackbar({
          message: 'Qualificador criado com sucesso!',
          variant: 'success',
          autoHideDuration: 3000,
        })
    ]);

  } catch (error) {
    const { userFeedback } = error;

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const updateQualifier = (params) => async (dispatch, getState, { apiForms }) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  try {
    await apiForms.qualifier.updateQualifier({ header, params });

    dispatch([
      getListQualifier(),
    ]);

  } catch (error) {
    const { userFeedback } = error;

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const getListQualifier = () => async (dispatch, getState, { apiForms }) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  try {
    const response = await apiForms.qualifier.getQualifiers({ header });

    dispatch([
      setListQualifiersFetchSuccess(response.data ? response.data.qualifiers : []),
    ]);

  } catch (error) {
    const { userFeedback } = error;

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      getAccountsFetchError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const deleteQualifier =
(qualifier) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      const header = selectorLogin.getHeaderConfig(state);

      try {
        const params = {
          id: qualifier._id,
        };

        ModalConfirm({
          centered: true,
          maskClosable: true,
          title: 'Atenção',
          content: `Você realmente deseja excluir o qualificador ${qualifier.name}?
        Esta ação é irreversível.`,
          cancelText: 'Voltar',
          okText: 'Excluir',
          okButtonProps: {
            type: 'danger',
          },
          onOk: async () => {
            await apiForms.qualifier.deleteQualifier({ header, params });
            dispatch([
              getListQualifier(),
              actionSnack.showSnackbar({
                message: 'Documento excluído com sucesso!',
                variant: 'success',
                autoHideDuration: 3000,
              })
            ]);
          },
        });
      } catch (error) {
        dispatch(
          actionSnack.showSnackbar({
            message: 'Ocorreu um erro',
            variant: 'error',
            autoHideDuration: 3000,
          })
        );
      }
    };

export const getListCoreLine = () => async (dispatch, getState, { apiMedicalRecords}) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  try {
    const response = await apiMedicalRecords.coreLine.getCoreLines({ header });

    dispatch([
      setListCoreLinesFetchSuccess(response.data ? response.data.coreLines : []),
    ]);

  } catch (error) {
    const { userFeedback } = error;

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const createCoreLine = (coreLine, modal) => async (dispatch, getState, { apiMedicalRecords}) => {
    const state = getState();
    const header = selectorLogin.getHeaderConfig(state);

    try {
      const response = await apiMedicalRecords.coreLine.createCoreLine({ header, params: {coreLine} });

      dispatch([
        getListCoreLine(),
        actionsModal.onClose({modal}),
        actionSnack.showSnackbar({
          message: 'CoreLine criado com sucesso!',
          variant: 'success',
          autoHideDuration: 3000,
        })
      ]);

    } catch (error) {
      const { userFeedback } = error;

      const uriMessage = util.snackBarManagerExceptionURIMessage({
        customMsg: userFeedback
      })
      dispatch([
        actionSnack.showSnackbar(uriMessage),
      ]);
    }
  }

  export const updateCoreLine = (coreLine, modal) => async (dispatch, getState, { apiMedicalRecords}) => {
    const state = getState();
    const header = selectorLogin.getHeaderConfig(state);

    try {
      const response = await apiMedicalRecords.coreLine.updateCoreLine({ header, params: {coreLine} });

      dispatch([
        getListCoreLine(),
        actionsModal.onClose({modal}),
        actionSnack.showSnackbar({
          message: 'Linha de cuidado atualizado com sucesso!',
          variant: 'success',
          autoHideDuration: 3000,
        })
      ]);

    } catch (error) {
      const { userFeedback } = error;

      const uriMessage = util.snackBarManagerExceptionURIMessage({
        customMsg: userFeedback
      });
      dispatch([
        actionSnack.showSnackbar(uriMessage),
      ]);
    }
  }

export const deleteCoreLine = ({_id}) => async (dispatch, getState, { apiMedicalRecords}) => {
    const state = getState();
    const header = selectorLogin.getHeaderConfig(state);

    try {
      const response = await apiMedicalRecords.coreLine.deleteCoreLine({ header, params: {_id}});

      dispatch([
        getListCoreLine(),
        actionSnack.showSnackbar({
          message: 'Linha de cuidado excluída com sucesso!',
          variant: 'success',
          autoHideDuration: 3000,
        })
      ]);

    } catch (error) {
      const { userFeedback } = error;

      const uriMessage = util.snackBarManagerExceptionURIMessage({
        customMsg: userFeedback
      });
      dispatch([
        actionSnack.showSnackbar(uriMessage),
      ]);
    }
}

export const deleteGroup = 
  (group) =>
    async (dispatch, getState, { apiAccount }) => {
      const state = getState();
      const header = selectorLogin.getHeaderConfig(state);

      try {
        const params = {
          id: group._id,
        };

        ModalConfirm({
          centered: true,
          maskClosable: true,
          title: 'Atenção',
          content: `Você realmente deseja excluir o grupo ${group.name}?
        Esta ação é irreversível.`,
          cancelText: 'Voltar',
          okText: 'Excluir',
          okButtonProps: {
            type: 'danger',
          },
          onOk: async () => {
            await apiAccount.groupPermission.deleteGroupAccount({ header, params });
            dispatch([
              getListGroupAccount(),
              actionSnack.showSnackbar({
                message: 'Documento excluído com sucesso!',
                variant: 'success',
                autoHideDuration: 3000,
              })
            ]);
          },
        });
      } catch (error) {
        dispatch(
          actionSnack.showSnackbar({
            message: 'Ocorreu um erro',
            variant: 'error',
            autoHideDuration: 3000,
          })
        );
      }
    };

export const updateGroupAccount = (params) => async (dispatch, getState, { apiAccount }) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);
  const groups = selector.getListGroupPermission(state);

  try {
    await apiAccount.groupPermission.updateGroupAccount({ header, params });

    const newListGroup = groups.map(group => {
      if (group._id === params.id)
        return params.groupPermission
      return group
    })

    dispatch([
      setListGroupAccountFetchSuccess(newListGroup),
      getListGroupAccount(),
    ]);

  } catch (error) {
    const { userFeedback } = error;
    console.log(error);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      getAccountsFetchError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }

}
  

export const getListGroupAccount = () => async (dispatch, getState, { apiAccount }) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  try {
    const response = await apiAccount.groupPermission.getGroupAccount({ header });

    dispatch([
      setListGroupAccountFetchSuccess(response.data),
    ]);

  } catch (error) {
    const { userFeedback } = error;

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      getAccountsFetchError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

export const getManagers = () => async (dispatch, getState, { apiAccount }) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  dispatch(getManagersFetchRequest());
  try {
    const response = await apiAccount.account.getManagers({ header });
    dispatch(getManagersFetchSuccess(response.data.managerUsers));

  } catch (error) {
    const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      getManagersFetchError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}


export const removeAsManager = (userId) => async (dispatch, getState, { apiAccount }) => {
  dispatch(setUserAsManagerFetchRequest());
  try {
    const params = { userId };
    await apiAccount.account.removeUserManagerAccess(params);

    dispatch([
      actionSnack.showSnackbar({ message: "O acesso ao Manager foi removido com sucesso", variant: 'success', autoHideDuration: 3500 }),
      dispatch(setUserAsManagerFetchSuccess())
    ]);

  } catch (error) {
    // const { userFeedback } = error;
    console.log(error);
    console.error("[catch]:", error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: "Não foi possível remover o acesso ao Manager"
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      setUserAsManagerFetchError(error)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}


export const actions = {
  searchUser, deleteGroup, updateGroupAccount, deletePendingRegistrationUser, getListQualifier, updateQualifier, deleteQualifier, createGroupAccount, createQualifier, getListCoreLine, createCoreLine, updateCoreLine,
  deleteCoreLine, getListGroupAccount, getAccounts, loadClientInvoices, clearSearch, createUser, createAccount, manageInitialUserAccess, registerManager, removeAsManager,
  changeAccountModal, changeUserModal, updateActiveDate, inactivateAccount, confirmUserRegistration, activateAccount, loginAsUser, getManagers, loadPackagesInfo, editAndResendConfirmationEmail,
  setAccountPackageId, loadAccountCurrentPackage, loadClientData, setAccountPackageData, updatePackageData,
  updateManagerPassword, getInitialCounters, setFormData, setPasswordData, clearForm,
  clearPasswordForm, getXmlInvoice, getPdfInvoice, getInvoiceStatus, cancelInvoice, sendInvoiceToEmail,
  getClientInvoiceData, emitInvoice, setClientInvoiceData
}
//#endregion

//#endregion #### Action Creators ####

//#region Selectors
const getManagerContextByKey = key => state => state.manager[key];
const getManagerApiContextByKey = key => state => state.manager.api[key];
const getManagerFetchingByKey = key => state => state.manager.fetching[key];

export const selector = ({
  getManagerPasswordForm: createSelector(
    getManagerContextByKey('passwordData'),
    state => state
  ),
  getAccountModalInfo: createSelector(
    getManagerContextByKey('accountModal'),
    state => state
  ),
  getSearchedUsers: createSelector(
    getManagerApiContextByKey('searchedUsers'),
    state => state
  ),
  getHasLoadedInitialUsers: createSelector(
    getManagerApiContextByKey('hasLoadedInitialUsers'),
    state => state
  ),
  getIsResendingEmail: createSelector(
    getManagerFetchingByKey('isResendingEmail'),
    state => state
  ),
  getIsConfirmingRegistration: createSelector(
    getManagerFetchingByKey('isConfirmingRegistration'),
    state => state
  ),
  getIsDeletingUser: createSelector(
    getManagerFetchingByKey('isDeletingUser'),
    state => state
  ),
  hasSearched: createSelector(
    getManagerApiContextByKey('hasSearched'),
    state => state
  ),
  getInvoices: createSelector(
    getManagerApiContextByKey('invoices'),
    state => state
  ),
  getAccounts: createSelector(
    getManagerApiContextByKey('accounts'),
    state => state
  ),
  getUserModalData: createSelector(
    getManagerApiContextByKey('userModal'),
    state => state
  ),
  getCurrentClientData: createSelector(
    getManagerContextByKey('currentClientData'),
    state => state
  ),
  getManagers: createSelector(
    getManagerApiContextByKey('managerUsers'),
    state => state
  ),
  getPackageModels: createSelector(
    getManagerApiContextByKey('packageModels'),
    state => state
  ),
  getUsersCount: createSelector(
    getManagerApiContextByKey('userCount'),
    state => state
  ),
  getActiveUsersQnt: createSelector(
    getManagerApiContextByKey('activeUsersCount'),
    state => state
  ),
  getInactiveUsersQnt: createSelector(
    getManagerApiContextByKey('inactiveUsersCount'),
    state => state
  ),
  getAccountsCount: createSelector(
    getManagerApiContextByKey('accountsCount'),
    state => state
  ),
  getActiveAccountsCount: createSelector(
    getManagerApiContextByKey('activeAccountsCount'),
    state => state
  ),
  getInactiveAccountsCount: createSelector(
    getManagerApiContextByKey('inactiveAccountsCount'),
    state => state
  ),
  getFormData: createSelector(
    getManagerContextByKey('formData'),
    state => state
  ),
  getAccountCurrentPackage: createSelector(
    getManagerContextByKey('currentPackage'),
    state => state
  ),
  getCurrentClientInvoiceData: createSelector(
    getManagerContextByKey('currentClientInvoiceData'),
    state => state
  ),
  getAccountModifiedPackage: createSelector(
    getManagerContextByKey('newPackageData'),
    state => state
  ),
  tableLoaded: createSelector(
    getManagerApiContextByKey('tableLoaded'),
    state => state
  ),
  getListGroupPermission: createSelector(
    getManagerApiContextByKey('groupsPermission'),
    state => state
  ),
  getQualifiers: createSelector(
    getManagerApiContextByKey('qualifiers'),
    state => state
  ),
  getCoreLines: createSelector(
    getManagerApiContextByKey('coreLines'),
    state => state
  ),
  getUserToken: createSelector(
    getManagerApiContextByKey('userToken'),
    state => state
  ),
  getAccountsPackageId: createSelector(
    getManagerApiContextByKey('accountsPackageId'),
    state => state
  ),
  isRegisteringManager: createSelector(
    getManagerFetchingByKey('registeringManager'),
    state => state
  ),
  isPasswordChanging: createSelector(
    getManagerFetchingByKey('isPasswordChanging'),
    state => state
  ),
  isFetchingAccounts: createSelector(
    getManagerFetchingByKey('fetchingAccounts'),
    state => state
  ),
  isFetchingPackages: createSelector(
    getManagerFetchingByKey('isFetchingPackages'),
    state => state
  ),
  isPackagesFetched: createSelector(
    getManagerFetchingByKey('packagesFetched'),
    state => state
  ),
  isUpdatingManagerAccess: createSelector(
    getManagerFetchingByKey('updatingManagerAccess'),
    state => state
  ),
  isUpdatingActiveDate: createSelector(
    getManagerFetchingByKey('updatingActiveDate'),
    state => state
  ),
  isUpdatingAccountPackage: createSelector(
    getManagerFetchingByKey('updatingAccountPackage'),
    state => state
  ),
  isFetchingClientData: createSelector(
    getManagerFetchingByKey('isLoadingClientData'),
    state => state
  ),
  isLoadingInvoiceStatus: createSelector(
    getManagerFetchingByKey('isLoadingInvoiceStatus'),
    state => state
  ),
  isCancelingInvoice: createSelector(
    getManagerFetchingByKey('isCancelingInvoice'),
    state => state
  ),
  isLoadingPdf: createSelector(
    getManagerFetchingByKey('isLoadingPdf'),
    state => state
  ),
  isLoadingXml: createSelector(
    getManagerFetchingByKey('isLoadingXml'),
    state => state
  ),
  isSendingEmail: createSelector(
    getManagerFetchingByKey('isSendingEmail'),
    state => state
  ),
  isLoadingClientInvoiceData: createSelector(
    getManagerFetchingByKey('isLoadingClientInvoiceData'),
    state => state
  ),
  isEmittingInvoice: createSelector(
    getManagerFetchingByKey('isEmittingInvoice'),
    state => state
  ),
});
//#endregion

//#region Reducers
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.MANAGER_REGISTER_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          registeringManager: true,
        }
      }
    }
    case types.MANAGER_REGISTER_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          registeringManager: false,
        }
      }
    }
    case types.MANAGER_REGISTER_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          registeringManager: false,
        }
      }
    }

    case types.MANAGER_CHANGE_PASSWORD_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isPasswordChanging: true,
        }
      }
    }
    case types.MANAGER_CHANGE_PASSWORD_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isPasswordChanging: false,
        }
      }
    }
    case types.MANAGER_CHANGE_PASSWORD_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isPasswordChanging: false,
        }
      }
    }

    case types.MANAGER_SEARCH_USER_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isSearching: true,
        }
      }
    }
    case types.MANAGER_SEARCH_USER_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isSearching: false,
        }
      }
    }
    case types.MANAGER_SEARCH_USER_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isSearching: false,
        },
        api: {
          ...state.api,
          searchedUsers: [
            ...action.payload
          ],
          hasSearched: true,
          hasLoadedInitialUsers: !state.api.hasLoadedInitialUsers && !state.api.searchedUsers.length ? true : false
        }
      }
    }

    case types.MANAGER_GET_ACCOUNTS_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchingAccounts: true,
        }
      }
    }
    case types.MANAGER_GET_ACCOUNTS_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchingAccounts: false,
        }
      }
    }
    case types.MANAGER_GET_ACCOUNTS_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchingAccounts: false,
        },
        api: {
          ...state.api,
          accounts: [
            ...action.payload
          ],
          tableLoaded: true
        }
      }
    }
    case types.MANAGER_SET_LIST_GROUP_ACCOUNT: {
      return {
        ...state,
        api: {
          ...state.api,
          groupsPermission: action.payload,
          tableLoaded: true
        }
      }
    }
    case types.MANAGER_SET_LIST_QUALIFIERS:{
      return {
        ...state,
        api: {
          ...state.api,
          qualifiers: action.payload,
          tableLoaded: true
        }
      }
    }
    case types.MANAGER_SET_LIST_CORE_LINES:{
      return {
        ...state,
        api: {
          ...state.api,
          coreLines: action.payload,
          tableLoaded: true
        }
      }
    }
    case types.MANAGER_GET_MANAGERS_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchingAccounts: true,
        }
      }
    }
    case types.MANAGER_GET_MANAGERS_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchingAccounts: false,
        }
      }
    }
    case types.MANAGER_GET_MANAGERS_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          fetchingAccounts: false,
        },
        api: {
          ...state.api,
          managerUsers: [
            ...action.payload
          ],
          // tableLoaded: true
        }
      }
    }

    case types.MANAGER_GET_PACKAGE_MODELS_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingPackages: true,
        }
      }
    }
    case types.MANAGER_GET_PACKAGE_MODELS_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingPackages: false,
        }
      }
    }
    case types.MANAGER_GET_PACKAGE_MODELS_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingPackages: false,
        },
        api: {
          ...state.api,
          packageModels: [
            ...action.payload
          ],
        }
      }
    }

    case types.MANAGER_CLEAR_SEARCH_USER: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isSearching: false,
        },
        api: {
          ...state.api,
          searchedUsers: [],
          hasSearched: INITIAL_STATE.api.hasSearched
        }
      }
    }

    case types.MANAGER_CLEAR_REGISTER_FORM: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          registeringManager: false,
        },
        formData: {}
      }
    }

    case types.MANAGER_CLEAR_PASSWORD_FORM: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isPasswordChanging: false,
        },
        passwordData: {}
      }
    }

    case types.MANAGER_SET_USER_AS_MANAGER_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingManagerAccess: true,
        }
      }
    }
    case types.MANAGER_SET_USER_AS_MANAGER_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingManagerAccess: false,
        }
      }
    }
    case types.MANAGER_SET_USER_AS_MANAGER_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingManagerAccess: false,
        }
      }
    }

    case types.MANAGER_REMOVE_USER_AS_MANAGER_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingManagerAccess: true,
        }
      }
    }
    case types.MANAGER_REMOVE_USER_AS_MANAGER_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingManagerAccess: false,
        }
      }
    }
    case types.MANAGER_REMOVE_USER_AS_MANAGER_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingManagerAccess: false,
        }
      }
    }

    case types.MANAGER_UPDATE_ACCOUNT_PACKAGE_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingAccountPackage: true,
        }
      }
    }
    case types.MANAGER_UPDATE_ACCOUNT_PACKAGE_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingAccountPackage: false,
        }
      }
    }
    case types.MANAGER_UPDATE_ACCOUNT_PACKAGE_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingAccountPackage: false,
        }
      }
    }

    case types.MANAGER_UPDATE_ACTIVE_DATE_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingActiveDate: true,
        }
      }
    }
    case types.MANAGER_UPDATE_ACTIVE_DATE_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingActiveDate: false,
        }
      }
    }
    case types.MANAGER_UPDATE_ACTIVE_DATE_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingActiveDate: false,
        }
      }
    }

    case types.MANAGER_INACTIVATE_ACCOUNT_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingInactivateAccount: true,
        }
      }
    }
    case types.MANAGER_INACTIVATE_ACCOUNT_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingInactivateAccount: false,
        }
      }
    }
    case types.MANAGER_INACTIVATE_ACCOUNT_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingInactivateAccount: false,
        }
      }
    }

    case types.MANAGER_ACTIVATE_USER_REGISTRATION_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isConfirmingRegistration: true,
        }
      }
    }
    case types.MANAGER_ACTIVATE_USER_REGISTRATION_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isConfirmingRegistration: false,
        }
      }
    }
    case types.MANAGER_ACTIVATE_USER_REGISTRATION_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isConfirmingRegistration: false,
        }
      }
    }

    case types.MANAGER_ACTIVATE_ACCOUNT_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingActivateAccount: true,
        }
      }
    }
    case types.MANAGER_ACTIVATE_ACCOUNT_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingActivateAccount: false,
        }
      }
    }
    case types.MANAGER_ACTIVATE_ACCOUNT_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          updatingActivateAccount: false,
        }
      }
    }

    case types.MANAGER_LOGIN_AS_USER_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          loggingUser: true,
        }
      }
    }
    case types.MANAGER_LOGIN_AS_USER_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          loggingUser: false,
        }
      }
    }
    case types.MANAGER_LOGIN_AS_USER_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          loggingUser: false,
        },
        api: {
          ...state.api,
          userToken: action.payload
        }
      }
    }

    case types.MANAGER_DELETE_USER_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isDeletingUser: true,
        }
      }
    }
    case types.MANAGER_DELETE_USER_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isDeletingUser: false,
        }
      }
    }
    case types.MANAGER_DELETE_USER_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isDeletingUser: false,
        }
      }
    }

    case types.MANAGER_SET_PASSWORD_DATA: {
      return {
        ...state,
        passwordData: {
          ...state.passwordData,
          ...action.payload
        },
      }
    }

    case types.MANAGER_SET_REGISTER_DATA: {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload
        },
      }
    }
    case types.MANAGER_SET_CLIENT_INVOICE_DATA: {
      return {
        ...state,
        currentClientInvoiceData: action.payload
      }
    }

    case types.MANAGER_SET_ACCOUNT_PACKAGE_DATA: {
      return {
        ...state,
        newPackageData: {
          ...state.newPackageData,
          ...action.payload
        },
      }
    }

    case types.MANAGER_SET_USER_COUNT: {
      return {
        ...state,
        api: {
          ...state.api,
          userCount: action.payload.userCount,
          activeUsersCount: action.payload.activeUsersCount,
          inactiveUsersCount: action.payload.inactiveUsersCount
        },
      }
    }

    case types.MANAGER_SET_ACCOUNT_COUNT: {
      return {
        ...state,
        api: {
          ...state.api,
          accountsCount: action.payload.accountsCount,
          activeAccountsCount: action.payload.activeAccountsCount,
          inactiveAccountsCount: action.payload.inactiveAccountsCount
        },
      }
    }

    case types.MANAGER_SET_ACCOUNT_CURRENT_PACKAGE: {
      return {
        ...state,
        currentPackage: {
          ...action.payload
        },
        newPackageData: {
          ...action.payload
        },
      }
    }

    case types.MANAGER_SET_ACCOUNT_PACKAGE_ID: {
      return {
        ...state,
        api: {
          ...state.api,
          accountsPackageId: {
            ...state.api.accountsPackageId,
            ...action.payload
          }
        },
      }
    }

    case types.MANAGER_PACKAGES_FETCHED: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          packagesFetched: false,
        }
      }
    }
    case types.MANAGER_LOAD_CLIENT_REQUEST: {
      debugger
      return {
        ...state,
        currentClientData: {
          ...state.currentClientData,
          accountPlan: {},
          paymentHistory: []
        },
        fetching: {
          ...state.fetching,
          isLoadingClientData: true
        }
      }
    }
    case types.MANAGER_LOAD_CLIENT_SUCCESS: {
      debugger
      return {
        ...state,
        currentClientData: {
          ...state.currentClientData,
          accountPlan: action.payload.accountPlan,
          paymentHistory: action.payload.paymentHistory
        },
        fetching: {
          ...state.fetching,
          isLoadingClientData: false
        }
      }
    }
    case types.MANAGER_LOAD_CLIENT_ERROR: {
      return {
        ...state,
        currentClientData: {
          ...state.currentClientData,
          error: action.payload
        },
        fetching: {
          ...state.fetching,
          isLoadingClientData: false
        }
      }
    }
    /*---*/
    case types.MANAGER_LOAD_CLIENT_INVOICES_REQUEST: {
      return {
        ...state,
        api: {
          ...state.api,
          tableLoaded: false
        },
        fetching: {
          ...state.fetching,
          isLoadingInvoices: true
        }
      }
    }
    case types.MANAGER_LOAD_CLIENT_INVOICES_SUCCESS: {
      return {
        ...state,
        api: {
          ...state.api,
          invoices: action.payload,
          tableLoaded: true
        },
        fetching: {
          ...state.fetching,
          isLoadingInvoices: false
        }
      }
    }
    case types.MANAGER_LOAD_CLIENT_INVOICES_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingInvoices: false
        }
      }
    }

    case types.MANAGER_GET_PDF_INVOICE_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingPdf: true
        }
      }
    }
    case types.MANAGER_GET_PDF_INVOICE_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingPdf: false
        }
      }
    }
    case types.MANAGER_GET_PDF_INVOICE_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingPdf: false
        }
      }
    }


    case types.MANAGER_GET_XML_INVOICE_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingXml: true
        }
      }
    }
    case types.MANAGER_GET_XML_INVOICE_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingXml: false
        }
      }
    }
    case types.MANAGER_GET_XML_INVOICE_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingXml: false
        }
      }
    }


    case types.MANAGER_CANCEL_INVOICE_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isCancelingInvoice: true
        }
      }
    }
    case types.MANAGER_CANCEL_INVOICE_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isCancelingInvoice: false
        }
      }
    }
    case types.MANAGER_CANCEL_INVOICE_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isCancelingInvoice: false
        }
      }
    }

    case types.MANAGER_GET_INVOICE_STATUS_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingInvoiceStatus: true
        }
      }
    }
    case types.MANAGER_GET_INVOICE_STATUS_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingInvoiceStatus: false
        }
      }
    }
    case types.MANAGER_GET_INVOICE_STATUS_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingInvoiceStatus: false
        }
      }
    }

    case types.MANAGER_SEND_INVOICE_TO_EMAIL_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isSendingEmail: true
        }
      }
    }
    case types.MANAGER_SEND_INVOICE_TO_EMAIL_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isSendingEmail: false
        }
      }
    }
    case types.MANAGER_SEND_INVOICE_TO_EMAIL_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isSendingEmail: false
        }
      }
    }

    case types.MANAGER_EDIT_AND_RESEND_CONFIRMATION_EMAIL_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isResendingEmail: true
        }
      }
    }
    case types.MANAGER_EDIT_AND_RESEND_CONFIRMATION_EMAIL_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isResendingEmail: false
        }
      }
    }
    case types.MANAGER_EDIT_AND_RESEND_CONFIRMATION_EMAIL_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isResendingEmail: false
        }
      }
    }

    case types.MANAGER_GET_CLIENT_INVOICE_DATA_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingClientInvoiceData: true
        },
        currentClientInvoiceData: {}
      }
    }
    case types.MANAGER_GET_CLIENT_INVOICE_DATA_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingClientInvoiceData: false
        },
        currentClientInvoiceData: action.payload
      }
    }
    case types.MANAGER_GET_CLIENT_INVOICE_DATA_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingClientInvoiceData: false
        }
      }
    }

    case types.MANAGER_EMIT_INVOICE_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isEmittingInvoice: true
        }
      }
    }
    case types.MANAGER_EMIT_INVOICE_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isEmittingInvoice: false
        }
      }
    }
    case types.MANAGER_EMIT_INVOICE_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isEmittingInvoice: false
        }
      }
    }


    case types.MANAGER_CHANGE_ACCOUNT_MODAL: {
      return {
        ...state,
        accountModal: {
          ...state.accountModal,
          ...action.payload
        }
      }
    }

    case types.MANAGER_CHANGE_USER_MODAL: {
      return {
        ...state,
        api: {
          ...state.api,
          userModal: {
            ...state.userModal,
            ...action.payload
          }
        }
      }
    }

    default: return state;
  }
};
//#endregion


import * as field         from './field';
import * as form          from './form';
import * as category      from './category';
import * as qualifier     from './qualifier';

export default ({
  field,
  form,
  category,
  qualifier,
});
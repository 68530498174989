import React, { Component }   from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles }         from '@material-ui/core/styles';
import Grid                   from '@material-ui/core/Grid';
import Paper                  from '@material-ui/core/Paper';
import { toastr }             from 'react-redux-toastr';

import { styles }             from './style';

import Header                 from './header';
import Form                   from './form';

import { selector }               from '@State/ducks/login/';
import { actions }            from '@State/ducks/login/signIn';

class SignIn extends Component {
  constructor (props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  render () {
    const { classes, signInState } = this.props;

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.item}>
          <div className={classes.contentForms}>
            <Paper className={classes.control}>
              <Header classes={classes} />
              <Form
                classes={classes}
                validation={signInState.validation}
                isLoading={signInState.fetching}
                onSubmit={this.onSubmit}
              />
            </Paper>
          </div>
        </Grid>
      </Grid>
    );
  }

  componentDidUpdate () {
    const { signInState } = this.props;

    if (signInState.message && signInState.message.length) {
      toastr.warning('Atenção', signInState.message);
    }
  }

  componentWillMount () {
    localStorage.removeItem('auth');
  }

  onSubmit (values) {
    const {
      setSignIn,
      setClearFormError,
      setSignInInvalidEmail,
      setSignInInvalidPassword
    } = this.props;

    if (!values.email) {
      setSignInInvalidEmail();
      return;
    }

    if (!values.password || values.password.length < 6) {
      setSignInInvalidPassword();
      return;
    }

    setClearFormError();
    setSignIn(values);
  }
};

const mapStateToProps = state => ({
  signInState   : selector.getSignIn(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setSignIn                 : actions.signIn,
  setClearFormError         : actions.setClearFormError,
  setSignInInvalidEmail     : actions.setSignInInvalidEmail,
  setSignInInvalidPassword  : actions.setSignInInvalidPassword
}, dispatch);

SignIn = withStyles(styles)(SignIn);
SignIn = connect(mapStateToProps, mapDispatchToProps)(SignIn);

export default SignIn;

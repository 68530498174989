import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { Field, reduxForm }           from 'redux-form';
import { withRouter }                 from 'react-router-dom';
import TextField                      from '@material-ui/core/TextField';
import Fab                            from '@material-ui/core/Fab';
import FormHelperText                 from '@material-ui/core/FormHelperText';
import Grid                           from '@material-ui/core/Grid';

import * as util                      from '@Util';

class Form extends Component {
  constructor (props) {
    super(props);
    this.renderField    = this.renderField.bind(this);
    this.redirectRoute  = this.redirectRoute.bind(this);
  }

  render () {
    const { isLoading, validation, classes, onSubmit, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(onSubmit) }>
        <div>
          <Field
            type="text"
            name="email"
            placeholder="Email"
            label="Email"
            error={validation.email || validation.emailFormat}
            className={classes.textField}
            InputProps={{classes: {underline: classes.underline, input: classes.input}}}
            InputLabelProps={{ shrink: true }}
            validate={[util.email, util.required]}
            component={this.renderField}
          />
          {validation.email ? <FormHelperText className={classes.errorText}>O campo email é inválido!</FormHelperText> : ''}
        </div>

        <div>
          <Field
            type="password"
            name="password"
            placeholder="Senha"
            label='Senha'
            error={validation.password || validation.passwordFormat}
            className={classes.textField}
            InputProps={{classes: {underline: classes.underline, input: classes.input}}}
            InputLabelProps={{ shrink: true }}
            validate={[util.required, util.minLength6]}
            component={this.renderField}
          />
          {(validation.password && !validation.passwordFormat) ? <FormHelperText className={classes.errorText}>Senha inválida, mínimo 6 caracteres</FormHelperText> : ''}
        </div>

        <Grid container direction={'column'}>
          <Grid item>
            <Fab
              type={'submit'}
              variant={'extended'}
              color={'primary'}
              className={classes.buttonSubmit}
              classes={{ root: classes.raisedPrimaryOverrideButton }}
              disabled={isLoading}
            >
              {isLoading ? 'Aguarde...' : 'Entrar'}
            </Fab>
          </Grid>
        </Grid>
      </form>
    );
  }

  renderField ({ input, meta: { touched, error }, ...custom }) {
    const { classes } = this.props;
    return (
      <Fragment>
      <TextField {...input} {...custom} />
      { touched && ( (error && <FormHelperText className={classes.errorText}> {error}</FormHelperText>) ) }
      </Fragment>
    );
  }

  redirectRoute () {
    this.props.history.push('/register');
  }
};

Form.propTypes = {
  onSubmit  : PropTypes.func.isRequired,
  classes   : PropTypes.object.isRequired,
  isLoading : PropTypes.bool.isRequired,
  validation   : PropTypes.shape({
    email         : PropTypes.bool.isRequired,
    emailFormat   : PropTypes.bool.isRequired,
    password      : PropTypes.bool.isRequired,
    passwordFormat: PropTypes.bool.isRequired
  }).isRequired
};

export default reduxForm({form: 'SignIn'})(withRouter(Form));
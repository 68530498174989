import { createTheme } from '@material-ui/core/styles';

const fontFamily = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

export default createTheme({
  palette: {
    primary: {
      main: '#286efa', //500
      light: '#4F88FA', //300
      dark: '#215BCD', //700
    },
    secondary: {
      main: '#673ab7', //500
      light: '#825DC4', //300
      dark: '#553096', //700
    }
  },
  typography: {
    useNextVariants: true,

    fontFamily,

    display4: {
      fontFamily,
    },

    display3: {
      fontFamily,
    },

    display2: {
      fontFamily,
    },

    display1: {
      fontFamily,
    },

    headline: {
      fontFamily,
    },

    title: {
      fontFamily,
    },

    subheading: {
      fontFamily,
    },

    body2: {
      fontFamily,
    },

    body1: {
      fontFamily,
    },

    caption: {
      fontFamily,
    },

    button: {
      fontFamily,
    },

    h1: {
      fontFamily,
    },

    h2: {
      fontFamily,
    },

    h3: {
      fontFamily,
    },

    h4: {
      fontFamily,
    },

    h5: {
      fontFamily,
    },

    h6: {
      fontFamily,
    },

    subtitle1: {
      fontFamily,
    },

    subtitle2: {
      fontFamily,
    },

    body1Next: {
      fontFamily,
    },

    body2Next: {
      fontFamily,
    },

    buttonNext: {
      fontFamily,
    },

    captionNext: {
      fontFamily,
    },

    overline: {
      fontFamily,
    },
  }
});
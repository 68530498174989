import state from '@App/state';
import initialState from './initialState';

export const SET_DATA_TYPE = (state, action) => {
  const {dataType} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      dataType
    }
  });
};

export const SET_SWIPEABLE = (state, action) => {
  const {index} = action.payload;
  return ({
    ...state,
    swipe: {
      ...state.swipe,
      index
    }
  });
};

export const SET_TAB_FIELD = (state, action) => {
  const {nextTabIndex, dataType} = action.payload;
  return ({
    ...state,
    swipe: {
      ...state.swipe,
      index: nextTabIndex
    },
    field: {
      ...state.field,
      dataType
    }
  });
};

export const RESET = (state, action) => {
  return ({
    ...state,
    context: '',
    formOrigin: null,
    tabDesignerDisabled: true,
    viewOnly: false,
    field: {...initialState.field},
    fetching: {...initialState.fetching},
    optionsIndexed : {
      0: { id: 0, value: '', points: null}
    },
    examsIndexed: {},
    swipe: {...initialState.swipe},
    api: {...initialState.api}
  });
};

export const SET_ENABLE_FIELD = (state, action) => {
  const {enablePeriodInterval, enableObservation} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      enablePeriodInterval,
      enableObservation
    }
  });
};

export const SET_ENABLE_NEW_LINE = (state, action) => {
  const {checked} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      enableNewLine: checked
    }
  });
};

export const SET_ENABLE_NEW_PAGE = (state, action) => {
  const {checked} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      enableNewPage: checked
    }
  });
};

export const SET_ENABLE_ORIENTATION = (state, action) => {
  const {checked} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      enableInline: checked
    }
  });
};

export const SET_ENABLE_SELECT_LIST_DESIGN = (state, action) => {
  const {checked} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      enableSelectListDesign: checked
    }
  });
};

export const SET_ENABLE_PERIOD_INTERVAL = (state, action) => {
  const {checked} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      enablePeriodInterval: checked
    }
  });
};

export const SET_ENABLE_PRE_PROGRAMMED = (state, action) => {
  const {checked} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      enablePreProgrammed: checked
    }
  });
};

export const SET_ENABLE_OBSERVATION = (state, action) => {
  const {checked} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      enableObservation: checked
    }
  });
};

export const SET_RANGE_MIN = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      rangeMin: value
    }
  });
};

export const SET_INFO_RANGE_MIN = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      textInfoMin: value
    }
  });
};

export const SET_INFO_RANGE_MAX = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      textInfoMax: value
    }
  });
}

export const SET_SCORE_RANGE_MIN = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      scoreMin: value
    }
  });
}

export const SET_SCORE_RANGE_MAX = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      scoreMax: value
    }
  });
}

export const SET_RANGE_MAX = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      rangeMax: value
    }
  });
};

export const SET_SIZE = (state, action) => {
  const {size} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      size
    }
  });
};

export const SET_FIELD_TITLE = (state, action) => {
  const {title} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      title
    }
  });
};

export const SET_FIELD_DISPLAY_TITLE = (state, action) => {
  const {displayName} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      displayName
    }
  });
};

export const SET_FIELD_MEASUREMENT_UNIT = (state, action) => {
  const {code} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      measurementUnit: code
    }
  });
};

export const SET_FIELD_CODE_BLOCK = (state, action) => {
  const {code} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      code: code
    }
  });
};

export const SET_FIELD_GRAPHIC_CONFIG = (state, action) => {

  return ({
    ...state,
    field: {
      ...state.field,
      graphicConfig: action.payload,
    }
  });
};

export const SET_FIELD_REFERENCE_DATA = (state, action) => {
  const {refData} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      refData: [...refData]
    }
  });
};

export const SET_FIELD_CODE_CONFIG = (state, action) => {
  const {codeConfig} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      codeConfig: codeConfig
    }
  });
};

export const SET_FIELD_POINTS = (state, action) => {
  const {points} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      points: points
    }
  });
};

export const SET_FIELD_CHECKPOINTS = (state, action) => {
  const {checkPoints} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      checkPoints: checkPoints
    }
  });
};

export const SET_FIELD_EMPTY_POINTS_WARNING = (state, action) => {
  const {emptyPointsWarning} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      emptyPointsWarning
    }
  });
};

export const SET_FIELD_OPTIONS = (state, action) => {
  const {index, optionsIndexed} = action.payload;
  return ({
    ...state,
    optionsIndexed,
    field: {
      ...state.field,
      optionsIds: [...state.field.optionsIds, index]
    }
  });
};
export const SET_OPTIONS = (state, action) => {
  const {optionsIndexed} = action.payload;
  return ({
    ...state,
    optionsIndexed
  });
};

export const SET_FIELD_OPTIONS_UPDATE = (state, action) => {
  const {field, optionsIndexed} = action.payload;
  return ({
    ...state,
    optionsIndexed: {
      ...optionsIndexed
    },
    field: { ...field }
  });
};

export const SET_FIELD_OPTION_BY_ID = (state, action) => {
  const {optionId, value, points} = action.payload;
  return ({
    ...state,
    optionsIndexed: {
      ...state.optionsIndexed,
      [optionId]: {
        ...state.optionsIndexed[optionId],
        value,
        points
      }
    }
  });
};

export const SET_SEARCH_EXAM_RESET = (state, action) => {
  return ({
    api: {
      ...state.api,
      examSearch: []
    }
  });
};

export const SEARCH_EXAM_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      examSearch: true
    }
  });
};

export const SEARCH_EXAM_FETCH_SUCCESS = (state, action) => {
  const {exams} = action.payload;
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      examSearch: false
    },
    api: {
      ...state.api,
      examSearch: exams
    }
  });
};

export const SEARCH_EXAM_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      examSearch: false
    }
  });
};

export const SET_DEFAULT_EXAMS = (state, action) => {
  return ({
    ...state,
    defaultExams: action.payload
  });
};

export const SET_CONTEXT = (state, action) => {
  const {context, formOrigin} = action.payload;
  return ({
    ...state,
    tabDesignerDisabled: true,
    context,
    formOrigin
  });
};

export const SAVE_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      saveField: true
    }
  });
};

export const SAVE_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      saveField: false
    }
  });
};

export const SAVE_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      saveField: false
    }
  });
};

export const SET_FIELD_BY_ID = (state, action) => {
  const {field, optionsIndexed, viewOnly, getFieldById} = action.payload;
  return ({
    ...state,
    tabDesignerDisabled: false,
    viewOnly,
    fetching: {
      ...state.fetching,
      getFieldById
    },
    field: {
      ...state.field,
      ...field,
      originalDataType: field.dataType
    },
    optionsIndexed: {
      ...state.optionsIndexed,
      ...optionsIndexed
    }
  });
};

export const SET_FIELD_DESCRIPTION = (state, action) => {
  const {description} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      description
    }
  });
};

export const UPDATE_FIELD_MODEL_BY_ID_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      updateFieldModelById: true
    }
  });
};

export const UPDATE_FIELD_MODEL_BY_ID_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      updateFieldModelById: false
    }
  });
};

export const UPDATE_FIELD_MODEL_BY_ID_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      updateFieldModelById: false
    }
  });
};

export const SET_GET_FIELD_BY_ID_LOADING = (state, action) => {
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      getFieldById: true
    }
  });
};

export const SET_ENABLE_CONFIG_SCORE = (state, action) => {
  const {checked} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      configScore: checked
    }
  });
}

export const SET_SELECT_QUALIFIER = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      qualifier: value
    }
  });
}

export const SET_SELECT_ANALYZE_BETTER = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      analyzeBetter: value
    }
  });
}

export const SET_SELECT_TYPE_SCORE = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      typeScore: value
    }
  });
}

export const SET_LIST_QUALIFIERS_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    qualifiers: action.payload,
  });
}

export const SET_SELECT_QUALIFIER_DEFICIENCY = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      qualifierDeficiency: value
    }
  });
}

export const SET_SELECT_QUALIFIER_ID = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      qualifierId: value
    }
  });
}

export const SET_QUALIFIER_DEFICIENCY_ID = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      qualifierDeficiencyId: value
    }
  });
}

export const SET_REFERENCE_FIELD = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      referenceField: value
    }
  });
}


export const SET_DEFICIENCY_CALC  = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      deficiencyCalc: value
    }
  });
} 

export const SET_TYPE_REFERENCE = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      typeReference: value
    }
  });
}

export const SET_FIELD_MAP_BODY = (state, action) => {
  const {value} = action.payload;
  return ({
    ...state,
    field: {
      ...state.field,
      typePainMapBody: value
    }
  });
}
import React from "react";
import { Modal } from "antd";
import queryString from "query-string";
import uuidv4 from "uuid/v4";

import constants from "./constants";
import selector from "./selector";
import types from "./constants";

import * as actionConfig from "@Actions/config";
import * as actionSnack from "@Common/snackBarRedux/action";
import * as actionModal from "@Common/modalRedux/action";
import typeForms from "@Constants/forms";
import typeFields from "@Constants/typeFields";
import * as router from "@Constants/router";
import * as util from "@Util";
import formOrigin from "@Constants/formOrigin";
import { selector as selectorLogin } from "@State/ducks/login/";
import * as actionModalFields from "@Components/ModalFields/action";
import systemConfigs from "@Constants/systemConfigs";
import dataTypes from "@Constants/typeFields";
import * as actionsScoresFieldReferenceDataDialog from "@Common/ScoresFieldReferenceDataDialog/action";
import { FormattedMessage } from "react-intl";
import messages from "@Util/messages";

const ModalConfirm = Modal.confirm;

//#region PRIVATE
const execptionError = (params) => {
  throw params;
};

const isRadioGroupType = (type) => type === typeFields.RADIO_GROUP_TYPE;
const isCheckGroupType = (type) => type === typeFields.CHECKBOX_GROUP_TYPE;
const isInfoType = (type) => type === typeFields.INFO_LABEL_TYPE;
// const isSliderType      = type  => (type === typeFields.SLIDER_TYPE);

const getListFieldsFetchRequest = () => ({
  type: types.FORMS_CREATE_GET_LIST_FIELDS_FETCH_REQUEST,
});
const getListFieldsFetchSuccess = (data) => ({
  type: types.FORMS_CREATE_GET_LIST_FIELDS_FETCH_SUCCESS,
  payload: data,
});
const getListFieldsFetchError = () => ({
  type: types.FORMS_CREATE_GET_LIST_FIELDS_FETCH_ERROR,
});

const getFieldModelByIdFetchRequest = () => ({
  type: types.FORMS_CREATE_GET_MODEL_BY_ID_FETCH_REQUEST,
});
const getFieldModelByIdFetchSuccess = (data) => ({
  type: types.FORMS_CREATE_GET_MODEL_BY_ID_FETCH_SUCCESS,
  payload: data,
});
const getFieldModelByIdFetchError = () => ({
  type: types.FORMS_CREATE_GET_MODEL_BY_ID_FETCH_ERROR,
});

const saveVersionFieldFetchRequest = () => ({
  type: types.FORMS_CREATE_SAVE_VERSION_FIELD_FETCH_REQUEST,
});
const saveVersionFieldFetchSuccess = (data) => ({
  type: types.FORMS_CREATE_SAVE_VERSION_FIELD_FETCH_SUCCESS,
  payload: data,
});

const saveFormsFetchRequest = () => ({
  type: types.FORM_CREATE_SAVE_FETCH_REQUEST,
});
const saveFormsFetchSuccess = () => ({
  type: types.FORM_CREATE_SAVE_FETCH_SUCCESS,
});
const saveFormsFetchError = () => ({
  type: types.FORM_CREATE_SAVE_FETCH_ERROR,
});

const getDraftByIdFetchRequest = () => ({
  type: types.FORM_CREATE_GET_DRAFT_BY_ID_FETCH_REQUEST,
});
const getDraftByIdFetchSuccess = (data) => ({
  type: types.FORM_CREATE_GET_DRAFT_BY_ID_FETCH_SUCCESS,
  payload: data,
});
const getDraftByIdFetchError = () => ({
  type: types.FORM_CREATE_GET_DRAFT_BY_ID_FETCH_ERROR,
});

const saveDraftFetchRequest = () => ({
  type: types.FORM_CREATE_SAVE_DRAFT_FETCH_REQUEST,
});
const saveDraftFetchSuccess = () => ({
  type: types.FORM_CREATE_SAVE_DRAFT_FETCH_SUCCESS,
});
const saveDraftFetchError = () => ({
  type: types.FORM_CREATE_SAVE_DRAFT_FETCH_ERROR,
});

const setSearchFieldsTab = (data) => ({
  type: types.FORMS_CREATE_SET_SEARCH_FIELDS_TAB,
  payload: data,
});
const modalSaveField = (data) => ({
  type: types.FORMS_CREATE_MODAL_SAVE_FIELD,
  payload: data,
});
const setBuildDraftById = (data) => ({
  type: types.FORMS_CREATE_SET_BUILD_DRAFT_BY_ID,
  payload: data,
});
const setFieldModelVersion = (data) => ({
  type: types.FORMS_CREATE_SET_FIELD_MODEL_VERSION,
  payload: data,
});
//#endregion

//#region PUBLIC
export const setNewFieldsIds = (data) => ({
  type: types.FORMS_CREATE_SET_NEW_FIELDS_IDS,
  payload: data,
});
export const setNewDynamicFields = (data) => ({
  type: types.FORMS_CREATE_SET_NEW_DYNAMIC_FIELDS,
  payload: data,
});
export const attachPatientModel = (data) => ({
  type: types.FORMS_CREATE_CHANGE_ATTACH_PATIENT_MODEL,
  payload: data,
});

export const onChangeDataForm = (data) => ({
  type: types.FORMS_CREATE_CHANGE_INPUT_DATA_FORMS,
  payload: data,
});

const saveNewAvatarFetchRequest = (data) => ({
  type: types.FORMS_CREATE_SAVE_NEW_AVATAR_FETCH_REQUEST,
  payload: data,
});

const saveNewAvatarFetchSuccess = (data) => ({
  type: types.FORMS_CREATE_SAVE_NEW_AVATAR_FETCH_SUCCESS,
  payload: data,
});

const saveNewAvatarData = (data) => ({
  type: types.FORMS_CREATE_SAVE_NEW_AVATAR_DATA,
  payload: data,
});

const saveNewAvatarFetchError = (data) => ({
  type: types.FORMS_CREATE_SAVE_NEW_AVATAR_FETCH_ERROR,
  payload: data,
});

const createAuthorFetchRequest = data => ({
  type: types.FORMS_CREATE_CREATE_AUTHOR_FETCH_REQUEST,
  payload: data,
})

const createAuthorFetchSuccess = data => ({
  type: types.FORMS_CREATE_CREATE_AUTHOR_FETCH_SUCCESS,
  payload: data,
});

const createAuthorFetchError = data => ({
  type: types.FORMS_CREATE_CREATE_AUTHOR_FETCH_ERROR,
  payload: data,
});

const updateAuthorFetchRequest = data => ({
  type: types.FORMS_CREATE_UPDATE_AUTHOR_FETCH_REQUEST,
  payload: data,
});

const updateAuthorFetchSuccess = data => ({
  type: types.FORMS_CREATE_UPDATE_AUTHOR_FETCH_SUCCESS,
  payload: data,
});

const updateAuthorFetchError = data => ({
  type: types.FORMS_CREATE_UPDATE_AUTHOR_FETCH_ERROR,
  payload: data,
});

const getListAuthorFetchRequest = data => ({
  type: types.FORMS_CREATE_GET_LIST_AUTHOR_FETCH_REQUEST,
  payload: data,
});

const getListAuthorFetchSuccess = data => ({
  type: types.FORMS_CREATE_GET_LIST_AUTHOR_FETCH_SUCCESS,
  payload: data,
});

const getListAuthorFetchError = data => ({
  type: types.FORMS_CREATE_GET_LIST_AUTHOR_FETCH_ERROR,
  payload: data,
})

export const managerContextRedirect = (redirectContext) => (dispatch) => {
  switch (redirectContext) {
    case typeForms.CONTEXT_REDIRECT_PERFIL_CONFIG_MEDICALRECORD_FORMS: {
      dispatch(
        actionConfig.routeRedirect(
          `/${router.ROUTE_BASIC}/${router.PERFIL}/${router.CONFIGURATION}/${router.MEDICAL_RECORD}/${router.FORMS}`
        )
      );
      break;
    }
    case typeForms.CONTEXT_REDIRECT_CONFIG_MEDICALRECORD_FORMS: {
      dispatch(
        actionConfig.routeRedirect(`/${router.MANAGER}/${router.FORM_MODEL}`)
      );
      break;
    }
    default:
      //history.goBack(); @heresalex bring it back?
      break;
  }
};

export const setUpdateFieldById = (field) => (dispatch, getState, api) => {
  const params = {
    idField: field.id,
    field: {
      enableNewLine: field.enableNewLine,
      enableNewPage: field.enableNewPage,
      enableInline: field.enableInline,
      enableSelectListDesign: field.enableSelectListDesign,
      size: field.size,
      title: field.title,
      fieldName: field.title,
      historyName: field.title,
      historyMeasurementUnit: field.measurementUnit,
      dataType: field.dataType,
      ...field,
    },
  };

  dispatch({
    type: types.FORMS_CREATE_SET_UPDATE_FIELD_BY_ID,
    payload: params,
  });
};

export const searchFieldsTab = (search) => (dispatch, getState, api) => {
  const state = getState();
  const listFields = selector.getListFields(state);
  const originListFields = selector.getOriginalListFields(state);
  const word = util.removedAccent(search.toLowerCase());
  const newCustomIds = [];

  if (!word || !word.length) {
    const customIds = listFields.customIdsOriginal;
    dispatch(setSearchFieldsTab({ customIds }));
  } else {
    originListFields.forEach((field, index) => {
      const nameField = util.removedAccent(field.name.toLowerCase());
      const displayNameField = util.removedAccent(
        field.displayName.toLowerCase()
      );
      if (
        nameField.indexOf(word) !== -1 ||
        displayNameField.indexOf(word) !== -1
      ) {
        newCustomIds.push(field.fieldModelVersionId);
      }
    });

    dispatch(setSearchFieldsTab({ customIds: newCustomIds }));
  }
};

export const setFieldDropEnd =
  ({ field, moduleId, attachedTo = null, externalId = null }) =>
    (dispatch, getState) => {
      const state = getState();
            const fields = selector.getDynamicFormsFields(state);
      const modules = selector.getDynamicFormsModules(state);

      const uuid = externalId || field.fieldId || uuidv4();

      for (const key in fields) {
        if (fields[key].fieldId === field.fieldId) {
          if (fields[key].preProgrammed) {
            dispatch(
              actionSnack.showSnackbar({
                message: `O campo selecionado já existe no formulário. Você não pode ter campos repetidos no formulário.`,
                variant: "error",
                autoHideDuration: 3500,
              })
            );
            return;
          }
          if (!fields[key].attachedTo) {
            dispatch(
              actionSnack.showSnackbar({
                message: `O campo selecionado já existe no formulário. Você não pode ter campos repetidos no formulário.`,
                variant: "error",
                autoHideDuration: 3500,
              })
            );
            return;
          }
          return;
        }
      }

      let fieldsIds = [];

      const newField = {
        ...field,
        id: uuid,
        attachedTo,
        fieldId: field.fieldId,
      };

      fields[uuid] = newField;
      fieldsIds = [...modules[moduleId].fieldsIds, uuid];

      dispatch({
        type: types.FORMS_CREATE_SET_FIELD_DROP_END,
        payload: {
          fields,
          fieldsIds,
          moduleId,
        },
      });
    };

export const onChangeHeader =
  ({ value, name }) =>
    (dispatch, getState, api) => {
      dispatch({
        type: types.FORMS_CREATE_CHANGE_INPUT_HEADER,
        payload: { value, name },
      });
    };

export const onChangeSection =
  ({ value, name, idSection }) =>
    (dispatch, getState, api) => {
      dispatch({
        type: types.FORMS_CREATE_CHANGE_INPUT_SECTIONS,
        payload: { value, name, idSection },
      });
    };

export const onChangeModule =
  ({ value, name, idModule }) =>
    (dispatch, getState, api) => {
      dispatch({
        type: types.FORMS_CREATE_CHANGE_INPUT_MODULES,
        payload: { value, name, idModule },
      });
    };

export const resetDynamicForms =
  (props = {}) =>
    (dispatch, getState, api) => {
      const { createSection } = props;
      const resetForms = { type: types.FORMS_CREATE_RESET_DYNAMIC_FORMS };
      dispatch(resetForms);

      if (createSection) dispatch(createNewSection());
    };

export const createNewSection = () => (dispatch, getState, api) => {
  const state = getState();
  const sections = selector.getDynamicFormsSections(state);
  const sectionsIds = selector.getDynamicFormsSectionsIds(state);
  const uuid = util.generateUUID();

  const newSection = {
    id: uuid,
    name: "",
    description: "",
    modulesIds: [],
  };

  sections[uuid] = newSection;
  sectionsIds.push(uuid);

  dispatch([
    {
      type: types.FORMS_CREATE_SET_NEW_SECTION,
      payload: { sections, sectionsIds },
    },
    createNewModule({ idSection: uuid }),
  ]);
};

export const createNewModule =
  ({ idSection }) =>
    (dispatch, getState, api) => {
      const state = getState();
      const sections = selector.getDynamicFormsSections(state);
      const modules = selector.getDynamicFormsModules(state);
      const uuid = util.generateUUID();
      let modulesIds = [];

      const newModule = {
        id: uuid,
        name: "",
        description: "",
        fieldsIds: [],
      };

      modules[uuid] = newModule;
      modulesIds = [...sections[idSection].modulesIds, uuid];

      dispatch({
        type: types.FORMS_CREATE_SET_NEW_MODULES,
        payload: { modules, modulesIds, idSection },
      });
    };

/**
 * Maps feeded fields and matched it's attachedTo id, returning a group of fields
 * @param {*} fields Object
 * @param {*} attachId String
 * @returns Object
 */
const getAttachedFields = (fields, attachId) => {
  let attachedGroup = [];
  Object.keys(fields).map((objectField) => {
    if (fields[objectField].attachedTo === attachId)
      attachedGroup.push(objectField);
  });
  return attachedGroup;
};

export const removeField =
  ({ idField, idModule }) =>
    (dispatch, getState) => {
      const state = getState();
      const modules = selector.getDynamicFormsModules(state);
      let fields = selector.getDynamicFormsFields(state);
      let fieldsIds = modules[idModule].fieldsIds;
      const field = fields[idField];

      if (field.attachedTo) {
        const attachedGroup = getAttachedFields(fields, field.attachedTo);

        ModalConfirm({
          centered: true,
          maskClosable: true,
          title: "Atenção",
          content: (
            <div>
              Você esta removendo do formulário um campo que possui vínculo com
              outros campos. Ao remover um campo com vínculo, todos os campos
              ligados a este serão removidos. Os campos vinculados, que serão
              removidos, são:
              {attachedGroup.map((field) => (
                <div>
                  <br /> {fields[field].displayName}{" "}
                </div>
              ))}
            </div>
          ),
          cancelText: "Cancelar",
          okText: "Remover Campos",
          onOk: () => {
            attachedGroup.map((fieldId) => {
              let fieldIndex = fieldsIds.findIndex((item) => item === fieldId);

              fieldsIds.splice(fieldIndex, 1);
              delete fields[fieldId];
            });

            dispatch({
              type: types.FORMS_CREATE_REMOVED_FIELD,
              payload: { fields, fieldsIds, idModule },
            });
          },
          onCancel: () => {
            return;
          },
        });
      } else {
        let fieldIndex = fieldsIds.findIndex((item) => item === idField);
        fieldsIds.splice(fieldIndex, 1);

        delete fields[idField];
      }
      dispatch({
        type: types.FORMS_CREATE_REMOVED_FIELD,
        payload: { fields, fieldsIds, idModule },
      });
    };

export const removeModule =
  ({ idModule, idSection }) =>
    (dispatch, getState, api) => {
      const state = getState();
      let sections = selector.getDynamicFormsSections(state);
      let modules = selector.getDynamicFormsModules(state);
      let fields = selector.getDynamicFormsFields(state);
      let modulesIds = sections[idSection].modulesIds;
      let moduleIndex = modulesIds.findIndex((item) => item === idModule);

      modules[idModule].fieldsIds.forEach((idField) => delete fields[idField]);
      modulesIds.splice(moduleIndex, 1);
      delete modules[idModule];

      dispatch({
        type: types.FORMS_CREATE_REMOVED_MODULE,
        payload: { fields, modules, modulesIds, idSection },
      });
    };

export const removeSection =
  ({ idSection }) =>
    (dispatch, getState, api) => {
      const state = getState();
      let sections = selector.getDynamicFormsSections(state);
      let modules = selector.getDynamicFormsModules(state);
      let fields = selector.getDynamicFormsFields(state);
      let sectionsIds = selector.getDynamicFormsSectionsIds(state);
      let sectionIndex = sectionsIds.findIndex((item) => item === idSection);

      sections[idSection].modulesIds.forEach((idModule) => {
        modules[idModule].fieldsIds.forEach((idField) => delete fields[idField]);
        delete modules[idModule];
      });

      sectionsIds.splice(sectionIndex, 1);
      delete sections[idSection];

      dispatch({
        type: types.FORMS_CREATE_REMOVED_SECTION,
        payload: { sections, sectionsIds, modules, fields },
      });
    };

export const onOpenModal =
  ({ idField }) =>
    (dispatch, getState, api) => {
      const state = getState();
      const fields = selector.getDynamicFormsFields(state);
      let field = fields[idField];
      let _formOrigin = selector.getFormOrigin(state);

      const newField = {
        uuid: idField,
        size: field.size || 12,
        ...field,
      };

      dispatch([
        actionModal.onOpen({ modal: constants.MODAL_FORMS_UPDATE_FIELD }),
        actionModalFields.editFieldById({
          context: constants.CONTEXT_FORMENGINE_DRAFT_UPDATE,
          modal: constants.MODAL_FORMS_UPDATE_FIELD,
          formOrigin: _formOrigin,
          viewOnly: false,
          field: newField,
        }),
      ]);
    };

const fieldsMap = (fields, currentField) => {
  let hasPoints = false;
  let fieldPoints = [];
  for (var key in fields) {
    //Maps the fields with points that are available
    const field = fields[key].fieldData;
    switch (field.dataType) {
      case dataTypes.SCORES_TYPE:
        if (field.fieldId !== currentField.fieldId) {
          if (field.scoresFieldVersionIds.length) hasPoints = true;
        } else hasPoints = false;
        break;
      case dataTypes.CHECKBOX_TYPE:
        const checkBoxPoints = field.points;
        if (
          checkBoxPoints !== null &&
          checkBoxPoints !== undefined &&
          field.checkPoints !== false
        )
          hasPoints = true;
        break;
      case dataTypes.CHECKBOX_GROUP_TYPE:
        const checkBoxGroupPoints = field.checkboxGroup.options[0].points;
        if (checkBoxGroupPoints !== null && checkBoxGroupPoints !== undefined)
          hasPoints = true;
        break;
      case dataTypes.RADIO_GROUP_TYPE:
        const radioGroupPoints = field.radioGroup.options[0].points;
        if (radioGroupPoints !== null && radioGroupPoints !== undefined)
          hasPoints = true;
        break;
      case dataTypes.INTERGER_TYPE:
      case dataTypes.FLOAT_TYPE:
      case dataTypes.DECIMAL_TYPE:
        hasPoints = true;
        break;
      case dataTypes.SLIDER_TYPE:
        hasPoints = true;
        break;
    }
    if (hasPoints) {
      fieldPoints = [...fieldPoints, field]; //New list of fields that holds points
      hasPoints = false;
    }
  }
  return fieldPoints;
};

/** Simple helper to return a built and mapped object from each module
 * @function fieldsMap -> Returns only fields that holds points
 * @param {array} allModules
 * @returns {[{checkboxGroup: Object, dataType: Integer, fieldModelVersionId: Integer,
 *              id: String, isNew: Boolean, module: String, name: String,
 *              order: Integer, points: Integer, radioGroup: Object,
 *              styleConfig: Object
 *          }]}
 */
const fieldModuleBuilderHelper = (allModules, field) => {
  let activePointsFields = [];
  allModules.modules.map((module) => {
    let fieldPoints = fieldsMap(module.fields, field);
    activePointsFields = [...activePointsFields, ...fieldPoints];
  });
  return activePointsFields;
};

/**
 * Dispatches modal actions to open Design Modal
 * @param {String} idField field being consulted
 * @returns dispatch actions
 */
export const onOpenDesignFormField = (idField) => (dispatch, getState, api) => {
  try {
    const state = getState();
    const fields = selector.getDynamicFormsFields(state);
    let field = fields[idField];
    let _formOrigin = selector.getFormOrigin(state);

    const newField = {
      uuid: idField,
      size: field.size || 12,
      ...field,
    };
    dispatch([
      actionModal.onOpen({ modal: constants.MODAL_FORMS_FIELD_DESIGN }),
      actionModalFields.editFieldById({
        context: constants.MODAL_FORMS_FIELD_DESIGN,
        modal: constants.MODAL_FORMS_FIELD_DESIGN,
        formOrigin: _formOrigin,
        viewOnly: false,
        field: newField,
      }),
    ]);
  } catch (error) {
    const { message } = error;
    if (error.isNotValidForm)
      // validates that it is a controlled error output
      util.managerExceptionURIMessage({ error, customMsg: message });
  }
};

export const onOpenCalcEdit = (idField) => (dispatch, getState, api) => {
  try {
    const state = getState();
    const fields = selector.getDynamicFormsFields(state);
    const modules = selector.getDynamicFormsModules(state);
    const sections = selector.getDynamicFormsSections(state);
    const form = selector.getDynamicFormsForm(state);
    const updatedFormData = interactionForm(
      form,
      fields,
      modules,
      sections,
      true
    );
    let field = fields[idField];
    let _formOrigin = selector.getFormOrigin(state);
    let interactionSections = [];
    updatedFormData.map((section) => {
      // maps each section returning fieldModuleBuilderHelper func for scoresFieldData
      interactionSections = [
        ...interactionSections,
        ...fieldModuleBuilderHelper(section, field),
      ];
    });
    const scoresFieldsData = [...interactionSections];

    const newField = {
      uuid: idField,
      size: field.size || 12,
      ...field,
    };
    dispatch([
      actionModal.onOpen({
        data: scoresFieldsData,
        modal: constants.MODAL_FORMS_SCORES_MATH,
      }),
      actionModalFields.editFieldById({
        context: constants.CONTEXT_FORMENGINE_DRAFT_UPDATE,
        modal: constants.MODAL_FORMS_SCORES_MATH,
        formOrigin: _formOrigin,
        viewOnly: false,
        field: newField,
        totalFields: scoresFieldsData,
      }),
    ]);
  } catch (error) {
    const { message } = error;
    if (error.isNotValidForm)
      // validates that it is a controlled error output
      util.managerExceptionURIMessage({ error, customMsg: message });
  }
};

export const onCloseModal = ({ idModal }) => {
  return {
    type: types.FORMS_CREATE_MODAL_CLOSE_VISIBILE,
    payload: { idModal },
  };
};

export const onChangeField =
  ({ name, value, idModal }) =>
    (dispatch, getState, api) => {
      const state = getState();
      const modals = selector.getModal(state);

      let modal = modals[idModal];
      let field = modal.field[name];

      const newField = {
        ...field,
        name: value,
      };

      dispatch({
        type: types.FORMS_CREATE_MODAL_CHANGE_INPUT,
        payload: { field: newField, idField: name, idModal },
      });
    };

export const onChangeSize =
  ({ size, idField, idModal }) =>
    (dispatch, getState, api) => {
      const state = getState();
      const fields = selector.getDynamicFormsFields(state);
      let field = fields[idField];

      field = { ...field, size };

      dispatch({
        type: types.FORMS_CREATE_MODAL_CHANGE_INPUT_SIZE,
        payload: { field, idModal, idField },
      });
    };

export const saveVersionField =
  ({ idField, idModal }) =>
    (dispatch, getState, api) => {
      const state = getState();
      const fields = selector.getDynamicFormsFields(state);
      let modal = selector.getModal(state);
      let params = {};

      modal = modal[idModal];
      let _field = modal.field[idField];

      let field = { ...fields[_field.id], ..._field };

      if (isRadioGroupType(field.dataType))
        params.radioGroupModelVersionId =
          field.radioGroup.radioGroupModelVersionId;

      if (isCheckGroupType(field.dataType))
        params.checkboxGroupModelVersionId =
          field.checkboxGroup.checkboxGroupModelVersionId;

      if (isInfoType(field.dataType)) params.infoId = field.infoId;

      dispatch(saveVersionFieldFetchRequest());

      params = {
        ...params,
        fieldModelId: field.fieldModelId,
        dataType: field.dataType,
        size: field.size,
      };

      dispatch([
        saveVersionFieldFetchSuccess(),
        modalSaveField({ field, idField }),
        onCloseModal({ idModal }),
        asyncSaveFieldModelVersion({ params, field: fields[_field.id] }),
      ]);
    };

export const updateDynamicFormsFieldsById =
  (field) => (dispatch, getState, api) => {
    const idVersionField = field.fieldModelVersionId;
    const idField = field.id;

    const styleConfig = {
      size: field.size,
      enableNewLine: field.enableNewLine,
      enableNewPage: field.enableNewPage,
      enableInline: field.enableInline,
      enableSelectListDesign: field.enableSelectListDesign,
      enableObservation: field.enableObservation,
      enablePeriodInterval: field.enablePeriodInterval,
    };

    dispatch([
      getFieldModelById(idVersionField, idField, styleConfig),
      getListFields(),
    ]);
  };

export const buildDraftById = (props) => (dispatch, getState, api) => {
  const {
    draft,
    fieldModelVersionsStructures,
    fieldModelsNames,
    infoLabelsNames,
    // moduleModelVersionsStructures,
    // moduleModelsNames,
    // sectionModelVersionsStructures,
    // sectionModelsNames,
  } = props;

  const fields = {};
  const mod = {};
  const sections = {};

  const fieldModelVersionsStructuresIndexed = {};
  const fieldModelsNamesIndexed = {};
  const infoLabelsNamesIndexed = {};
  // const moduleModelVersionsStructuresIndexed  = {};
  // const moduleModelsNamesIndexed              = {};
  // const sectionModelVersionsStructuresIndexed = {};
  // const sectionModelsNamesIndexed             = {};

  fieldModelVersionsStructures.forEach(
    (item) =>
      (fieldModelVersionsStructuresIndexed[item.fieldModelVersionId] = item)
  );
  fieldModelsNames.forEach(
    (item) => (fieldModelsNamesIndexed[item.fieldModelId] = item)
  );
  infoLabelsNames.forEach(
    (item) => (infoLabelsNamesIndexed[item.infoId] = item)
  );

  const forms = {
    name: draft.name,
    description: draft.description,
    attachPatientModel: draft.attachPatientModel,
    sectionsIds: [],
    categoriesIds: draft.categoriesIds,
    authors: draft.authors,
    permissionType: draft.permissionType,
    permissionGroups: draft.permissionGroups,
    permissionAccounts: draft.permissionAccounts,
    typeForm: draft.typeForm,
    payment: draft.payment,
    permissionDuplicateModel: draft.permissionDuplicateModel,
    formDataDashboard: draft.formDataDashboard
  };

  for (let section of draft.sections) {
    sections[section.id] = { ...section };
    forms.sectionsIds.push(section.id);

    for (let _mod of section.modules) {
      mod[_mod.id] = { ..._mod };

      for (let field of _mod.fields) {
        let _fieldModelVersionsStructuresIndexed =
          fieldModelVersionsStructuresIndexed[field.fieldModelVersionId] || {};
        let _fieldModelsNamesIndexed =
          fieldModelsNamesIndexed[field.fieldModelId] || {};
        let _infoLabelsNamesIndexed =
          infoLabelsNamesIndexed[field.infoId] || {};

        fields[field.id] = {
          ...field,
          ..._fieldModelVersionsStructuresIndexed,
          ..._fieldModelsNamesIndexed,
          ..._infoLabelsNamesIndexed,
          searchLastVersion: false,
        };
      }
    }
  }
  
  dispatch(
    setBuildDraftById({
      draftId: draft._id,
      fields,
      modules: mod,
      sections,
      forms,
    })
  );
};

/**
 * Validates and mounts form sections based on the draft
 * @param {Object} form  getDynamicFormsForms
 * @param {Object} fields getDynamicFormsFields
 * @param {Object} modules getDynamicFormsModules
 * @param {Object} sections getDynamicFormsSections
 * @param {Boolean} calcValidation boolean to check if its Scores Field form data validation
 * @returns validated and updated section
 */
const interactionForm = (
  form,
  fields,
  modules,
  sections,
  calcValidation = false
) => {
  const newSection = [];
  let indexSection = 0;

  if (!form.name || !form.name.trim().length)
    execptionError({
      isNotValidForm: true,
      message: "Por favor informe o nome do Formulário!",
    });

  for (let idSection of form.sectionsIds) {
    const section = sections[idSection];
    const _modules = [];
    let indexModule = 0;

    if (!section.name || !section.name.trim().length)
      execptionError({
        isNotValidForm: true,
        message: "Por favor informe o nome da Seção!",
        idSection: section.id,
      });

    for (let idModule of section.modulesIds) {
      let mod = modules[idModule];
      let _fields = [];
      let indexField = 0;

      if (!mod.name || !mod.name.trim().length)
        execptionError({
          isNotValidForm: true,
          message: `Por favor informe o nome do Modulo na Seção: ${section.name.toUpperCase()}!`,
          idSection: section.id,
          idModule: mod.id,
        });

      for (let idField of mod.fieldsIds) {
        const field = fields[idField];
        _fields.push({
          isNew: true,
          order: indexField,
          fieldModelVersionId: field.fieldModelVersionId,
          fieldData: { ...field, module: mod.name },
          fieldId: field.fieldId,
          codeConfig: field.codeConfig,
          measurementUnit: field.measurementUnit,
          isModelField: field.isModelField,
          styleConfig: {
            size: field.size || 12,
            enableNewLine: field.enableNewLine || false,
            enableNewPage: field.enableNewPage || false,
            enableInline: field.enableInline || false,
            enableSelectListDesign: field.enableSelectListDesign || false,
            enableObservation: field.enableObservation || false,
            enablePeriodInterval: field.enablePeriodInterval || false,
          },
        });

        ++indexField;
      }

      if (!_fields.length && !calcValidation)
        execptionError({
          isNotValidForm: true,
          message: `Por favor informe pelo menos um Campo no Modulo: ${mod.name}!`,
          idSection: section.id,
          idModule: mod.id,
        });

      _modules.push({
        name: mod.name,
        description: mod.description,
        order: indexModule,
        fields: _fields,
        isNew: true,
      });

      ++indexModule;
    }

    newSection.push({
      name: section.name,
      description: section.description,
      isNew: true,
      order: indexSection,
      modules: _modules,
    });

    ++indexSection;
  }

  return newSection;
};

const buildDraftForm = (form, fields, modules, sections) => {
  const newSection = [];
  let indexSection = 0;

  for (let idSection of form.sectionsIds) {
    const section = sections[idSection];
    const _modules = [];
    let indexModule = 0;

    for (let idModule of section.modulesIds) {
      let mod = modules[idModule];
      let _fields = [];
      let indexField = 0;

      for (let idField of mod.fieldsIds) {
        const field = fields[idField];
        _fields.push({
          order: indexField,
          searchLastVersion: false,
          ...field,
        });

        ++indexField;
      }

      if (!_fields.length)
        execptionError({
          isNotValidForm: true,
          message: `Por favor informe pelo menos um Campo no Modulo: ${mod.name}!`,
          idSection: section.id,
          idModule: mod.id,
        });

      _modules.push({
        ...mod,
        order: indexModule,
        fields: _fields,
      });

      ++indexModule;
    }

    newSection.push({
      ...section,
      order: indexSection,
      modules: _modules,
    });

    ++indexSection;
  }

  return newSection;
};
//#endregion

//region APIs
export const getListFields =
  (formOrigin) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      let header = selectorLogin.getHeaderConfig(state);
      let _formOrigin = formOrigin || selector.getFormOrigin(state);
      header = { ...header, formOrigin: _formOrigin };

      try {
        dispatch(getListFieldsFetchRequest());

        let response = await apiForms.field.getModelFields({ header });
        let items = {};
        let customIds = [];

        response.data.forEach((item, index) => {
          items[item.fieldModelVersionId] = { index, ...item };
          customIds.push(item.fieldModelVersionId);
        });

        const listFields = { items, customIds };
        dispatch(getListFieldsFetchSuccess({ listFields, data: response.data }));
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        util.managerExceptionURIMessage({ error, customMsg });
        dispatch(getListFieldsFetchError());

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
        // window.location = systemConfigs.AUTH_DOMAIN;
      }
    };

export const getFieldModelById =
  (
    idVersionField,
    idField,
    externalId,
    scoresFieldVersionIds,
    styleConfig = {}
  ) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      let header = selectorLogin.getHeaderConfig(state);
      let _formOrigin = selector.getFormOrigin(state);
      header = {
        ...header,
        formOrigin: _formOrigin || formOrigin["MANAGER"],
      };

      try {
        dispatch(getFieldModelByIdFetchRequest());

        const response = await apiForms.field.getFieldModelByIdFromManager({
          header,
          idVersionField,
        });
        const result = response.data;

        const newResult = {
          ...result,
          fieldId: externalId || uuidv4(),
          scoresFieldVersionIds:
            scoresFieldVersionIds || result.scoresFieldVersionIds,
          ...styleConfig,
        };

        dispatch(
          getFieldModelByIdFetchSuccess({
            idField,
            result: newResult,
          })
        );
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        util.managerExceptionURIMessage({ error, customMsg });
        dispatch(getFieldModelByIdFetchError());

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
        // window.location = systemConfigs.AUTH_DOMAIN;
      }
    };

export const getFieldModelDataById =
  (idVersionField, idField, styleConfig = {}) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      let header = selectorLogin.getHeaderConfig(state);
      let _formOrigin = selector.getFormOrigin(state);
      header = {
        ...header,
        formOrigin: _formOrigin || formOrigin["MANAGER"],
      };
            try {
        const response = await apiForms.field.getFieldModelByIdFromManager({
          header,
          idVersionField,
        });

        const result = response.data;
        return result;
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        util.managerExceptionURIMessage({ error, customMsg });
        dispatch(getFieldModelByIdFetchError());

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
      }
    };

export const asyncSaveFieldModelVersion =
  ({ params, field }) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      let header = selectorLogin.getHeaderConfig(state);
      let items = selector.getListFieldsItems(state);
      let fields = selector.getDynamicFormsFields(state);
      let formOrigin = selector.getFormOrigin(state);
      header = { ...header, formOrigin };

      try {
        const response = await apiForms.field.saveFieldModelVersion({
          header,
          params,
        });
        const { fieldModelVersionId } = response.data;

        items[field.fieldModelId] = {
          ...items[field.fieldModelId],
          fieldModelVersionId,
        };

        fields[field.id] = { ...fields[field.id], fieldModelVersionId };

        dispatch(setFieldModelVersion({ items, fields }));
      } catch (error) {
        dispatch(modalSaveField({ field, idField: field.id }));
        dispatch(
          actionSnack.showSnackbar({
            message: `Não foi possível salvar a alteração do campo: ${field.fieldName}`,
            variant: "error",
            autoHideDuration: 3500,
          })
        );
      }
    };

export const checkNameFormModel =
  ({ search }, setScoresFieldReferenceDataDialog) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      const nameForm = selector.getDynamicFormsName(state);
      let header = selectorLogin.getHeaderConfig(state);
      let formOrigin = selector.getFormOrigin(state);
      header = { ...header, formOrigin };

      try {
        if (!nameForm || !nameForm.length) {
          dispatch(
            actionSnack.showSnackbar({
              message: "Por favor inserir o nome do Modelo do Formulário",
              variant: "error",
              autoHideDuration: 3500,
            })
          );
          return;
        }

        dispatch(saveFormsFetchRequest());

        let execute = () => { };

        if (header.workspaceId) {
          //nivel workspace
          execute = apiForms.form.checkNameFormModel;
        } else {
          //@heresalex fix this
          execute = apiForms.form.checkNameFormModel;
        }

        let response = await execute({
          header,
          nameForm: encodeURIComponent(nameForm),
        });
        let isAvaliable = response.data.isAvailable;

        if (!isAvaliable) {
          ModalConfirm({
            centered: true,
            maskClosable: true,
            title: "Atenção",
            content: `Já existe um modelo salvo com esse nome "${nameForm}". O que você deseja fazer? Substituir o modelo antigo por essa nova versão ou renomear o modelo atual?`,
            cancelText: "Renomear",
            okText: "Substituir",
            onOk: () => {
              dispatch(saveForms({ search }, setScoresFieldReferenceDataDialog));
            },
            onCancel: () => {
              dispatch(saveFormsFetchError());
            },
          });
        } else {
          dispatch(saveForms({ search }, setScoresFieldReferenceDataDialog));
        }
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        dispatch(saveFormsFetchError());
        util.managerExceptionURIMessage({ error, customMsg });

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
        // window.location = systemConfigs.AUTH_DOMAIN;
      }
    };

export const saveForms =
  ({ search }, setScoresFieldReferenceDataDialog) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      const fields = selector.getDynamicFormsFields(state);
      const modules = selector.getDynamicFormsModules(state);
      const sections = selector.getDynamicFormsSections(state);
      const form = selector.getDynamicFormsForm(state);
      const draftId = selector.getDynamicFormsDraftId(state);
      const hideAttachPatientModel = selector.getIsHideAttachPatientModel(state);
      let header = selectorLogin.getHeaderConfig(state);
      let formOrigin = selector.getFormOrigin(state);
      let parsed = queryString.parse(search);
      let params = {};
      header = { ...header, formOrigin };

      params = {
        isAccountForm: !header.workspaceId,
        form: {
          draftId,
          name: form.name,
          description: form.description,
          attachPatientModel: form.attachPatientModel || hideAttachPatientModel,
          sections: [],
          categoriesIds: form.categoriesIds,
          authors: form.authors,
          permissionType: form.permissionType,
          permissionGroups: form.permissionGroups,
          permissionAccounts: form.permissionAccounts,
          payment: form.payment || false,
          permissionDuplicateModel: form.permissionDuplicateModel || false,
          formDataDashboard: form.formDataDashboard || false,
          typeForm: form.typeForm,
        },
      };

      try {
        dispatch(saveFormsFetchRequest());
        params.form.sections = interactionForm(form, fields, modules, sections);

        await apiForms.form.saveForm({ header, params });

        dispatch([
          actionsScoresFieldReferenceDataDialog.setDialogTriggerState(false),
          saveFormsFetchSuccess(),
          resetDynamicForms(),
          managerContextRedirect(parsed.redirect),
        ]);
      } catch (error) {
        const { message, msg, isNotValidForm, userFeedback } = error;
        const scoresFieldReferenceDataMessage =
          "Failed Scores Field Reference Data";
        const customMsg = !!msg;

        dispatch(saveFormsFetchError());

        if (
          userFeedback.type &&
          userFeedback.type === scoresFieldReferenceDataMessage
        )
          return setScoresFieldReferenceDataDialog({
            active: true,
            scoresRefData: userFeedback.scoresCreateDataValidation,
          });
        //Setar aqui os fieldIds do diálogo?
        else if (isNotValidForm && message) {
          dispatch(
            actionSnack.showSnackbar({
              message,
              variant: "error",
              autoHideDuration: 3500,
            })
          );
          return;
        }

        util.managerExceptionURIMessage({ error, customMsg });
        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
        // window.location = systemConfigs.AUTH_DOMAIN;
      }
    };

export const saveDraft =
  () =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      const fields = selector.getDynamicFormsFields(state);
      const modules = selector.getDynamicFormsModules(state);
      const sections = selector.getDynamicFormsSections(state);
      const draftId = selector.getDynamicFormsDraftId(state);
      const form = selector.getDynamicFormsForm(state);
      let header = selectorLogin.getHeaderConfig(state);
      let formOrigin = selector.getFormOrigin(state);
      header = { ...header, formOrigin };

      try {
        let params = {};

        dispatch(saveDraftFetchRequest());

        params = {
          isAccountForm: !header.workspaceId,
          draft: {
            _id: draftId,
            name: form.name,
            description: form.description,
            categoriesIds: form.categoriesIds,
            authors: form.authors,
            permissionType: form.permissionType,
            permissionGroups: form.permissionGroups,
            permissionAccounts: form.permissionAccounts,
            payment: form.permissionType === 'public' ? false : form.payment,
            permissionDuplicateModel: form.permissionDuplicateModel || false,
            formDataDashboard: form.formDataDashboard || false,
            attachPatientModel: form.attachPatientModel,
            workspaceId: selectorLogin.getAuthBusinessWorkspaceId(state),
            typeForm: form.typeForm,
            sections: [],
          },
        };

        params.draft.sections = buildDraftForm(form, fields, modules, sections);

        await apiForms.form.putDraft({ header, params });

        dispatch([
          saveDraftFetchSuccess(),
          actionSnack.showSnackbar({
            message: "Todas as alterações foram salvas!",
          }),
        ]);
      } catch (error) {
        const { msg, isNotValidForm, message } = error;
        const customMsg = !!msg;

        dispatch(saveDraftFetchError());

        if (isNotValidForm && message) {
          dispatch(
            actionSnack.showSnackbar({
              message,
              variant: "error",
              autoHideDuration: 3500,
            })
          );
          return;
        }

        util.managerExceptionURIMessage({ error, customMsg });

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
        // window.location = systemConfigs.AUTH_DOMAIN;
      }
    };

export const getDraftById =
  ({ draftId, formOriginId }) =>
    async (dispatch, getState, { apiForms }) => {
            const state = getState();
      let header = selectorLogin.getHeaderConfig(state);
      header = { ...header, formOrigin: formOriginId };

      let hideAttachPatientModel = Number(formOriginId) === formOrigin["MANAGER"];

      try {
        dispatch(getDraftByIdFetchRequest());

        let resDraftById = await apiForms.form.getDraftById({ header, draftId });
        let draft = resDraftById.data.draft;

        if (!draft.sections.length) {
          dispatch([
            resetDynamicForms({ createSection: true }),
            getDraftByIdFetchSuccess({
              draftId,
              hideAttachPatientModel,
              formOrigin: formOriginId,
            }),
          ]);
          return;
        }

        dispatch([
          getDraftByIdFetchSuccess({
            draftId,
            hideAttachPatientModel,
            formOrigin: formOriginId,
          }),
          buildDraftById(resDraftById.data),
        ]);
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        util.managerExceptionURIMessage({ error, customMsg });
        dispatch(getDraftByIdFetchError());

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
        // window.location = systemConfigs.AUTH_DOMAIN;
      }
    };

export const saveNewAvatar =
  (file) => async (dispatch, getState, api) => {
    try {
      dispatch(saveNewAvatarFetchRequest());

      const state = getState();

      const header = selectorLogin.getHeaderConfig(state);

      const params = { file };

      const { src, fileReference } = (
        await api.apiFiles.upload.uploadAuthorAvatar(
          { header, params }
        )
      ).data;

      dispatch([
        saveNewAvatarFetchSuccess({ avatarSource: src }),
        saveNewAvatarData({ fileReference })
      ]);
    } catch (error) {
      const { msg } = error;
      const customMsg = !!msg;

      dispatch(saveNewAvatarFetchError());

      util.managerExceptionURIMessage({ error, customMsg });

      if (error && error.status === 401)
        dispatch(actionConfig.routeRedirect('/'));
    }
  };

export const createAuthor =
  (params) => async (dispatch, getState, api) => {
    try {
      dispatch(createAuthorFetchRequest());

      const state = getState();

      const header = selectorLogin.getHeaderConfig(state);

      await api.apiDocs.author.createAuthor(
        { header, params }
      )

      dispatch([
        getListAuthors(),
        createAuthorFetchSuccess()
      ]);
    } catch (error) {
      const { msg } = error;
      const customMsg = !!msg;

      dispatch(createAuthorFetchError());

      util.managerExceptionURIMessage({ error, customMsg });

      if (error && error.status === 401)
        dispatch(actionConfig.routeRedirect('/'));
    }
  };

export const updateAuthor =
  (params) => async (dispatch, getState, api) => {
    try {
      dispatch(updateAuthorFetchRequest());

      const state = getState();

      const header = selectorLogin.getHeaderConfig(state);

      await api.apiDocs.author.updateAuthor(
        { header, params }
      )

      dispatch([
        getListAuthors(),
        updateAuthorFetchSuccess()
      ]);
    } catch (error) {
      const { msg } = error;
      const customMsg = !!msg;

      dispatch(updateAuthorFetchError());

      util.managerExceptionURIMessage({ error, customMsg });

      if (error && error.status === 401)
        dispatch(actionConfig.routeRedirect('/'));
    }
  };

export const getListAuthors =
  () => async (dispatch, getState, api) => {
    try {
      dispatch(getListAuthorFetchRequest());

      const state = getState();

      const header = selectorLogin.getHeaderConfig(state);

      const authors = (await api.apiDocs.author.getAuthors(
        { header }
      )).data;

      dispatch(getListAuthorFetchSuccess(authors));
    } catch (error) {
      const { msg } = error;
      const customMsg = !!msg;

      dispatch(getListAuthorFetchError());

      util.managerExceptionURIMessage({ error, customMsg });

      if (error && error.status === 401)
        dispatch(actionConfig.routeRedirect('/'));
    }
  };

export const getUrlImageAvatar =
  (fileReference) => async (dispatch, getState, api) => {
    try {

      const state = getState();

      const header = selectorLogin.getHeaderConfig(state);

      const { url } = (await api.apiFiles.files.getUrlImageAvatar(
        { header, fileReference }
      )).data

      dispatch([
        saveNewAvatarFetchSuccess({ avatarSource: url }),
      ]);

    } catch (error) {
      const { msg } = error;
      const customMsg = !!msg;

      util.managerExceptionURIMessage({ error, customMsg });

      if (error && error.status === 401)
        dispatch(actionConfig.routeRedirect('/'));
    }
  };

  export const onOpenGraphicConfig = (idField) => (dispatch, getState, api) => {
    try {
      const state = getState();
      const fields = selector.getDynamicFormsFields(state);
      const modules = selector.getDynamicFormsModules(state);
      const sections = selector.getDynamicFormsSections(state);
      const form = selector.getDynamicFormsForm(state);
      const updatedFormData = interactionForm(
        form,
        fields,
        modules,
        sections,
        true
      );
      let field = fields[idField];
      let _formOrigin = selector.getFormOrigin(state);
      let interactionSections = [];
      updatedFormData.map((section) => {
        // maps each section returning fieldModuleBuilderHelper func for scoresFieldData
        interactionSections = [
          ...interactionSections,
          ...fieldModuleBuilderHelper(section, field),
        ];
      });
      const scoresFieldsData = [...interactionSections];
  
      const total = scoresFieldsData.map((item, index) => ({
        ...item,
        fieldNumber: item.fieldModelId
      }));
  
  
      const newField = {
        uuid: idField,
        size: field.size || 12,
        ...field,
      };
      dispatch([
        actionModal.onOpen({
          data: scoresFieldsData,
          modal: constants.MODAL_FORMS_GRAPHIC_CONFIG,
        }),
        actionModalFields.editFieldById({
          context: constants.CONTEXT_FORMENGINE_DRAFT_UPDATE,
          modal: constants.MODAL_FORMS_GRAPHIC_CONFIG,
          formOrigin: _formOrigin,
          viewOnly: false,
          field: newField,
          totalFields: total,
        }),
      ]);
    } catch (error) {
      const { message } = error;
      if (error.isNotValidForm)
        // validates that it is a controlled error output
        util.managerExceptionURIMessage({ error, customMsg: message });
    }
  };
//#endregion

import initialState           from './initialState';
import * as reducerGeneratorDocsManager  from './manager/config/reducerGenerator';
import * as reducerGeneratorDocs from './config/reducerGenerator';

const INITIAL_STATE = ({
        ...initialState
});

export default (state = INITIAL_STATE, action) => {
  if (reducerGeneratorDocsManager[action.type])
    return reducerGeneratorDocsManager[action.type](state, action);
  if(reducerGeneratorDocs[action.type])
    return reducerGeneratorDocs[action.type](state, action);
  else
    return state;
};
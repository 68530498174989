import state from "@App/state";

export const CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          listFormModels: true
        }
      }
    }
  });
};

export const CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_SUCCESS = (state, action) => {
  const { listFormModels } = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          listFormModels: false
        },
        api: {
          ...state.medicalRecord.forms.api,
          listFormModels
        }
      }
    }
  });
};

export const CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          listFormModels: false
        }
      }
    }
  });
};

export const CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          listDrafts: true
        }
      }
    }
  });
};

export const CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_SUCCESS = (state, action) => {
  const { listDrafts } = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          listDrafts: false
        },
        api: {
          ...state.medicalRecord.forms.api,
          listDrafts
        }
      }
    }
  });
};

export const CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          listDrafts: false
        }
      }
    }
  });
};

export const CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          createFormEmpty: true
        }
      }
    }
  });
};

export const CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          createFormEmpty: false
        }
      }
    }
  });
};

export const CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          createFormEmpty: false
        }
      }
    }
  });
};

export const CONFIGURATION_FIELDS_POST_DRAFT_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          createDraft: true
        }
      }
    }
  });
};

export const CONFIGURATION_FIELDS_POST_DRAFT_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          createDraft: false
        }
      }
    }
  });
};

export const CONFIGURATION_FIELDS_POST_DRAFT_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          createDraft: false
        }
      }
    }
  });
};

export const CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          createDraft: true,
        }
      }
    }
  });
};

export const CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          createDraft: false
        }
      }
    }
  });
};

export const CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          createDraft: false
        }
      }
    }
  });
};

export const CONFIGURATION_SET_PROPERTY_CATEGORY_FORM = (state, action) => {
  const { categoryProperty } = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        api: {
          ...state.medicalRecord.forms.api,
          categoryProperty: {
            ...state.medicalRecord.forms.api.categoryProperty,
            ...categoryProperty,
          }
        }
      }
    }
  });
}

export const FORM_SAVE_CATEGORY_FETCH_SUCCESS = (state, action) => {
  const { status } = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          saveCategory: status,
        }
      }
    }
  });
}

export const FORM_UPDATE_CATEGORY_FETCH_SUCCESS = (state, action) => {
  const { status } = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          updateCategory: status,
        }
      }
    }
  });

}

export const FORM_GET_CATEGORIES_MODELS_FETCH_REQUEST_STATUS = (state, action) => {
  const { status } = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        fetching: {
          ...state.medicalRecord.forms.fetching,
          getCategories: status,
        }
      }
    }
  })
}

export const FORM_GET_CATEGORIES_ALL_FETCH_SUCCESS =
  (state, action) => {
    const { categoriesAllForms } = action.payload;
    return {
      ...state,
      medicalRecord: {
        ...state.medicalRecord,
        forms: {
          ...state.medicalRecord.forms,
          fetching: {
            ...state.medicalRecord.forms.fetching,
            getCategories: false,
          },
          api: {
            ...state.medicalRecord.forms.api,
            categoriesAllForms
          }
        }
      }
    }
  };

export const SET_CATEGORY_SELECTED = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        api: {
          ...state.medicalRecord.forms.api,
          categorySelected: action.payload
        }
      }
    }
  });
}

export const SET_FORMS_FILTER = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        api: {
          ...state.medicalRecord.forms.api,
          formsListFilter: action.payload
        }
      }
    }
  })
}

export const FORMS_GET_CATEGORIES_FORM_FETCH_SUCCESS = (state, action) => {
  const { categoriesForms } = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        api: {
          ...state.medicalRecord.forms.api,
          categoriesForms
        }
      }
    }
  })
}

export const SET_SIDEBAR_BUTTON_SELECT_FORM = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      forms: {
        ...state.medicalRecord.forms,
        sidebar: {
          isOpen: state.medicalRecord.forms.sidebar.isOpen,
          ...action.payload
        }
      }
    }
  });
};

export const SET_AUTHORS_FORMS_MANAGER = (state, action) => {
  return ({
   ...state,
   medicalRecord: {
     ...state.medicalRecord,
     forms: {
       ...state.medicalRecord.forms,
       api: {
         ...state.medicalRecord.forms.api,
         authors: action.payload,
       }
     }
   }
 });
};

export const SET_AUTHORS_SELECTED_FORMS_MANAGER = (state, action) => {
  return ({
   ...state,
   medicalRecord: {
     ...state.medicalRecord,
     forms: {
       ...state.medicalRecord.forms,
       api: {
         ...state.medicalRecord.forms.api,
         authorsSelected: action.payload,
       }
     }
   }
 });
};

import types from './constant';

const INITIAL_STATE = { };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.MODAL_OPENED: {
      const {modal, ...rest} = action.payload;
      return ({
        ...state,
        [modal]: {
          ...state[modal],
          done: true,
          ...rest
        }
      });
    }
    case types.MODAL_CLOSED: {
      const {modal, ...rest} = action.payload;
      return ({
        ...state,
        [modal]: {
          ...state[modal],
          done: false,
          ...rest
        }
      });
    }
    case types.MODAL_SET_MANAGER: {
      return ({
        ...state,
        ...action.payload
      });
    }
    case types.MODAL_SET_BY_NAME_PROPS: {
      const {modal, ...rest} = action.payload;
      return ({
        ...state,
        [modal]: {
          ...state[modal],
          ...rest
        }
      });
    }
    default: return state;
  }
}
export default ({
  isFirstLoading: true,
  isEnablePostMessage: false,
  enableStatusBar: false,
  enableHour: false,
  statusBarMessage: '',
  btnSaveMessage: '',
  fetching: {
    getFormModelVersionId: false,
    saveAnswerForm: false,
    search: false,
    saveProfessional: false,
    savePatient: false,
    examSearch: false,
    addExamDynamic: false,
    getExamHistory: false
  },
  form: {
    formName: '',
    formId: 0,
    formModelId: 0,
    formModelVersionId: 0,
    formModelInfo: {
      name: '',
      description: ''
    },
    userIdCreated: '',
    versionTag: '',
    createdAt: '',
    sectionsIds: [],
    patientInfo: {},
    fieldPatientId: null,
    patientId: null,
    fieldPatientIsError: true
  },
  sections: {},
  modules: {},
  fields: {},
  examDataValueId: {},
  reportsIndexed: {},
  api: {
    search: {},
    examSearch: [],
    examHistory: {
      listRows: [],
      listKeys: {
        withHour  : [],
        default   : []
      },
    }
  },
  defaultExams: [],
  remote: {
    publicKey: '',
    userId: 0,
    localId: 0,
    patientId: 0,
    workspaceId: 0,
    patientName: '',
    enableAsk: {}
  },
});
export default ({
  isFirstLoading: true,
  sidebar:{
    isOpen: true,
    categoryUndefined: false,
    allFormModels: true,
  },
  fetching: {
    listFormModels: false,
    listDrafts: false,
    createFormEmpty: false,
    createDraft: false,
    saveCategory: false,
    updateCategory: false,
    getCategories: false,
  },
  api: {
    listFormModels: [],
    listDrafts: [],
    categoryProperty: {
      icon: "faFolder",
      color: "rgb(31, 34, 37)"
    },
    authors: [],
    authorsSelected: [],
    categoriesAllForms: [],
    categoriesForms: [],
    categorySelected: {},
    formsListFilter: {
      forms: [],
      draftsForms: []
    }
  }
});
export const MANAGER                                    = 'manager';
export const OVERVIEW                                   = 'overview';
export const BUSINESS                                   = 'business';
export const USERS                                      = 'users';
export const INVOICES                                   = 'invoices';
export const AUTOLOGIN                                  = 'autologin';
export const PERMISSIONS                                = 'permissions';
export const REGISTER                                   = 'register';
export const MODEL                                      = 'model';
export const FORM_MODEL                                 = 'form';
export const CONFIGURATION                              = 'configuration';
export const PACKAGES                                   = 'packages';
export const COUPONS                                    = 'coupons';
export const FORMSENGINE                                = 'formsEngine';
export const ANSWER                                     = 'answer';
export const CREATE                                     = 'create';
export const ROUTE_BASIC                                = 'app';
export const FORMS                                      = 'forms';
export const MEDICAL_RECORD                             = 'medicalRecord';
export const PERFIL                                     = 'perfil';
export const MODELS                                     = 'models';
export const DOCUMENT                                   = 'document';
export const DOCUMENTS                                  = 'documents';
export const GROUP                                      = 'group';
export const QUALIFIERS                                 = 'qualifiers';
export const CORELINE                                   = 'coreline';


export const ROUTE_MANAGER                              = `${MANAGER}`;
export const ROUTE_MANAGER_BUSINESS                     = `${ROUTE_MANAGER}/${BUSINESS}`;
export const ROUTE_MANAGER_PACKAGES                     = `${ROUTE_MANAGER}/${PACKAGES}`;
export const ROUTE_MANAGER_COUPONS                      = `${ROUTE_MANAGER}/${COUPONS}`;
export const ROUTE_MANAGER_MODELS                       = `${ROUTE_MANAGER}/${MODEL}`;
export const ROUTE_MANAGER_FORM_MODELS                  = `${ROUTE_MANAGER}/${FORM_MODEL}`;
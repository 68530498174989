import React from "react";
import * as util from "@Util";
import { actions as actionConfig } from "@State/ducks/config";
import types from "./constants";
import typeFields from "@Constants/typeFields";
import { selector as selectorLogin } from "@State/ducks/login/";
import selector from "./selector";
import systemConfigs from "@Constants/systemConfigs";
import { Modal } from "antd";
import formOrigin from "@Constants/formOrigin";
import { toastr } from "react-redux-toastr";
import * as actionsFieldModelDialog from "@Common/FieldModelDialog/action";

import { v4 as uuidv4 } from "uuid";

import dataTypes from "@Constants/typeFields";
import * as actionSnack from "@Common/snackBarRedux/action";
import * as actionModal from "@Common/modalRedux/action";

import * as actionConfigFields from "@Components/Manager/component/Fields/config/actions";
import constantsConfigFields from "@Components/Manager/component/Fields/config/constant";

// uncomment when implementing formEngine create and update @Manager
import constantsFormEngineCreate from "@Components/common/formEngine/Create/constants";
import * as actionFormEngineCreate from "@Components/common/formEngine/Create/action";

const ModalConfirm = Modal.confirm;

//#region PRIVATE METHOD
const timeout = {
  searchExam: null,
};

const isRadioGroupType = (type) => type === typeFields.RADIO_GROUP_TYPE;
const isCheckGroupType = (type) => type === typeFields.CHECKBOX_GROUP_TYPE;
const isInfoType = (type) => type === typeFields.INFO_LABEL_TYPE;
const isScoresType = (type) => type === typeFields.SCORES_TYPE;

const managerContext =
  ({ context, field }) =>
    (dispatch, getState, api) => {
      const state = getState();
      const _formOrigin = selector.getFormOrigin(state);

      switch (context) {
        case constantsConfigFields.CONTEXT_MODALFIELDS:
        case constantsConfigFields.CONTEXT_CONFIGURATION_MEDICALRECORD_FIELDS_UPDATE: {
          dispatch(actionConfigFields.getFields());
          break;
        }
        case constantsFormEngineCreate.CONTEXT_FORMENGINE_DRAFT_CREATE: {
          dispatch(actionFormEngineCreate.getListFields(_formOrigin));
          break;
        }
        case constantsFormEngineCreate.CONTEXT_FORMENGINE_DRAFT_UPDATE: {
          dispatch([
            actionFormEngineCreate.setUpdateFieldById(field),
            actionFormEngineCreate.getListFields(_formOrigin),
          ]);
          break;
        }
        // uncomment when implementing formEngine create @Manager
        /*case constantsFormEngineCreate.CONTEXT_FORMENGINE_DRAFT_CREATE: {
        dispatch(actionFormEngineCreate.getListFields(_formOrigin));
        break;
      }
      }*/
        default:
          break;
      }
    };
//#endregion

export const resetModal = () => ({ type: types.MODALFIELDS_RESET });
export const setSearchExamReset = () => ({
  type: types.MODALFIELDS_SET_SEARCH_EXAM_RESET,
});

export const setSwipeIndex = (data) => ({
  type: types.MODALFIELDS_SET_SWIPEABLE,
  payload: data,
});
export const setTabField = (data) => ({
  type: types.MODALFIELDS_SET_TAB_FIELD,
  payload: data,
});
export const setDataType = (data) => ({
  type: types.MODALFIELDS_SET_DATA_TYPE,
  payload: data,
});
export const setFieldSize = (data) => ({
  type: types.MODALFIELDS_SET_SIZE,
  payload: data,
});
export const setRangeMin = (data) => ({
  type: types.MODALFIELDS_SET_RANGE_MIN,
  payload: data,
});
export const setRangeMax = (data) => ({
  type: types.MODALFIELDS_SET_RANGE_MAX,
  payload: data,
});
export const setInfoRangeMin = (data) => ({
  type: types.MODALFIELDS_SET_INFO_RANGE_MIN,
  payload: data,
});
export const setInfoRangeMax = (data) => ({
  type: types.MODALFIELDS_SET_INFO_RANGE_MAX,
  payload: data,
});
export const setScoreRangeMin = (data) => ({
  type: types.MODALFIELDS_SET_SCORE_RANGE_MIN,
  payload: data,
});
export const setScoreRangeMax = (data) => ({
  type: types.MODALFIELDS_SET_SCORE_RANGE_MAX,
  payload: data,
});
export const setSelectQualifier = (data) => ({
  type: types.MODALFIELDS_SET_SELECT_QUALIFIER,
  payload: data,
});
export const setSelectQualifierDeficiency = (data) => ({
  type: types.MODALFIELDS_SET_SELECT_QUALIFIER_DEFICIENCY,
  payload: data,
});
export const setSelectQualifierId = (data) => ({
  type: types.MODALFIELDS_SET_SELECT_QUALIFIER_ID,
  payload: data,
});
export const setQualifierDeficiencyId = (data) => ({
  type: types.MODALFIELDS_SET_QUALIFIER_DEFICIENCY_ID,
  payload: data,
})
export const setReferenceField = (data) => ({
  type: types.MODALFIELDS_SET_REFERENCE_FIELD,
  payload: data,
});
export const setDeficiencyCalc = (data) => ({
  type: types.MODALFIELDS_SET_DEFICIENCY_CALC,
  payload: data,
});
export const setTypeReference = (data) => ({
  type: types.MODALFIELDS_SET_TYPE_REFERENCE,
  payload: data,
});
export const setSelectAnalyzeBetter = (data) => ({
  type: types.MODALFIELDS_SET_SELECT_ANALYZE_BETTER,
  payload: data,
});
export const setSelectTypeScore = (data) => ({
  type: types.MODALFIELDS_SET_SELECT_TYPE_SCORE,
  payload: data,
});
export const setEnableField = (data) => ({
  type: types.MODALFIELDS_SET_ENABLE_FIELD,
  payload: data,
});
export const setEnableNewLine = (data) => ({
  type: types.MODALFIELDS_SET_ENABLE_NEW_LINE,
  payload: data,
});
export const setEnableNewPage = (data) => ({
  type: types.MODALFIELDS_SET_ENABLE_NEW_PAGE,
  payload: data,
});
export const setEnablePeriodInterval = (data) => ({
  type: types.MODALFIELDS_SET_ENABLE_PERIOD_INTERVAL,
  payload: data,
});
export const setEnablePreProgrammed = (data) => ({
  type: types.MODALFIELDS_SET_ENABLE_PRE_PROGRAMMED,
  payload: data,
});
export const setEnableConfigScore = (data) => ({
  type: types.MODALFIELDS_SET_ENABLE_CONFIG_SCORE,
  payload: data,
});
export const setEnableObservation = (data) => ({
  type: types.MODALFIELDS_SET_ENABLE_OBSERVATION,
  payload: data,
});
export const setEnableOrientation = (data) => ({
  type: types.MODALFIELDS_SET_ENABLE_ORIENTATION,
  payload: data,
});
export const setEnableSelectListDesign = (data) => ({
  type: types.MODALFIELDS_SET_ENABLE_SELECT_LIST_DESIGN,
  payload: data,
});
export const setFieldTitle = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_TITLE,
  payload: data,
});
export const setFieldDisplayTitle = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_DISPLAY_TITLE,
  payload: data,
});
export const setFieldMeasurementUnit = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_MEASUREMENT_UNIT,
  payload: data,
});
export const setFieldCodeBlock = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_CODE_BLOCK,
  payload: data,
});
export const setFieldGraphicConfig = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_GRAPHIC_CONFIG,
  payload: data,
});
export const setFieldRefData = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_REFERENCE_DATA,
  payload: data,
});
export const setFieldCodeConfig = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_CODE_CONFIG,
  payload: data,
});
export const setFieldPoints = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_POINTS,
  payload: data,
});
export const setFieldCheckPoints = (data) => ({
  type: types.MODALFIELDS_SET_CHECKPOINTS,
  payload: data,
});
export const setFieldEmptyPointsWarning = (data) => ({
  type: types.MODALFIELDS_SET_EMPTY_POINTS_WARNING,
  payload: data,
});
export const setFieldsOptions = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_OPTIONS,
  payload: data,
});
export const setOptions = (data) => ({
  type: types.MODALFIELDS_SET_OPTIONS,
  payload: data,
});
export const setFieldOptionUpdate = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_OPTIONS_UPDATE,
  payload: data,
});
export const setFieldOptionById = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_OPTION_BY_ID,
  payload: data,
});
export const searchExamFetchRequest = () => ({
  type: types.MODALFIELDS_SEARCH_EXAM_FETCH_REQUEST,
});
export const searchExamFetchSuccess = (data) => ({
  type: types.MODALFIELDS_SEARCH_EXAM_FETCH_SUCCESS,
  payload: data,
});
export const searchExamFetchError = () => ({
  type: types.MODALFIELDS_SEARCH_EXAM_FETCH_ERROR,
});
export const setDefaultExams = (data) => ({
  type: types.MODALFIELDS_SET_DEFAULT_EXAMS,
  payload: data,
});
export const setFieldById = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_BY_ID,
  payload: data,
});
export const saveFieldFetchRequest = () => ({
  type: types.MODALFIELDS_SAVE_FETCH_REQUEST,
});
export const saveFieldFetchSuccess = () => ({
  type: types.MODALFIELDS_SAVE_FETCH_SUCCESS,
});
export const saveFieldFetchError = (data) => ({
  type: types.MODALFIELDS_SAVE_FETCH_ERROR,
  payload: data,
});
export const setFieldDescription = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_DESCRIPTION,
  payload: data,
});
export const updateFieldModelByIdFetchRequest = () => ({
  type: types.MODALFIELDS_UPDATE_FIELD_MODEL_BY_ID_FETCH_REQUEST,
});
export const updateFieldModelByIdFetchSuccess = () => ({
  type: types.MODALFIELDS_UPDATE_FIELD_MODEL_BY_ID_FETCH_SUCCESS,
});
export const updateFieldModelByIdFetchError = () => ({
  type: types.MODALFIELDS_UPDATE_FIELD_MODEL_BY_ID_FETCH_ERROR,
});
export const setGetFieldByIdLoading = () => ({
  type: types.MODALFIELDS_SET_GET_FIELD_BY_ID_LOADING,
});
export const setFieldMapBody = (data) => ({
  type: types.MODALFIELDS_SET_FIELD_MAP_BODY,
  payload: data,
});
const setListQualifiersFetchSuccess = (data) => ({
  type: types.MODALFIELDS_SET_LIST_QUALIFIERS_FETCH_SUCCESS,
  payload: data,
})

export const setFieldContext = (params) => (dispatch) => {
  const data = {
    context: params.context || "",
    formOrigin: params.formOrigin || null,
  };

  dispatch({ type: types.MODALFIELDS_SET_CONTEXT, payload: data });
};

export const setEnableType = (props) => (dispatch) => {
  const { type, checked } = props;

  if (type === "newLine") dispatch(setEnableNewLine({ checked }));
  else if (type === "newPage") dispatch(setEnableNewPage({ checked }));
  else if (type === "periodInterval")
    dispatch(setEnablePeriodInterval({ checked }));
  else if (type === "observation") dispatch(setEnableObservation({ checked }));
  else if (type === "isInline") dispatch(setEnableOrientation({ checked }));
  else if (type === "selectListDesign")
    dispatch(setEnableSelectListDesign({ checked }));
  else if (type === "preProgrammed")
    dispatch(setEnablePreProgrammed({ checked }));
  else if (type === "configScore"){
    dispatch(setEnableConfigScore({ checked }));
    if(!checked)
      dispatch(disabledConfigScore());
    }
};


const disabledConfigScore = () => (dispatch) => {
  dispatch([
    setSelectQualifier({ value: null }),
    setSelectQualifierDeficiency({ value: null }),
    setSelectQualifierId({ value: null }),
    setQualifierDeficiencyId({ value: null }),
    setSelectAnalyzeBetter({ value: null }),
    setSelectTypeScore({ value: null }),
    setDeficiencyCalc({ value: null }),
    setTypeReference({ value: null }), 
  ])
  };

export const setRangeType = (props) => (dispatch) => {
  const { type, value } = props;
  if (type === "rangeMin") dispatch(setRangeMin({ value }));
  else if (type === "rangeMax") dispatch(setRangeMax({ value }));
};

export const setTextInfoRangeType = (props) => (dispatch) => {
  const { type, value } = props;
  if (type === "infoMin") dispatch(setInfoRangeMin({ value }));
  else if (type === "infoMax") dispatch(setInfoRangeMax({ value }));
}

export const setScoreRangeType = (props) => (dispatch) => {
  const { type, value } = props;
  if (type === "scoreMin") dispatch(setScoreRangeMin({ value }));
  else if (type === "scoreMax") dispatch(setScoreRangeMax({ value }));
}

export const setSelectType = (props) => (dispatch) => {
  const { type, value } = props;
  if (type === "qualifier") dispatch(setSelectQualifier({ value }));
  else if (type === "analyzeBetter") dispatch(setSelectAnalyzeBetter({ value }));
  else if (type === "typeScore") dispatch(setSelectTypeScore({ value }));
  else if (type === "qualifierDeficiency") dispatch(setSelectQualifierDeficiency({ value }));
  else if (type === "qualifierId") dispatch(setSelectQualifierId({ value }));
  else if (type === "qualifierDeficiencyId") dispatch(setQualifierDeficiencyId({ value }));
  else if (type === "deficiencyCalc") dispatch(setDeficiencyCalc({ value }));
  else if (type === "typeReference") dispatch(setTypeReference({ value }));
  else if (type === "referenceField") dispatch(setReferenceField({ value }));
}

export const setFieldsOptionsRemovedById =
  (idField) => (dispatch, getState) => {
    const state = getState();
    const field = selector.getField(state);
    const optionsIds = selector.getOptionsIds(state);
    const optionsIndexed = selector.getOptionsIndexed(state);

    const newOptionsIds = optionsIds.filter((id) => id !== idField);
    delete optionsIndexed[idField];

    const newField = {
      ...field,
      optionsIds: newOptionsIds,
    };

    dispatch(
      setFieldOptionUpdate({
        field: newField,
        optionsIndexed: { ...optionsIndexed },
      })
    );
  };

export const setSelectedExams = (data) => (dispatch) => {
  dispatch(setDefaultExams(data));
};

export const updateFields =
  (dataType, modal, field, codeBlock, totalFields, configFullyAnswered) =>
    (dispatch, getState) => {
      const state = getState();
      const context = selector.getContext(state);
      const measurementUnit = field.measurementUnit;
      const isFetchingSave = selector.getIsFetchingSave(state);
      if (isFetchingSave) return; // stops saveField spamming
      dispatch(saveFieldFetchRequest());
      if (isRadioGroupType(dataType) || isCheckGroupType(dataType)) {
        dispatch(saveFieldCheckOrRadioGroup({ modal, dataType }));
        return;
      }

      if (isInfoType(dataType)) {
        dispatch(saveFieldInfo({ modal, dataType }));
        return;
      }

      if (isScoresType(dataType) && codeBlock) {
        field.code = codeBlock;
        field.codeConfig = configFullyAnswered;
      }

      if (!field.fieldModelId) {
        dispatch(
          saveFieldSimple({
            modal,
            dataType,
            codeBlock,
            totalFields,
            configFullyAnswered,
            measurementUnit,
          })
        );
      } else if (field.title !== field.historyName) {
        dispatch(
          saveFieldSimple({
            modal,
            dataType,
            codeBlock,
            totalFields,
            configFullyAnswered,
            measurementUnit,
          })
        );
      } else if (field.title === field.historyName) {
        dispatch(
          saveFieldSimple({
            modal,
            dataType,
            codeBlock,
            totalFields,
            configFullyAnswered,
            measurementUnit,
          })
        );
      }
      dispatch([
        managerContext({
          context,
          field: { ...field },
        }),
      ]);
      return;
    };

export const saveField =
  ({ modal, codeBlock, totalFields, configFullyAnswered = null, graphicConfig }) =>
    (dispatch, getState) => {
      const ModalConfirm = Modal.confirm;
      const state = getState();
      const dataType = selector.getFieldDataType(state);
      const context = selector.getContext(state);
      const field = selector.getField(state);
      const measurementUnit = field.measurementUnit;

      const { originalDataType } = field;

      /**
       * Internal function to update fields values
       * @param {*} dataType
       * @param {*} modal
       * @param {*} field
       * @returns Internal function to update field values based on the dataType and field,
       *          utilizing one of the functions: @function saveFieldCheckOrRadioGroup
       *                                          @function saveFieldInfo
       *                                          @function saveFieldSimple
       *                                          @function setUpdateFieldModelById
       */
      const updateFields = (
        dataType,
        modal,
        field,
        codeBlock,
        totalFields,
        configFullyAnswered,
        graphicConfig,
      ) => {
        const measurementUnit = field.measurementUnit;
        if (isRadioGroupType(dataType) || isCheckGroupType(dataType)) {
          dispatch(saveFieldCheckOrRadioGroup({ modal, dataType }));
          return;
        }

        if (isInfoType(dataType)) {
          dispatch(saveFieldInfo({ modal, dataType }));
          return;
        }

        if (isScoresType(dataType) && codeBlock) {
          field.code = codeBlock;
          field.codeConfig = configFullyAnswered;
        }

        if (!field.fieldModelId) {
          dispatch(
            saveFieldSimple({
              modal,
              dataType,
              codeBlock,
              totalFields,
              configFullyAnswered,
              measurementUnit,
              graphicConfig,
            })
          );
        } else if (field.title !== field.historyName) {
          dispatch(
            saveFieldSimple({
              modal,
              dataType,
              codeBlock,
              totalFields,
              configFullyAnswered,
              measurementUnit,
              graphicConfig,
            })
          );
        } else if (field.title === field.historyName) {
          dispatch(
            saveFieldSimple({
              modal,
              dataType,
              codeBlock,
              totalFields,
              configFullyAnswered,
              measurementUnit,
              graphicConfig,
            })
          );
        }
        dispatch([
          managerContext({
            context,
            field: { ...field, graphicConfig },
          }),
        ]);
        return;
      };

      //Checks and blocks if points are null when trying to save with CheckPoints checked
      const isEmpty = checkEmptyPoints(field)(dispatch, getState);
      if (isEmpty) return;
      updateFields(
        dataType,
        modal,
        field,
        codeBlock,
        totalFields,
        configFullyAnswered,
        graphicConfig
      );
      saveField(dataType, modal, field); //Totally new field or different name
    };

export const editFieldById = (props) => (dispatch) => {
  const { context, formOrigin, field, viewOnly, getFieldById, totalFields } =
    props;
  const checkPoints = props.field.checkPoints;
  let editFieldByIdPoints = null;
  let optionsIds = [];
  let optionsIndexed = {};

  if (checkPoints !== undefined && !checkPoints) editFieldByIdPoints = null;
  //Sets points to zero when points checkbox is unchecked
  else if (props.field.points !== null)
    editFieldByIdPoints = props.field.points;

  if (field.checkboxGroup.options.length) {
    field.checkboxGroup.options.forEach((opt) => {
      optionsIndexed[opt.order] = {
        id: opt.order,
        value: opt.name,
        points: opt.points,
      };

      optionsIds.push(opt.order);
    });
  } else if (field.radioGroup.options.length) {
    field.radioGroup.options.forEach((opt) => {
      optionsIndexed[opt.order] = {
        id: opt.order,
        value: opt.name,
        points: opt.points,
      };

      optionsIds.push(opt.order);
    });
  } else {
    optionsIds.push(0);
    optionsIndexed = { id: 0, value: "", points: editFieldByIdPoints };
  }

  const newField = {
    id: field.id,
    dataType: field.dataType,
    title: field.fieldName,
    historyName: field.fieldName,
    historyMeasurementUnit: field.measurementUnit,
    size: field.size,
    enableObservation: field.enableObservation || false,
    enablePeriodInterval: field.enablePeriodInterval || false,
    enableNewLine: field.enableNewLine || false,
    enableNewPage: field.enableNewPage || false,
    enableInline: field.enableInline || false,
    enableSelectListDesign: field.enableSelectListDesign || false,
    rangeMin: field.rangeMin || 0,
    rangeMax: field.rangeMax || 10,
    textInfoMin: field.textInfoMin || '',
    textInfoMax: field.textInfoMax || '',
    typeScore: field.typeScore,
    analyzeBetter: field.analyzeBetter,
    qualifier: field.qualifier,
    scoreMax: field.scoreMax,
    scoreMin: field.scoreMin || 0,
    qualifierId: field.qualifierId,
    referenceField: field.referenceField,
    deficiencyCalc: field.deficiencyCalc,
    qualifierDeficiency: field.qualifierDeficiency,
    qualifierDeficiencyId: field.qualifierDeficiencyId,
    fieldModelId: field.fieldModelId,
    fieldModelVersionId: field.fieldModelVersionId,
    workspaceId: field.workspaceId,
    checkboxGroupModelId: field.checkboxGroup.checkboxGroupModelId || 0,
    radioGroupModelId: field.radioGroup.radioGroupModelId || 0,
    optionsIds,
    totalFields: totalFields,
    configScore: field.analyzeBetter ? true : false,
    code: field.code,
    codeConfig: field.codeConfig,
    points: editFieldByIdPoints,
    fieldId: field.fieldId,
    measurementUnit: field.measurementUnit,
    isBeingEdited: true,
    displayName: field.displayName,
    enablePreProgrammed: field.preProgrammed,
    graphicConfig: field.graphicConfig,
    typePainMapBody: field.typePainMapBody,
  };

  try {
    dispatch([
      setFieldContext({ context, formOrigin }),
      setFieldById({
        field: newField,
        optionsIndexed,
        viewOnly: viewOnly || false,
        getFieldById,
      }),
    ]);
  } catch (error) {
    const { msg } = error;
    const customMsg = !!msg;

    util.managerExceptionURIMessage({ error, customMsg });

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
    // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

//======= APIs =======

export const checkFieldModelAvailabilityFromManager =
  ({
    modal,
    codeBlock,
    totalFields,
    configFullyAnswered = null,
    measurementUnit,
  }) =>
    async (dispatch, getState, { apiForms }) => {
      try {
        const state = getState();
        const header = selectorLogin.getHeaderConfig(state);
        const field = selector.getField(state);
        const formOrigin = selector.getFormOrigin(state);
        const dataType = selector.getFieldDataType(state);
        const _header = { ...header, formOrigin };
        const isBeingEdited = field.isBeingEdited;
        const updateFieldsParams = {
          dataType,
          modal,
          field,
          codeBlock,
          totalFields,
          configFullyAnswered,
          measurementUnit,
        };

        const params = {
          name: field.title,
          dataType: field.dataType,
          measurementUnit: field.measurementUnit,
          displayName: field.displayName,
        };

        dispatch(saveFieldFetchRequest());

        let response =
          await apiForms.field.checkFieldModelAvailabilityFromManager({
            header: _header,
            params,
          });

        let isAvaliable = response.data.isAvailable;

        dispatch(saveFieldFetchSuccess());

        if (!isAvaliable) {
          dispatch(
            actionsFieldModelDialog.setDialogState({
              dialogOpen: true,
              isAvailable: false,
              data: updateFieldsParams,
            })
          );
          return;
        } else if (isAvaliable && response.data.diff && isBeingEdited) {
          switch (response.data.diff) {
            case "title":
              dispatch(
                actionsFieldModelDialog.setDialogState({
                  dialogOpen: true,
                  isAvailable: true,
                  diff: "title",
                  data: updateFieldsParams,
                })
              );
              return;
            case "dataType":
              dispatch(
                actionsFieldModelDialog.setDialogState({
                  dialogOpen: true,
                  isAvailable: true,
                  diff: "dataType",
                  data: updateFieldsParams,
                })
              );
              return;
            case "measurementUnit":
              dispatch(
                actionsFieldModelDialog.setDialogState({
                  dialogOpen: true,
                  isAvailable: true,
                  diff: "measurementUnit",
                  data: updateFieldsParams,
                })
              );
              return;
            default:
              return null;
          }
        } else
          dispatch(
            updateFields(
              dataType,
              modal,
              field,
              codeBlock,
              totalFields,
              configFullyAnswered
            )
          );
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        dispatch(saveFieldFetchError());

        util.managerExceptionURIMessage({ error, customMsg });

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
      }
    };

export const checkFieldModelAvailability =
  ({
    modal,
    codeBlock,
    totalFields,
    configFullyAnswered = null,
    measurementUnit,
  }) =>
    async (dispatch, getState, { apiForms }) => {
      try {
        const state = getState();
        const header = selectorLogin.getHeaderConfig(state);
        const field = selector.getField(state);
        const formOrigin = selector.getFormOrigin(state);
        const dataType = selector.getFieldDataType(state);
        const _header = { ...header, formOrigin };
        const isBeingEdited = field.isBeingEdited;
        const updateFieldsParams = {
          dataType,
          modal,
          field,
          codeBlock,
          totalFields,
          configFullyAnswered,
          measurementUnit,
        };

        const params = {
          name: field.title,
          dataType: field.dataType,
          measurementUnit: field.measurementUnit,
          displayName: field.displayName,
        };

        dispatch(saveFieldFetchRequest());

        let response = await apiForms.field.checkFieldModelAvailability({
          header: _header,
          params,
        });

        let isAvaliable = response.data.isAvailable;

        dispatch(saveFieldFetchSuccess());

        if (!isAvaliable) {
          dispatch(
            actionsFieldModelDialog.setDialogState({
              dialogOpen: true,
              isAvailable: false,
              data: updateFieldsParams,
            })
          );
          return;
        } else if (isAvaliable && response.data.diff && isBeingEdited) {
          switch (response.data.diff) {
            case "title":
              dispatch(
                actionsFieldModelDialog.setDialogState({
                  dialogOpen: true,
                  isAvailable: true,
                  diff: "title",
                  data: updateFieldsParams,
                })
              );
              return;
            case "dataType":
              dispatch(
                actionsFieldModelDialog.setDialogState({
                  dialogOpen: true,
                  isAvailable: true,
                  diff: "dataType",
                  data: updateFieldsParams,
                })
              );
              return;
            case "measurementUnit":
              dispatch(
                actionsFieldModelDialog.setDialogState({
                  dialogOpen: true,
                  isAvailable: true,
                  diff: "measurementUnit",
                  data: updateFieldsParams,
                })
              );
              return;
            default:
              return null;
          }
        } else
          dispatch(
            updateFields(
              dataType,
              modal,
              field,
              codeBlock,
              totalFields,
              configFullyAnswered
            )
          );
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        dispatch(saveFieldFetchError());

        util.managerExceptionURIMessage({ error, customMsg });

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
      }
    };

export const saveFieldSimple =
  ({
    modal,
    dataType,
    codeBlock,
    totalFields,
    configFullyAnswered,
    measurementUnit,
    graphicConfig
  }) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      const header = selectorLogin.getHeaderConfig(state);
      const field = selector.getField(state);
      const defaultExams = selector.getDefaultExams(state);
      const context = selector.getContext(state);
      const formOrigin = selector.getFormOrigin(state);
      const optionsIndexed = selector.getOptionsIndexed(state);
      let checks = {};
      const pointsCheck = field.checkPoints;
      const displayName = field.displayName;

      let code = {};
      let codeConfig = null;
      let fields = {};
      let _header = { ...header, formOrigin };

      if (dataType === 18 || dataType === 19 || dataType === 20) {
        checks = {
          enableObservation: field.enableObservation,
          enablePeriodInterval: field.enablePeriodInterval,
        };
      }

      if (dataType === 24) {
        //Is it a scores calculator field?
        code = codeBlock;
        fields = totalFields;
        codeConfig = configFullyAnswered;
        if (field.enablePreProgrammed) {
          code = field.code;
          fields = field.refData;
          codeConfig = field.codeConfig;
          checks = {
            enablePreProgrammed: field.enablePreProgrammed,
          };
        }
      } else {
        code = null; //Set to null if its not a scores field
        fields = null;
      }
      if(dataType === 27){
        checks = {
          enablePreProgrammed: field.enablePreProgrammed,
        };
      }

      let params = {
        dataType,
        ...checks,
        name: field.title,
        enableNewPage: field.enableNewPage,
        enableNewLine: field.enableNewLine,
        size: field.size,
        code,
        codeConfig,
        fields,
        measurementUnit,
        displayName,
        analyzeBetter: field.analyzeBetter,
        typeScore: field.typeScore,
        qualifier: field.qualifier,
        scoreMin: field.scoreMin,
        scoreMax: field.scoreMax,
        qualifierId: field.qualifierId,
        referenceField: field.referenceField,
        deficiencyCalc: field.deficiencyCalc,
        qualifierDeficiency: field.qualifierDeficiency,
        qualifierDeficiencyId: field.qualifierDeficiencyId,
        graphicConfig: field.graphicConfig || graphicConfig,
        typePainMapBody: field.typePainMapBody,
        fieldId: uuidv4(),
      };

      if (dataType === 6)
        //Adds points to Checkbox only
        params = {
          ...params,
          points: field.points,
        };

      if (dataType === 21)
        //Exame
        params = {
          ...params,
          defaultExams: defaultExams.map((exam) => exam.examId),
        };

      if (dataType === 22)
        //Relatorio
        params = { ...params, description: field.description };

      if (dataType === 23)
        //Slider
        params = {
          ...params,
          rangeMin: field.rangeMin,
          rangeMax: field.rangeMax,
          textInfoMin: field.textInfoMin,
          textInfoMax: field.textInfoMax,
          rangeDescription: null,
        };

      try {
        if (!dataType) {
          dispatch(
            actionSnack.showSnackbar({
              message: "Selecione pelo menos um tipo!",
              variant: "warning",
              autoHideDuration: 3500,
            })
          );
          return;
        }

        if (!field.title || !field.title.length) {
          dispatch(
            actionSnack.showSnackbar({
              message: "O campo titulo é obrigatorio!",
              variant: "warning",
              autoHideDuration: 3500,
            })
          );
          return;
        }

        if (
          (dataType === dataTypes.SLIDER_TYPE && field.rangeMin === null) ||
          field.rangeMax === null
        ) {
          dispatch(
            actionSnack.showSnackbar({
              message: "Defina o valor da Escala!",
              variant: "warning",
              autoHideDuration: 3500,
            })
          );
          return dispatch(saveFieldFetchError());
        }

        if (!pointsCheck) params.points = null; //Saves points as null if Points checkbox is unchecked

        dispatch(saveFieldFetchRequest());

        let resSave = await apiForms.field.saveField({ header: _header, params });
        resSave = resSave.data;

        dispatch([
          saveFieldFetchSuccess(),
          actionModal.onClose({ modal }),
          managerContext({
            context,
            field: { ...field, ...resSave },
          }),
          actionSnack.showSnackbar({ message: "Salvo com sucesso." }),
          resetModal(),
        ]);
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        dispatch(saveFieldFetchError());

        util.managerExceptionURIMessage({ error, customMsg });

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
        // window.location = systemConfigs.AUTH_DOMAIN;
      }
    };

export const saveFieldInfo =
  ({ modal, dataType }) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      const header = selectorLogin.getHeaderConfig(state);
      const field = selector.getField(state);
      const context = selector.getContext(state);
      const formOrigin = selector.getFormOrigin(state);

      let _header = { ...header, formOrigin };

      const params = {
        dataType,
        size: 12,
        name: field.title,
        fieldId: uuidv4(),
        displayName: field.displayName,
      };

      try {
        if (!field.title || !field.title.length) {
          dispatch(
            actionSnack.showSnackbar({
              message: "O campo titulo é obrigatorio!",
              variant: "warning",
              autoHideDuration: 3500,
            })
          );
          return;
        }

        dispatch(saveFieldFetchRequest());

        const infoLabel = await apiForms.field.saveFieldInfo({
          header: _header,
          params,
        });

        const { infoId } = infoLabel.data;
        let resSave = await apiForms.field.saveField({
          header: _header,
          params: { ...params, infoId },
        });
        resSave = resSave.data;

        dispatch([
          saveFieldFetchSuccess(),
          actionModal.onClose({ modal }),
          managerContext({
            context,
            field: { ...field, ...resSave },
          }),
          actionSnack.showSnackbar({ message: "Salvo com sucesso." }),
          resetModal(),
        ]);
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        dispatch(saveFieldFetchError());

        util.managerExceptionURIMessage({ error, customMsg });

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
        // window.location = systemConfigs.AUTH_DOMAIN;
      }
    };

export const saveFieldCheckOrRadioGroup =
  ({ modal, dataType }) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      const header = selectorLogin.getHeaderConfig(state);
      const field = selector.getField(state);
      const context = selector.getContext(state);
      const optionsIndexed = selector.getOptionsIndexed(state);
      const formOrigin = selector.getFormOrigin(state);
      const options = [];
      const checkPoints = field.checkPoints;

      let _header = { ...header, formOrigin };

      field.optionsIds.forEach((item) => {
        const _opt = optionsIndexed[item];
        const _pts = !checkPoints ? null : _opt.points; //Checks and set points to null if points checkbox is unchecked
        if (_opt && _opt.value) {
          options.push({
            name: _opt.value,
            order: _opt.id,
            points: _pts,
          });
        }
      });

      const nameKey = isRadioGroupType(dataType) ? "radioGroup" : "checkboxGroup";
      const paramsRadioGroup = {
        [nameKey]: {
          name: field.title,
          display: "column",
          options,
        },
      };

      let paramsField = {
        dataType,
        size: field.size,
        name: field.title,
        fieldId: uuidv4(),
        displayName: field.displayName,
        analyzeBetter: field.analyzeBetter,
        qualifier: field.qualifier,
        scoreMax: field.scoreMax,
        scoreMin: field.scoreMin || 0,
        qualifierId: field.qualifierId,
        referenceField: field.referenceField,
        deficiencyCalc: field.deficiencyCalc,
        qualifierDeficiency: field.qualifierDeficiency,
        qualifierDeficiencyId: field.qualifierDeficiencyId,
        configScore: field.analyzeBetter ? true : false,
      };

      try {
        if (!field.title || !field.title.length) {
          dispatch(
            actionSnack.showSnackbar({
              message: "O campo titulo é obrigatorio!",
              variant: "warning",
              autoHideDuration: 3500,
            })
          );
          return;
        }

        if (!options.length) {
          dispatch(
            actionSnack.showSnackbar({
              message: "Adiciona pelo menos uma opção valida!",
              variant: "warning",
              autoHideDuration: 3500,
            })
          );
          return;
        }

        dispatch(saveFieldFetchRequest());

        let response = isRadioGroupType(dataType)
          ? await apiForms.field.saveRadioGroup({
            header: _header,
            params: paramsRadioGroup,
          })
          : await apiForms.field.saveCheckboxGroup({
            header: _header,
            params: paramsRadioGroup,
          });
        paramsField = { ...paramsField, ...response.data };

        const fieldParamsRadioGroup = {
          ...paramsRadioGroup[nameKey],
          ...response.data,
        };

        let resSave = await apiForms.field.saveField({
          header: _header,
          params: paramsField,
        });
        resSave = resSave.data;

        dispatch([
          saveFieldFetchSuccess(),
          actionModal.onClose({ modal }),
          managerContext({
            context,
            field: {
              ...field,
              ...resSave,
              [nameKey]: { ...fieldParamsRadioGroup },
            },
          }),
          actionSnack.showSnackbar({ message: "Salvo com sucesso." }),
          resetModal(),
        ]);
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        dispatch(saveFieldFetchError());

        util.managerExceptionURIMessage({ error, customMsg });

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
        // window.location = systemConfigs.AUTH_DOMAIN;
      }
    };

export const onSearch =
  ({ value, typeSearch }) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      const header = selectorLogin.getHeaderConfig(state);
      const formOrigin = selector.getFormOrigin(state);

      let _header = { ...header, formOrigin };

      clearTimeout(timeout.searchExam);

      try {
        timeout.searchExam = setTimeout(async () => {
          if (!value || !value.length) {
            dispatch(setSearchExamReset());
            return;
          }

          dispatch(searchExamFetchRequest());

          if (typeSearch === "examCreate") {
            let response = await apiForms.exam.getSearchExam({
              header: _header,
              value,
            });
            let exams = response.data.exams;

            dispatch(searchExamFetchSuccess({ exams }));
          }
        }, 800);
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        dispatch(searchExamFetchError());

        util.managerExceptionURIMessage({ error, customMsg });

        if (error && error.status === 401) {
          util.managerExceptionURIMessage({ error, customMsg });
          dispatch(actionConfig.routeRedirect("/"));
          // window.location = systemConfigs.AUTH_DOMAIN;
        }
      }
    };

export const setUpdateFieldModelById =
  ({ modal, field }) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      const header = selectorLogin.getHeaderConfig(state);
      const context = selector.getContext(state);
      const formOrigin = selector.getFormOrigin(state);

      const params = {
        name: field.title,
        fieldModelId: field.fieldModelId,
      };

      let _header = { ...header, formOrigin };

      try {
        dispatch(updateFieldModelByIdFetchRequest());

        await apiForms.field.putFieldModelById({ header: _header, params });

        dispatch([
          updateFieldModelByIdFetchSuccess(),
          actionModal.onClose({ modal }),
          managerContext({
            context,
            field: { ...field },
          }),
          resetModal(),
        ]);
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        dispatch(updateFieldModelByIdFetchError());

        util.managerExceptionURIMessage({ error, customMsg });

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect("/"));
        // window.location = systemConfigs.AUTH_DOMAIN;
      }
    };

/**
 * Checks if the field should have points according to the points checkBox
 * @param {Object} field - Specific Field data
 * @returns {Boolean} Returns true if field have no Points and its checked to have points
 */
const checkEmptyPoints = (field) => (dispatch, getState) => {
  const emptyPointsMessage = `Você não pode salvar pontos VAZIO. Por favor, informe a pontuação nos campos marcados em vermelho.`;
  const state = getState();
  const optionsIndexed = selector.getOptionsIndexed(state);
  let isEmpty = false;
  switch (field.dataType) {
    case dataTypes.CHECKBOX_TYPE: {
      if (field.checkPoints && field.points === null) {
        dispatch(setFieldEmptyPointsWarning({ emptyPointsWarning: true }));
        toastr.warning("Atenção", emptyPointsMessage);
        isEmpty = true;
      }
      break;
    }
    case dataTypes.CHECKBOX_GROUP_TYPE: {
      Object.keys(optionsIndexed).map((opt) => {
        if (field.checkPoints && optionsIndexed[opt].points === null) {
          dispatch(setFieldEmptyPointsWarning({ emptyPointsWarning: true }));
          toastr.warning("Atenção", emptyPointsMessage);
          isEmpty = true;
          return;
        }
      });
      break;
    }
    case dataTypes.RADIO_GROUP_TYPE: {
      Object.keys(optionsIndexed).map((opt) => {
        if (field.checkPoints && optionsIndexed[opt].points === null) {
          dispatch(setFieldEmptyPointsWarning({ emptyPointsWarning: true }));
          toastr.warning("Atenção", emptyPointsMessage);
          isEmpty = true;
          return;
        }
      });
      break;
    }
  }
  return isEmpty;
};

export const getListQualifier = () => async (dispatch, getState, { apiForms }) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  try {
    const response = await apiForms.qualifier.getQualifiers({ header });

    dispatch([
      setListQualifiersFetchSuccess(response.data ? response.data.qualifiers : []),
    ]);

  } catch (error) {
    const { userFeedback } = error;

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect("/"));
  }
}

import * as endpoints from '@App/constants/endpoints';
import * as keys from '@App/constants/keys';
import interceptor from '../interceptor';

const _get = async props => {
  const { axios, pathUrl } = props;

  try {
    return (await axios.get(pathUrl)).data;
  } catch (error) {
      if (error && error.response && error.response.status)
          throw {
              status: error.response.status,
              userFeedback: error.response.data.userFeedback,
          };
      throw error;
  }
};

const _post = async (axios, pathUrl, params = {}) => {
  try {
      return (await axios.post(pathUrl, params)).data;
  } catch (error) {
      if (error && error.response && error.response.status)
          throw {
              status: error.response.status,
              userFeedback: error.response.data.userFeedback,
          };
      throw error;
  }
};

const _put = async (axios, pathUrl, params = {}) => {
  try {
      return (await axios.put(pathUrl, params)).data;
  } catch (error) {
      if (error && error.response && error.response.status)
          throw {
              status: error.response.status,
              userFeedback: error.response.data.userFeedback,
          };
      throw error;
  }
};

const _delete = async (axios, pathUrl, params = {}) => {
  try {
      return (await axios.delete(pathUrl, params)).data;
  } catch (error) {
      if (error && error.response && error.response.status)
          throw {
              status: error.response.status,
              userFeedback: error.response.data.userFeedback,
          };
      throw error;
  }
};

//#region GET
export const getCoupon = async params => {
  const { couponId } = params
  const pathUrl = `${endpoints.API_MANAGER}/coupons/${couponId}`;
  const axios = interceptor({ xApiKey: keys.API_MANAGER_KEY });
  return await _get({ axios, pathUrl });
};
//#endregion

//#region PUT
export const updateCoupon = async params => {
  const { couponId, body } = params;
  const pathUrl = `${endpoints.API_MANAGER}/coupons?id=${couponId}`;
  const axios = interceptor({ xApiKey: keys.API_MANAGER_KEY });
  return await _put(axios, pathUrl, body);
}
//#endregion

//#region POST
export const createCoupon = async params => {
  const { body } = params
  const pathUrl = `${endpoints.API_MANAGER}/coupons`;
  const axios = interceptor({ xApiKey: keys.API_MANAGER_KEY });
  return await _post(axios, pathUrl, body);
};
//#endregion

//#region DELETE
export const deleteCoupon = async params => {
  const { couponId } = params;
  const pathUrl = `${endpoints.API_MANAGER}/coupons/${couponId}`;
  const axios = interceptor({ xApiKey: keys.API_MANAGER_KEY });
  return await _delete(axios, pathUrl, params);
};
//#endregion

//#region LIST
export const listCoupons = async params => {
  const { currentPage, offset, filters } = params
  const pathUrl = `${endpoints.API_MANAGER}/coupons/list?currentPage=${currentPage}&offset=${offset}`;
  const filtersString = encodeURI(JSON.stringify(filters))
  const finalPathUrl = filters ? pathUrl + '&filters=' + filtersString : pathUrl
  const axios = interceptor({ xApiKey: keys.API_MANAGER_KEY });

  return await _get({ axios, pathUrl: finalPathUrl });
};
//#endregion
export default ({
  SECTION                               : 'answer',
  MODAL_EXAM_HISTORY                    : 'modalExameHistory',
  MODAL_EXAM_HISTORY_GENERAL            : 'modalExameHistoryGeneral',
  MODAL_HISTORY_INPUT_NUMBER            : 'modal:history:input:number',
  MODAL_HISTORY_INPUT_SCORES            : 'modal:history:input:scores',
  MODAL_HISTORY_INPUT_RADIO             : 'modal:history:input:radio',
  MODAL_HISTORY_INPUT_CHECKBOX_GROUP    : 'modal:history:input:checkbox_group',
  MODAL_HISTORY_INPUT_CHECK_ONLY        : 'modal:history:input:checkbox_group',

  FORMS_ANSWER_SET_NAME_CONTEXT                     : 'FORMS_ANSWER_SET_NAME_CONTEXT',

  FORMS_ANSWER_SET_FORM_BUILD                       : 'FORMS_ANSWER_SET_FORM_BUILD',

  FORMS_ANSWER_GET_FORM_BY_ID_FETCH_REQUEST         : 'FORMS_ANSWER_GET_FORM_BY_ID_FETCH_REQUEST',
  FORMS_ANSWER_GET_FORM_BY_ID_FETCH_SUCCESS         : 'FORMS_ANSWER_GET_FORM_BY_ID_FETCH_SUCCESS',
  FORMS_ANSWER_GET_FORM_BY_ID_FETCH_ERROR           : 'FORMS_ANSWER_GET_FORM_BY_ID_FETCH_ERROR',
  
  FORMS_ANSWER_UPDATE_FIELD_BY_ID                   : 'FORMS_ANSWER_UPDATE_FIELD_BY_ID',
  FORMS_ANSWER_UPDATE_FIELD_PATIENT_BY_ID           : 'FORMS_ANSWER_UPDATE_FIELD_PATIENT_BY_ID',

  FORMS_ANSWER_SAVE_FORMS                           : 'FORMS_ANSWER_SAVE_FORMS',

  FORMS_ANSWER_GET_SEARCH_FETCH_REQUEST             : 'FORMS_ANSWER_GET_SEARCH_FETCH_REQUEST',
  FORMS_ANSWER_GET_SEARCH_FETCH_SUCCESS             : 'FORMS_ANSWER_GET_SEARCH_FETCH_SUCCESS',
  FORMS_ANSWER_GET_SEARCH_FETCH_ERROR               : 'FORMS_ANSWER_GET_SEARCH_FETCH_ERROR',
  FORMS_ANSWER_SET_RESET_SEARCH                     : 'FORMS_ANSWER_SET_RESET_SEARCH',

  FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_REQUEST    : 'FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_REQUEST',
  FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_SUCCESS    : 'FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_SUCCESS',
  FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_ERROR      : 'FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_ERROR',

  FORMS_ANSWER_CREATE_PATIENT_FETCH_REQUEST         : 'FORMS_ANSWER_CREATE_PATIENT_FETCH_REQUEST',
  FORMS_ANSWER_CREATE_PATIENT_FETCH_SUCCESS         : 'FORMS_ANSWER_CREATE_PATIENT_FETCH_SUCCESS',
  FORMS_ANSWER_CREATE_PATIENT_FETCH_ERROR           : 'FORMS_ANSWER_CREATE_PATIENT_FETCH_ERROR',

  FORMS_ANSWER_EXAM_FETCH_REQUEST                   : 'FORMS_ANSWER_EXAM_FETCH_REQUEST',
  FORMS_ANSWER_EXAM_FETCH_SUCCESS                   : 'FORMS_ANSWER_EXAM_FETCH_SUCCESS',
  FORMS_ANSWER_EXAM_FETCH_ERROR                     : 'FORMS_ANSWER_EXAM_FETCH_ERROR',
  FORMS_ANSWER_SET_SEARCH_EXAM_RESET	              : 'FORMS_ANSWER_SET_SEARCH_EXAM_RESET',

  FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_REQUEST	      : 'FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_REQUEST',
  FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_SUCCESS	      : 'FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_SUCCESS',
  FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_ERROR	        : 'FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_ERROR',

  FORMS_ANSWER_SET_ERROR_FIELD_BY_ID                : 'FORMS_ANSWER_SET_ERROR_FIELD_BY_ID',
  FORMS_ANSWER_SET_ERROR_FIELD_PATIENT              : 'FORMS_ANSWER_SET_ERROR_FIELD_PATIENT',

  FORMS_ANSWER_ON_CHANGE_REPORT                     : 'FORMS_ANSWER_ON_CHANGE_REPORT',
  FORMS_ANSWER_SET_NEW_REPORT_BY_ID                 : 'FORMS_ANSWER_SET_NEW_REPORT_BY_ID',
  FORMS_ANSWER_SET_REMOVE_REPORT_BY_ID              : 'FORMS_ANSWER_SET_REMOVE_REPORT_BY_ID',

  FORMS_ANSWER_SET_STATUS_BAR_MESSAGE               : 'FORMS_ANSWER_SET_STATUS_BAR_MESSAGE',

  FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_REQUEST       : 'FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_REQUEST',
  FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_SUCCESS       : 'FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_SUCCESS',
  FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_ERROR         : 'FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_ERROR',
  
  FORMS_ANSWER_SET_ENABLE_HOUR_TABLE                : 'FORMS_ANSWER_SET_ENABLE_HOUR_TABLE'
});
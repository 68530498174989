import axios from 'axios';

export default props => {
  const fetch = axios.create();

  fetch.interceptors.request.use(function (config) {
    const auth  = JSON.parse(localStorage.getItem('auth') || "{}");

    if (auth && auth.hasOwnProperty('token'))
      config.headers.common['Authorization'] = `Bearer ${auth.token}`;

    if (props && props.hasOwnProperty('token') && props.token)
      config.headers.common['Authorization'] = `Bearer ${props.token}`;

    if (props && props.hasOwnProperty('account') && props.account >= 0)
      config.headers.common['Account'] = props.account;

    if (props && props.hasOwnProperty('accountId') && props.accountId >= 0)
      config.headers.common['Account'] = props.accountId;

      if (props && props.hasOwnProperty('user') && props.user >= 0)
      config.headers.common['User'] = props.userId;

      if (props && props.hasOwnProperty('userId') && props.userId >= 0)
      config.headers.common['User'] = props.userId;

    if (props && props.hasOwnProperty('workspace') && props.workspace >= 0)
      config.headers.common['Workspace'] = props.workspace;

    if (props && props.hasOwnProperty('workspaceId') && props.workspaceId >= 0)
      config.headers.common['Workspace'] = props.workspaceId;

    if (props && props.hasOwnProperty('formOrigin'))
      config.headers.common['form-origin'] = props.formOrigin;

    if (props && props.hasOwnProperty('medicalRecordsAttendance'))
      config.headers.common['medical-records-attendance'] = props.medicalRecordsAttendance;

    if (props && props.hasOwnProperty('xApiKey'))
      config.headers.common['x-api-key'] = props.xApiKey;

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  return fetch;
};


/**
 * FORM-ORIGIN
 * 1 - NORMAL
 * 2 - PRONTUARIO
*/
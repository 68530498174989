import green from '@material-ui/core/colors/green';

export const styles = theme => ({
  default: {
    backgroundColor: 'rgba(49, 49, 49, 0.71)'
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: '#E15554',
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: '#F79824',
  },
});
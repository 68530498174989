export const APP_FORMS            = process.env.REACT_APP_APP_FORMS;

export const API_AUTH             =  process.env.REACT_APP_API_AUTH;
export const API_ACCOUNT          =  process.env.REACT_APP_API_ACCOUNT;
export const API_FORMS            =  process.env.REACT_APP_API_FORMS;
export const API_MEDICALRECORD    =  process.env.REACT_APP_API_MEDICALRECORD;
export const API_FILES            =  process.env.REACT_APP_API_FILES;
export const REACT_APP            = process.env.REACT_APP_REACT_APP;
export const API_MANAGER          = process.env.REACT_APP_API_MANAGER;
export const API_DOCS             = process.env.REACT_APP_API_DOCS;

/*eslint-disable no-throw-literal*/
import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

const ACCOUNT = 'account';
const GROUP = 'group';
const LIST = 'list';
const UPDATE = 'update';
const DELETE = 'delete';

const _get = async props => {
    const { axios, pathUrl } = props;

    try {
        return (await axios.get(pathUrl)).data;
    } catch (error) {
        if (error && error.response && error.response.status)
            throw {
                status: error.response.status,
                userFeedback: error.response.data.userFeedback,
            };
        throw error;
    }
};

const _post = async (axios, pathUrl, params = {}) => {
    try {
        return (await axios.post(pathUrl, params)).data;
    } catch (error) {
        if (error && error.response && error.response.status)
            throw {
                status: error.response.status,
                userFeedback: error.response.data.userFeedback,
                ...error.response.data
            };
        throw error;
    }
};

const _put = async (axios, pathUrl, params = {}) => {
    try {
        return (await axios.put(pathUrl, params)).data;
    } catch (error) {
        if (error && error.response && error.response.status)
            throw {
                status: error.response.status,
                userFeedback: error.response.data.userFeedback,
            };
        throw error;
    }
};

const _delete = async (axios, pathUrl, params = {}) => {
    try {
        return (await axios.delete(pathUrl, params)).data;
    } catch (error) {
        if (error && error.response && error.response.status)
            throw {
                status: error.response.status,
                userFeedback: error.response.data.userFeedback,
            };
        throw error;
    }
};

//#region GET
export const getGroupAccount = async ({ header }) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${GROUP}/${LIST}`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
};

export const createGroupAccount = async ({ header, params }) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${GROUP}`;
    const axios = interceptor(header);
    return await _post(axios, pathUrl, params);
}

export const updateGroupAccount = async ({ header, params }) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${GROUP}/${UPDATE}/${params.id}`;
    const axios = interceptor(header);
    return await _put(axios, pathUrl, params);
}

export const deleteGroupAccount = async ({ header, params }) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${GROUP}/${DELETE}/${params.id}`;
    const axios = interceptor(header);
    return await _delete(axios, pathUrl, params);
}
import { createSelector } from 'reselect';

//#region INITIAL_STATE
const INITIAL_STATE = {
};
//#endregion

//#region Actions
const types = {
  MODAL_OPENED            : 'MODAL_OPENED',
  MODAL_CLOSED            : 'MODAL_CLOSED',
  MODAL_SET_MANAGER       : 'MODAL_SET_MANAGER',
  MODAL_SET_BY_NAME_PROPS : 'MODAL_SET_BY_NAME_PROPS'
};
//#endregion

//#region Action Creators
export const onOpen                   = data  => ({type: types.MODAL_OPENED, payload: data });
export const onClose                  = data  => ({type: types.MODAL_CLOSED, payload: data });
export const setModal                 = data  => ({type: types.MODAL_SET_MANAGER, payload: data });
export const setPropsModalByNameProps = data  => ({type: types.MODAL_SET_BY_NAME_PROPS, payload: data });

export const actions = {
  onOpen, onClose, setModal, setPropsModalByNameProps
}
//#endregion

//#region Selectors
export const selectors = ({
  getModalByName: (state, nameModal) => state.modalState?.[nameModal] || {},

  getModalPropsByName: (state, nameModal) => state.modal?.[nameModal] || {},

  getIsDone : createSelector(
    (state, nameModal) => /* state.modal ? ( */state.modal[nameModal] ? state.modal[nameModal].done : false/* ) : false */,
    state => state
  )
});
//#endregion

//#region Reducers
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.MODAL_OPENED: {
      const {modal, ...rest} = action.payload;
      return ({
        ...state,
        [modal]: {
          ...state[modal],
          done: true,
          ...rest
        }
      });
    }
    case types.MODAL_CLOSED: {
      const {modal, ...rest} = action.payload;
      return ({
        ...state,
        [modal]: {
          ...state[modal],
          done: false,
          ...rest
        }
      });
    }
    case types.MODAL_SET_MANAGER: {
      return ({
        ...state,
        ...action.payload
      });
    }
    case types.MODAL_SET_BY_NAME_PROPS: {
      const {modal, ...rest} = action.payload;
      return ({
        ...state,
        [modal]: {
          ...state[modal],
          ...rest
        }
      });
    }
    default: return state;
  }
};
//#endregion
/*eslint-disable no-throw-literal*/

/**
 * @description Metodo HTTP GET
 * @param {Object} axios instancia do AXIOS
 * @param {String} pathUrl endpoint a ser usado
 * @param {String} responseType tipo de resposta
 */
export const get = async (axios, pathUrl, config = {}) => {
  try {
    return (await axios.get(pathUrl, config)).data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

/**
 * @description Metodo HTTP POST
 * @param {Object} axios instancia do AXIOS
 * @param {String} pathUrl endpoint a ser usado
 * @param {Object} params objeto que irá no body da requisição
 * @param {Object} config objeto de configuração da requisição --> consultar a documentação para mais detalhes https://github.com/axios/axios#request-config
 */
export const post = async (axios, pathUrl, params = {}, config = {}) => {
  try {
    return (await axios.post(pathUrl, params, config)).data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

/**
 * @description Metodo HTTP PUT
 * @param {Object} axios instancia do AXIOS
 * @param {String} pathUrl endpoint a ser usado
 * @param {Object} params objeto que irá no body da requisição
 */
export const put = async (axios, pathUrl, params = {}) => {
  try {
    return (await axios.put(pathUrl, params)).data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

/**
 * @description Metodo HTTP DELETE
 * @param {Object} axios instancia do AXIOS
 * @param {String} pathUrl endpoint a ser usado
 * @param {Object} params objeto que irá no body da requisição
 */
export const del = async (axios, pathUrl, params = {}) => {
  try {
    return (await axios.delete(pathUrl, {data: params})).data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};
import * as yup from 'yup'
import moment from 'moment'

export const CouponSchema = yup.object().shape({
  name: yup
    .string()
    .required('Campo nome é obrigatório')
    .trim()
    .max(90, 'O número máximo de caracteres é 90')
    .matches('^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$', 'Nome inválido'),
  description: yup
    .string()
    .max(255, 'Descrição deve ter no máximo 255 caracteres'),
  code: yup
    .string()
    .required('Campo código é obrigatório')
    .max(10, 'Código deve ser de no máximo 10 caracteres'),
  value: yup
    .string()
    .required('Campo desconto em reais é obrigatório')
    .test('minimalValue', 'Valor mínimo deve ser 1', function(val) {
      const { createError } = this
      const value = val ? parseInt(val.replace(/[^\d]+/g, '')) : 0
      if (value === 0) return createError('Desconto em reais não pode ser menor que 1')
      return value
    }),
  recurrence: yup
    .string()
    .required('Campo recorrência é obrigatório')
    .test('minimalValue', 'Valor mínimo deve ser 1', function(val) {
      const { createError } = this
      const value = val ? parseInt(val.replace(/[^\d]+/g, '')) : 0
      if (value === 0) return createError('Recorrência não pode ser menor que 1')
      return value
    }),
  maximumByAccount: yup
    .string()
    .required('Máximo por conta é obrigatório')
    .test('minimalValue', 'Valor mínimo deve ser 1', function(val) {
      const { createError } = this
      const value = val ? parseInt(val.replace(/[^\d]+/g, '')) : 0
      if (value === 0) return createError('Máximo por conta não pode ser menor que 1')
      return value
    }),
  stock: yup
    .string()
    .required('Quantidade disponível é obrigatório')
    .test('minimalValue', 'Valor mínimo deve ser 1', function(val) {
      const { createError } = this
      const value = val ? parseInt(val.replace(/[^\d]+/g, '')) : 0
      if (value === 0) return createError('Quantidade disponível não pode ser menor que 1')
      return value
    }),
  startAt: yup
    .date()
    .required('Campo data de início é obrigatório')
    .min(new Date().toDateString(), 'Data deve ser maior ou igual a hoje'),
    // .min(moment().format('DD/MM/YYYY'), 'Data deve ser maior ou igual a hoje'),
  endAt: yup
    .date()
    .required('Campo data de fim é obrigatório'),
  isActive: yup
    .boolean()
    .required('Campo ativo é obrigatório'),
  isBasePlan: yup
    .boolean()
    .required('Campo Cupom de Plano Base é obrigatório'),
})

// export const BillingDataSchema = yup.object().shape({
//   name: yup
//     .string()
//     .required('Campo nome é obrigatório')
//     .trim()
//     .max(60, 'O número máximo de caracteres é 60')
//     .matches('^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$', 'Nome inválido'),
//   email: yup
//     .string()
//     .required('Campo e-mail é obrigatório')
//     .email('E-mail inválido'),
//   useCnpj: yup.boolean(),
//   cpf: yup.string().when('useCnpj', {
//     is: false,
//     then: yup
//       .string()
//       .required('Campo CPF é obrigatório')
//       .test('cpf', 'CPF inválido', testCpf),
//     otherwise: null,
//   }),
//   cnpj: yup.string().when('useCnpj', {
//     is: true,
//     then: yup
//       .string()
//       .required('Campo CNPJ é obrigatório')
//       .test('cnpj', 'CNPJ inválido', testCnpj),
//     otherwise: null,
//   }),
//   corporateName: yup.string().when('useCnpj', {
//     is: true,
//     then: yup.string().required('Campo razão social é obrigatório'),
//     otherwise: null,
//   }),
//   zipcode: yup
//     .string()
//     .required('Campo CEP é obrigatório')
//     .test('testCep', 'CEP inválido', testCep),
//   address: yup.string().required('Campo endereço é obrigatório'),
//   number: yup.string().required('Campo número é obrigatório'),
//   neighborhood: yup.string().required('Campo bairro é obrigatório'),
//   state: yup.string().required('Campo estado é obrigatório'),
//   city: yup.string().required('Campo cidade é obrigatório'),
// })

// export const PaymentSchema = yup.object().shape({
//   main: yup.string(),
//   cpf: yup.string(),
//   name: yup
//     .string()
//     .required('Campo nome é obrigatório')
//     .trim()
//     .max(60, 'O número máximo de caracteres é 60')
//     .matches('^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$', 'Nome inválido'),
//   number: yup
//     .string()
//     .required('Campo número é obrigatório')
//     .length(19, 'Preencha todos os números do cartão')
//     .test('cardNumber', '', testCardNumber),
//   expiry: yup
//     .string()
//     .required('Campo data de expiração é obrigatório')
//     .test('expiry', '', testExpiry),
//   cvc: yup
//     .string()
//     .required('Código de verificação é obrigatório')
//     .test('expiry', '', testCvc),
// })

// export const AccountDefinitionsSchema = yup.object().shape({
//   accountName: yup.string().required('Campo nome do negócio é obrigatório'),
// })

// export const NewPasswordSchema = yup.object().shape({
//   password: yup
//     .string()
//     .required('Campo senha é obrigatório')
//     .min(6, 'Senha deve ter pelo menos 6 caracteres')
//     .test('password', '', testPassword),
//   password2: yup
//     .string()
//     .required('Campo repita senha é obrigatório')
//     .oneOf([yup.ref('password'), null], 'Senhas não correspondem'),
// })

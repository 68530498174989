import initialStateMedicalRecordFields        from '@Components/Manager/component/Fields/config/initialState';
import * as commonReducerMedicalRecordFields  from '@Components/Manager/component/Fields/config/reducerGenerator';
import initialStateConfigurationMedicalRecordForms       from '@Components/Manager/component/Forms/config/initialState';
import * as commonReducerConfigurationMedicalRecordForms from '@Components/Manager/component/Forms/config/reducerGenerator';

const INITIAL_STATE = ({
  medicalRecord: {
    fields: {...initialStateMedicalRecordFields},
    forms: {...initialStateConfigurationMedicalRecordForms},
  }
});

export default (state = INITIAL_STATE, action) => {
  if (typeof commonReducerMedicalRecordFields[action.type] === 'function') {
    return commonReducerMedicalRecordFields[action.type](state, action);
  }
  else if (typeof commonReducerConfigurationMedicalRecordForms[action.type] === 'function') {
    return commonReducerConfigurationMedicalRecordForms[action.type](state, action);
  }
  else {
    return state;
  }
};
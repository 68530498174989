import React from "react";
import CryptoJS from "crypto-js";
import vmasker from "vanilla-masker";
import moment from "moment";
import isNumber from "lodash/isNumber";
import * as validators from "./validations";
import { toastr } from "react-redux-toastr";
import systemConfigs from "@Constants/systemConfigs";
import dataTypes from "@Constants/typeFields";

const SECRET_KEY = "Pb8mrfyPHGLUkNSM51L6NRRi7hIMGQKC";

export const isEmptyObject = (data) =>
  Object.keys(data).length === 0 && data.constructor === Object;
export const maxLength = (max) => (value) =>
  value && value.length > max
    ? `Deve ter ${max} caracteres ou menos!`
    : undefined;
export const minLength = (min) => (value) =>
  value && value.length < min
    ? `Deve ter ${min} caracteres ou mais!`
    : undefined;
export const minValue = (min) => (value) =>
  value && value.length < min ? `Deve ter pelo menos ${min}` : undefined;
export const exact2Caracter = (value) =>
  value && value.length !== 2 ? `Deve ter 2 caracteres!` : undefined;
export const number = (value) =>
  value && isNaN(Number(value)) ? "Deve ser um número!" : undefined;
export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Endereço de email inválido"
    : undefined;
export const tooOld = (value) =>
  value && value > 65 ? "Você pode ser muito velho para isso!" : undefined;
export const aol = (value) =>
  value && /.+@aol\.com/.test(value)
    ? "Sério? Você ainda usa a AOL para o seu email?"
    : undefined;
export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? "Apenas caracteres alfanuméricos!"
    : undefined;
export const required = (value) =>
  value ? undefined : "Este Campo é obrigatório!";
export const requiredValue = (value) =>
  value > 0 ? undefined : "Este Campo é obrigatório!";
export const maxLength15 = maxLength(15);
export const minLength6 = minLength(6);
export const minValue18 = minValue(18);
export const minValue6 = minValue(6);

export const maskOnlyNumber = (value) => vmasker.toNumber(value);
export const maskDecimalNumber = (value) => vmasker.toMoney(value); //999.999,00
export const maskDateAndHour = (value) =>
  vmasker.toPattern(value, "99/99/9999 99:99");
export const maskDateOnly = (value) => vmasker.toPattern(value, "99/99/9999");
export const maskHourOnly = (value) => vmasker.toPattern(value, "99:99");

export const formatEditorTag = (content) =>
  content.replace(/\[\[/g, "@(").replace(/\]\]/g, ")@");
export const formatServerTag = (content) =>
  content.replace(/@\(/g, "[[").replace(/\)@/g, "]]");

export const getTokenStorage = () => {
  const auth = JSON.parse(localStorage.getItem("auth") || "{}");
  // Do something before request is sent
  if (auth && auth.hasOwnProperty("token")) {
    return `Bearer ${auth.token}`;
  }

  return null;
};

export const sanitizeSearch = (item, value) => {
  const sanitizedSearch = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const sanitizedName = item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const regex = new RegExp(`^(.*?(${sanitizedSearch})[^$]*)$`, 'i');

  return regex.test(sanitizedName);
};

export const sanitizeSearchName = (item, value) => {
  const sanitizedSearch = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const sanitizedName = item.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const regex = new RegExp(`^(.*?(${sanitizedSearch})[^$]*)$`, 'i');

  return regex.test(sanitizedName);
};

export const unformat = (value) => {
  if (value && value !== "") return value.replace(/[/.:-]/g, "");
  return null;
};

export const formatReal = (number) => {
  if (typeof number !== "number") return 0;

  // Create our number formatter.
  let formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });

  return formatter.format(number);
};

export const formatFloat = (number) => {
  try {
    if (`${number}`.indexOf(".") === -1) return number;

    let numberInteger = `${number}`.split(".")[0];
    let temp = `${number}`.split(".")[1];
    temp = temp.slice(0, 2);

    return parseFloat(`${numberInteger}.${temp}`);
  } catch (exception) {
    // console.log('exception: ', exception);
    return null;
  }
};

export const removedAccent = (key) => {
  if (!key || !key.length) return key;

  return key.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //this remove accents
};

export const encrypt = (dataString) => {
  if (!dataString) return;

  const cypherText = CryptoJS.AES.encrypt(dataString, SECRET_KEY);
  let newCypherText = cypherText.toString().replace(/\//g, "encrypt");
  return newCypherText;
};

export const decrypt = (dataString) => {
  if (!dataString) return;
  let newCypherText = dataString.replace(/encrypt/g, "/");
  const bytes = CryptoJS.AES.decrypt(newCypherText, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

/**
 * @description Função que ordena em ordem ASC | DESC
 * @param {object} object recebe um objeto qualuqer
 * @param {string} type atributo do objeto
 * @param {string} order asc | desc
 * @returns {object}
 */
export const orderByObject = (object, type, order) => {
  if (!type || typeof type !== "string") {
    console.warn(
      "Warning!",
      "type is undefined or not is string, please check the references"
    );
    return object;
  }

  if (order === "desc")
    return object.sort((a, b) =>
      b[type] < a[type] ? -1 : b[type] === a[type] ? 0 : 1
    );

  return object.sort((a, b) =>
    a[type] < b[type] ? -1 : a[type] === b[type] ? 0 : 1
  );
};

/**
 * @description Função que retorna o objeto que não contem o mesmo atributo
 * @param {object} list objeto qualquer
 * @param {string} type atributo do objeto
 * @returns {object}
 */
export const distinctObject = (list, type) => {
  const unique = {};
  const distinct = [];

  list.map((item) => {
    if (typeof unique[item[type]] === "undefined") {
      distinct.push(item);
    }

    return (unique[item[type]] = 0);
  });

  return distinct;
};

/**
 * @description Função que retorna o valor não duplicados de um objeto
 * @param {object} list objeto qualquer
 * @param {string} type atributo do objeto
 * @returns {object}
 */
export const distinct = (list, type) => {
  const unique = {};
  const distinct = [];

  list.map((item) => {
    if (typeof unique[item[type]] === "undefined") {
      distinct.push(item[type]);
    }

    return (unique[item[type]] = 0);
  });

  return distinct;
};

/**
 * @description Função que retornar as 2 letras do nome informado
 * @param {string} string recebe uma cadeia de caracteres
 * @returns {string}
 */
export const getNameInitials = (string) => {
  let newString = string.split(" ");
  let anwser = "";

  if (newString.length > 1) {
    let firstName = newString[0].toUpperCase().substr(0, 1);
    let lastName = newString[newString.length - 1].toUpperCase().substr(0, 1);
    anwser = `${firstName}${lastName}`;
  } else {
    newString = newString[0].split("");
    anwser = `${newString[0].toUpperCase().substr(0, 1)}${newString[1]
      .toUpperCase()
      .substr(0, 1)}`;
  }

  return anwser;
};

/**
 * @description Gerar UUID v4
 */
export const generateUUID = () => {
  // http://www.ietf.org/rfc/rfc4122.txt
  const s = [];
  const hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  const uuid = s.join("");
  return uuid;
};

export const getAllElementsWithAttributeByEditor = () => {
  let matchingElements = [];
  const allElements = document.querySelectorAll('[data-block="true"]');

  for (let i = 0, n = allElements.length; i < n; i++) {
    if (allElements[i].getAttribute("data-block") !== null) {
      // Element exists with attribute. Add to array.
      matchingElements.push(allElements[i]);
    }
  }
  return matchingElements;
};

export const getElement = (conditionKey, nodeElements = []) => {
  let element = {};

  // eslint-disable-next-line
  nodeElements.map((item, key) => {
    if (item) {
      let offSetKey = item.dataset.offsetKey;
      let setKey = offSetKey.length ? offSetKey.split("-") : [];

      if (setKey[0] === conditionKey) element = item;
    }
  });

  return element;
};

export const normalizeCPF = (value) => {
  if (!value) return value;

  const onlyNumbers = value.replace(/[^\d]/g, "");
  const numLength = onlyNumbers.length;

  if (numLength <= 3) return onlyNumbers;
  else if (numLength <= 6)
    return `${onlyNumbers.slice(0, 3)}.${onlyNumbers.slice(3, 6)}`;
  else if (numLength <= 9)
    return `${onlyNumbers.slice(0, 3)}.${onlyNumbers.slice(
      3,
      6
    )}.${onlyNumbers.slice(6, 9)}`;
  else
    return `${onlyNumbers.slice(0, 3)}.${onlyNumbers.slice(
      3,
      6
    )}.${onlyNumbers.slice(6, 9)}-${onlyNumbers.slice(9, 11)}`;
};

export const normalizeCNPJ = (value) => {
  if (!value) return value;

  const onlyNumbers = value.replace(/[^\d]/g, "");
  const numLength = onlyNumbers.length;

  if (numLength <= 2) return onlyNumbers;
  else if (numLength <= 5)
    return `${onlyNumbers.slice(0, 2)}.${onlyNumbers.slice(2, 5)}`;
  else if (numLength <= 8)
    return `${onlyNumbers.slice(0, 2)}.${onlyNumbers.slice(
      2,
      5
    )}.${onlyNumbers.slice(5, 8)}`;
  else if (numLength <= 12)
    return `${onlyNumbers.slice(0, 2)}.${onlyNumbers.slice(
      2,
      5
    )}.${onlyNumbers.slice(5, 8)}/${onlyNumbers.slice(8, 12)}`;
  else
    return `${onlyNumbers.slice(0, 2)}.${onlyNumbers.slice(
      2,
      5
    )}.${onlyNumbers.slice(5, 8)}/${onlyNumbers.slice(
      8,
      12
    )}-${onlyNumbers.slice(12, 14)}`;
};

export const normalizePhone = (value) => {
  if (!value) return value;

  const onlyNumbers = value.replace(/[^\d]/g, "");
  const numLength = onlyNumbers.length;

  if (numLength <= 2) return `(${onlyNumbers.slice(0, 2)}`;
  else if (numLength <= 6)
    return `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(2, 6)}`;
  else if (numLength <= 10)
    return `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(
      2,
      6
    )}-${onlyNumbers.slice(6, 10)}`;
  else
    return `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(
      2,
      7
    )}-${onlyNumbers.slice(7, 11)}`;
};

export const normalizeData = (value) => {
  if (!value) return value;

  const onlyNumbers = value.replace(/[^\d]/g, "");
  const numLength = onlyNumbers.length;

  if (numLength <= 2) return onlyNumbers;
  else if (numLength <= 4)
    return `${onlyNumbers.slice(0, 2)}/${onlyNumbers.slice(2, 4)}`;
  else
    return `${onlyNumbers.slice(0, 2)}/${onlyNumbers.slice(
      2,
      4
    )}/${onlyNumbers.slice(4, 8)}`;
};

export const normalizeHour = (value) => {
  if (!value) return value;

  const onlyNumbers = value.toString().replace(/[^\d]/g, "");
  const numLength = onlyNumbers.length;

  if (numLength <= 2) return onlyNumbers;
  else if (numLength <= 4)
    return `${onlyNumbers.slice(0, 2)}:${onlyNumbers.slice(2, 4)}`;
  else
    return `${onlyNumbers.slice(0, 2)}:${onlyNumbers.slice(
      2,
      4
    )}:${onlyNumbers.slice(4, 6)}`;
};

export const normalizeCEP = (value) => {
  if (!value) return value;

  const onlyNumbers = value.replace(/[^\d]/g, "");
  const numLength = onlyNumbers.length;

  if (numLength <= 5) return onlyNumbers;
  else return `${onlyNumbers.slice(0, 5)}-${onlyNumbers.slice(5, 8)}`;
};

export const validateCPF = (value) => {
  if (!value) return "Por favor digite um CPF válido!";

  // Removing special characters from value
  value = value.replace(/[^\d]/g, "");
  // Checking value to have 11 digits only
  if (value.length !== 11) return "Por favor digite um CPF válido!";

  let sum = 0;
  let firstCN = 0;
  let secondCN = 0;
  let i = 0;

  firstCN = parseInt(value.substring(9, 10), 10);
  secondCN = parseInt(value.substring(10, 11), 10);

  let checkResult = (sum, cn) => {
    let result = (sum * 10) % 11;
    if (result === 10 || result === 11) {
      result = 0;
    }
    return result === cn;
  };

  // Checking for dump data
  if (
    value === "" ||
    value === "00000000000" ||
    value === "11111111111" ||
    value === "22222222222" ||
    value === "33333333333" ||
    value === "44444444444" ||
    value === "55555555555" ||
    value === "66666666666" ||
    value === "77777777777" ||
    value === "88888888888" ||
    value === "99999999999"
  ) {
    return "Por favor digite um CPF válido!";
  }

  // Step 1 - using first Check Number:
  for (i = 1; i <= 9; i++) {
    sum = sum + parseInt(value.substring(i - 1, i), 10) * (11 - i);
  }

  // If first Check Number (CN) is valid, move to Step 2 - using second Check Number:
  if (!checkResult(sum, firstCN)) return "Por favor digite um CPF válido!";

  sum = 0;
  for (i = 1; i <= 10; i++) {
    sum = sum + parseInt(value.substring(i - 1, i), 10) * (12 - i);
  }

  if (!checkResult(sum, secondCN)) return "Por favor digite um CPF válido!";

  return undefined;
};

export const validateFormatDate = (value) => {
  if (!value) return value;

  value = value.replace(/[^\d]/g, "");

  if (value.length !== 8) return "Por favor digite uma data válida!";
};

export const validateFormatCEP = (value) => {
  if (!value) return value;

  value = value.replace(/[^\d]/g, "");

  if (value.length !== 8) return "Por favor digite um cep válido!";
};

export const formatDateAmerican = (value) => {
  if (typeof value !== "string") return value;

  let temp = value.split("/");

  if (temp.length !== 3) return value;

  return temp.reverse().join("-");
};

export const removeMaskOnlyNumber = (value) => {
  if (typeof value !== "string") return value;

  return value.replace(/[^\d]/g, "");
};

export const managerExceptionURI = (error, generatorFunction, customMsg) => {
  if (customMsg) return generatorFunction({ message: customMsg });

  if (error) {
    if (typeof error.status === "number")
      return generatorFunction({ message: error.userFeedback });
  }

  return generatorFunction({
    message: "Verifique a conexão da sua internet e tente novamente!",
  });
};

export const managerExceptionURIMessage = (props, config) => {
  const { error, customMsg } = props;
  const { toastrOptions } = config || {};
  let { title, message, options } = toastrOptions || {};

  title = title || "Atenção";
  options = options || {};

  if (customMsg) {
    message = message || customMsg;
    toastr.warning(title, message, options);
    return;
  }

  if (error) {
    if (typeof error.status === "number") {
      message = message || error.userFeedback;
      toastr.warning(title, message, options);
      return;
    }
  }

  message = message || "Verifique a conexão da sua internet e tente novamente!";
  toastr.warning(title, message, options);
};

export const parseHorizontalTableToVertical = (table) => {
  const horizontal = { ...table };

  const vertical = {
    rows: [],
  };

  if (horizontal.tableModelId) vertical.tableModelId = horizontal.tableModelId;
  if (horizontal.tableModelVersionId)
    vertical.tableModelVersionId = horizontal.tableModelVersionId;
  if (horizontal.versionTag) vertical.versionTag = horizontal.versionTag;

  for (const column of horizontal.columns) {
    const verticalRow = {
      dataType: column.dataType,
      order: column.order,
      name: column.name,
      size: column.size,
      columns: [],
    };

    let isTmp = false;

    if (column.tableModelVersionColumnId) {
      verticalRow.tableModelVersionRowId = column.tableModelVersionColumnId;
    } else if (column.tmpHash) {
      verticalRow.tmpHash = column.tmpHash;
      isTmp = true;
    }

    for (const row of horizontal.rows) {
      if (isTmp)
        verticalRow.columns.push({
          value: row[column.tmpHash],
          order: row.order,
        });
      else
        verticalRow.columns.push({
          value: row[column.tableModelVersionColumnId],
          order: row.order,
        });
    }

    vertical.rows.push(verticalRow);
  }

  return vertical;
};

export const parseVerticalTableToHorizontal = (table) => {
  const vertical = { ...table };

  const horizontal = {
    columns: [],
    rows: [],
  };

  const indexedRowsByOrder = [];

  if (vertical.tableModelId) horizontal.tableModelId = vertical.tableModelId;
  if (vertical.tableModelVersionId)
    horizontal.tableModelVersionId = vertical.tableModelVersionId;
  if (vertical.versionTag) horizontal.versionTag = vertical.versionTag;

  for (const row of vertical.rows) {
    const horizontalColumn = {
      dataType: row.dataType,
      order: row.order,
      name: row.name,
      size: row.size,
    };

    let isTmp = false;

    if (row.tableModelVersionRowId) {
      horizontalColumn.tableModelVersionColumnId = row.tableModelVersionRowId;
    } else if (row.tmpHash) {
      horizontalColumn.tmpHash = row.tmpHash;
      isTmp = true;
    }

    for (const verticalColumn of row.columns) {
      if (!indexedRowsByOrder[verticalColumn.order]) {
        if (isTmp)
          indexedRowsByOrder[verticalColumn.order] = {
            [row.tmpHash]: verticalColumn.value,
            order: verticalColumn.order,
          };
        else
          indexedRowsByOrder[verticalColumn.order] = {
            [row.tableModelVersionRowId]: verticalColumn.value,
            order: verticalColumn.order,
          };
      } else {
        if (isTmp)
          indexedRowsByOrder[verticalColumn.order] = {
            ...indexedRowsByOrder[verticalColumn.order],
            [row.tmpHash]: verticalColumn.value,
          };
        else
          indexedRowsByOrder[verticalColumn.order] = {
            ...indexedRowsByOrder[verticalColumn.order],
            [row.tableModelVersionRowId]: verticalColumn.value,
          };
      }
    }

    horizontal.columns.push(horizontalColumn);
  }

  for (const indexedRow of indexedRowsByOrder) {
    if (indexedRow !== null && indexedRow !== undefined) {
      horizontal.rows.push(indexedRow);
    }
  }

  return horizontal;
};

export const toggleSelectedIndex = (dataSource, idSource) => {
  const selectedIndex = dataSource.indexOf(idSource);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(dataSource, idSource);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(dataSource.slice(1));
  } else if (selectedIndex === dataSource.length - 1) {
    newSelected = newSelected.concat(dataSource.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      dataSource.slice(0, selectedIndex),
      dataSource.slice(selectedIndex + 1)
    );
  }

  return newSelected;
};

export const intercomUpdate = (loginData) => {
  const { email, intercomUserHash, name, createdAt } = loginData.auth;

  const user = {
    email,
    name,
    user_hash: intercomUserHash,
    platformCore: true,
    platformLocusPrime: false,
    created_at: moment(createdAt).unix(createdAt),
  };

  if (window.Intercom) {
    window.Intercom("boot", { user_data: user });

    window.Intercom("update", {
      last_request_at: parseInt(new Date().getTime() / 1000),
    });
  }
};

export const snackBarManagerExceptionURIMessage = (props) => {
  const { error, customMsg } = props;

  if (customMsg)
    return { message: customMsg, variant: "error", autoHideDuration: 3500 };

  if (error && typeof error.status === "number")
    return {
      message: error.userFeedback,
      variant: "error",
      autoHideDuration: 3500,
    };

  return {
    message: "Verifique a conexão da sua internet e tente novamente!",
    variant: "error",
    autoHideDuration: 3500,
  };
};

export const getPeriodDatetimeDetails = (startDatetime, endDatetime) => {
  const start = moment(startDatetime, "YYYY-MM-DD HH:mm:ss");
  const end = moment(endDatetime, "YYYY-MM-DD HH:mm:ss");

  const yearsDiff = end.diff(start, "years");
  const years = yearsDiff;
  const yearsDuration = moment.duration(yearsDiff, "years");
  const yearsAsMonths = yearsDuration.asMonths();

  const monthsDiff = end.diff(start, "months");
  const months = monthsDiff - yearsAsMonths;
  const monthsDuration = moment.duration(monthsDiff, "months");
  const monthsAsDays = monthsDuration.asDays();

  const daysDiff = end.diff(start, "days");
  let days = daysDiff - monthsAsDays;
  const daysDuration = moment.duration(daysDiff, "days");
  const daysAsHours = daysDuration.asHours();

  const hoursDiff = end.diff(start, "hours");
  const hours = hoursDiff - daysAsHours;
  const hoursDuration = moment.duration(hoursDiff, "hours");
  const hoursAsMinutes = hoursDuration.asMinutes();

  const minutesDiff = end.diff(start, "minutes");
  const minutes = minutesDiff - hoursAsMinutes;
  const minutesDuration = moment.duration(minutesDiff, "minutes");
  const minutesAsSeconds = minutesDuration.asSeconds();

  const secondsDiff = end.diff(start, "seconds");
  const seconds = secondsDiff - minutesAsSeconds;

  if (days < 0) days = 0;

  return { years, months, days, h: hours, m: minutes, s: seconds };
};

export const getPeriodDateDetails = (startDate, endDate) => {
  const start = moment(startDate, "YYYY-MM-DD");
  const end = moment(endDate, "YYYY-MM-DD");

  const yearsDiff = end.diff(start, "years");
  const years = yearsDiff;
  const yearsDuration = moment.duration(yearsDiff, "years");
  const yearsAsMonths = yearsDuration.asMonths();

  const monthsDiff = end.diff(start, "months");
  const months = monthsDiff - yearsAsMonths;
  const monthsDuration = moment.duration(monthsDiff, "months");
  const monthsAsDays = monthsDuration.asDays();

  const daysDiff = end.diff(start, "days");
  const days = daysDiff - monthsAsDays;

  return { years, months, days };
};

export const getPeriodTimeDetails = (startTime, endTime) => {
  const start = moment(startTime, "HH:mm:ss");
  const end = moment(endTime, "HH:mm:ss");

  const hoursDiff = end.diff(start, "hours");
  const hours = hoursDiff;
  const hoursDuration = moment.duration(hoursDiff, "hours");
  const hoursAsMinutes = hoursDuration.asMinutes();

  const minutesDiff = end.diff(start, "minutes");
  const minutes = minutesDiff - hoursAsMinutes;
  const minutesDuration = moment.duration(minutesDiff, "minutes");
  const minutesAsSeconds = minutesDuration.asSeconds();

  const secondsDiff = end.diff(start, "seconds");
  const seconds = secondsDiff - minutesAsSeconds;

  return { h: hours, m: minutes, s: seconds };
};

export const getPeriodHourDetails = (startHour, endHour) => {
  let hour = null;
  let min = null;
  let sec = null;

  if (isNumber(startHour.h) && isNumber(endHour.h) && startHour.h < endHour.h)
    hour = endHour.h - startHour.h;

  if (isNumber(startHour.m) && isNumber(endHour.m) && startHour.m < endHour.m)
    min = endHour.m - startHour.m;

  if (isNumber(startHour.s) && isNumber(endHour.s) && startHour.s < endHour.s)
    sec = endHour.s - startHour.s;

  return { h: hour, m: min, s: sec };
};

export const removeDateZ = (date) => date.replace("Z", "");

export const shouldLoadTimelineAttendance = (attendance, filter) => {
  if (!filter) return true;
  if (filter === "all") return true;
  if (attendance && attendance[filter] && attendance[filter].length)
    return true;
  else if (attendance && attendance.sections) {
    for (const section of attendance.sections) {
      if (section.name === filter) return true;
    }
  }
  return false;
};

export const extractBetweenKeys = (stringValue) => {
  return stringValue ? stringValue.match(/{(.*?)}/g) : null;
};

export const formatIntervalDuration = (intervalDuration) => {
  const { years, months, days, h, m, s } = intervalDuration;

  let postValue = "";

  let openParentheses = false;
  let closeParentheses = false;

  if (years) {
    const yearSufix = years > 1 ? "anos" : "ano";
    postValue = `${postValue} ( ${years} ${yearSufix}`;
    openParentheses = true;
  }

  if (months) {
    const monthSufix = months > 1 ? "meses" : "mês";
    if (!openParentheses) {
      postValue = `${postValue} ( ${months} ${monthSufix}`;
      openParentheses = true;
    } else postValue = `${postValue} e ${months} ${monthSufix}`;
  }

  if (days) {
    const daySufix = days > 1 ? "dias" : "dia";
    if (!openParentheses) {
      postValue = `${postValue} ( ${days} ${daySufix}`;
      openParentheses = true;
    } else postValue = `${postValue} e ${days} ${daySufix}`;
  }

  if (h) {
    const hSufix = h > 1 ? "horas" : "hora";
    if (!openParentheses) {
      postValue = `${postValue} ( ${h} ${hSufix}`;
      openParentheses = true;
    } else postValue = `${postValue} e ${h} ${hSufix}`;
  }

  if (m) {
    const mSufix = m > 1 ? "minutos" : "minuto";
    if (!openParentheses) {
      postValue = `${postValue} ( ${m} ${mSufix}`;
      openParentheses = true;
    } else postValue = `${postValue} e ${m} ${mSufix}`;
  }

  if (s) {
    const sSufix = s > 1 ? "segundos" : "segundo";
    if (!openParentheses) {
      postValue = `${postValue} ( ${s} ${sSufix}`;
      openParentheses = true;
    } else postValue = `${postValue} e ${s} ${sSufix})`;
    closeParentheses = true;
  }

  if (!closeParentheses && openParentheses) postValue = `${postValue} )`;

  return postValue;
};

export const formatTimeIntervalDuration = (intervalDuration) => {
  let value = "";

  if (intervalDuration) {
    const { h, m, s } = intervalDuration;

    let openParentheses = false;
    let closeParentheses = false;

    if (h) {
      const hSufix = h > 1 ? "horas" : "hora";
      value = `${value} ( ${h} ${hSufix}`;
      openParentheses = true;
    }

    if (m) {
      const mSufix = m > 1 ? "minutos" : "minuto";
      if (!openParentheses) {
        value = `${value} ( ${m} ${mSufix}`;
        openParentheses = true;
      } else value = `${value} e ${m} ${mSufix}`;
    }

    if (s) {
      const sSufix = s > 1 ? "segundos" : "segundo";
      if (!openParentheses) {
        value = `${value} ( ${s} ${sSufix}`;
        openParentheses = true;
      } else value = `${value} e ${s} ${sSufix} )`;
      closeParentheses = true;
    }

    if (!closeParentheses && openParentheses) value = `${value} )`;
  }

  return value;
};

export const extractBetweenAt = (stringValue) => {
  // eslint-disable-next-line
  return stringValue ? stringValue.match(/\@(.*?)\@/g) : null;
};

export const createLinkToFile = ({ base64, type, filename, link }) => {
  /*var file = new Blob([base64], { type: 'application/' + type });
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL);*/
  let a = document.createElement("a");
  //a.href = (type == 'xml') ? `data:application/xml,${base64}` : `data:application/${type};base64,${base64}`;*/
  a.href = link;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
 * Maps trough object's array and returns a new array matching valid dataTypes for scores operations
 * @param {Object} fields
 * @returns Object with the dataTypes that are valid for Scores operations
 */
export const validateFieldsScoresDataType = (fields) => {
  let fieldPoints = [];
  let key = 0;
  fields.map((field) => {
    let hasPoints = false;
    switch (field.dataType) {
      case dataTypes.SCORES_TYPE:
      case dataTypes.CHECKBOX_GROUP_TYPE:
      case dataTypes.RADIO_GROUP_TYPE:
      case dataTypes.INTERGER_TYPE:
      case dataTypes.FLOAT_TYPE:
      case dataTypes.DECIMAL_TYPE:
      case dataTypes.SLIDER_TYPE:
        hasPoints = true;
        break;
      case dataTypes.CHECKBOX_TYPE:
        if (field.points != null) hasPoints = true;
        break;
    }
    if (hasPoints) {
      key++;
      fieldPoints = [...fieldPoints, { ...field, key: key }]; //New list of fields that holds points
    }
  });
  return fieldPoints;
};

/**
 * treats decimal separator and decimal places on a given number
 * @param {Number} number number to be treated
 * @param {Integer} decimals amount of decimal places to set
 * @param {Boolean} keepOriginal boolean to keep original separator
 * @returns value with decimal separator and decimal places set
 */
export const setDecimalSeparator = (
  number,
  decimals = null,
  keepOriginal = false
) => {
  if (number === null || number === undefined) return null;
  const value = decimals ? parseFloat(number).toFixed(decimals) : number;
  if (keepOriginal) return value;
  return `${value}`.replace(".", systemConfigs.POINTS_DECIMAL_SEPARATOR);
};

const measurementUnitDisplayStyle = (measurementUnit, measurementUnitClass) => {
  return measurementUnit ? (
    <label className={measurementUnitClass}>{measurementUnit}</label>
  ) : null;
};

export const stringNameAndMeasurementUnit = (
  fieldName,
  measurementUnit,
  measurementUnitClass,
  space = " "
) => {
  return (
    <label>
      {fieldName}
      {space}
      {measurementUnitDisplayStyle(measurementUnit, measurementUnitClass)}
    </label>
  );
};

export const handleDataType = (dataType) => {
  let nameOfDataType = "";

  switch (dataType) {
    case 1:
      nameOfDataType = "Número";
      break;
    case 2:
      nameOfDataType = "Número Flutuante";
      break;
    case 3:
      nameOfDataType = "Número Decimal";
      break;
    case 4:
      nameOfDataType = "Texto";
      break;
    case 5:
      nameOfDataType = "Lista de opções";
      break;
    case 6:
      nameOfDataType = "Seleção Simples";
      break;
    case 7:
      nameOfDataType = "Data e Hora";
      break;
    case 8:
      nameOfDataType = "Data";
      break;
    case 9:
      nameOfDataType = "Hora";
      break;
    case 10:
      nameOfDataType = "Pesquisa";
      break;
    case 11:
      nameOfDataType = "";
      break;
    case 12:
      nameOfDataType = "";
      break;
    case 13:
      nameOfDataType = "";
      break;
    case 14:
      nameOfDataType = "Rótulo de Informação";
      break;
    case 15:
      nameOfDataType = "";
      break;
    case 16:
      nameOfDataType = "Texto Grande";
      break;
    case 17:
      nameOfDataType = "Múltipla Escolha";
      break;
    case 18:
      nameOfDataType = "Período `(Data e Hora)`";
      break;
    case 19:
      nameOfDataType = "Período";
      break;
    case 20:
      nameOfDataType = "Período (Hora)";
      break;
    case 21:
      nameOfDataType = "";
      break;
    case 22:
      nameOfDataType = "Relatório";
      break;
    case 23:
      nameOfDataType = "Escala Numérica";
      break;
    case 24:
      nameOfDataType = "Cálculo";
      break;
    case 26:
      nameOfDataType = "CID";
      break;
    case 27:
      nameOfDataType = "Gráfico";
      break;
    case 28:
      nameOfDataType = 'Mapa corporal';
      break
    case 29:
      nameOfDataType = 'Objetivos';
      break
    default:
      nameOfDataType = "Campo não identificado";
      break;
  }
  return nameOfDataType;
};

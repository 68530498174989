import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const CATEGORY_MODEL = 'category';
const MANAGER = 'manager'

//#region GET
export const getCategory = async ({ header }) => { // Atualizado de getFolder para getCategory
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${CATEGORY_MODEL}`; // Atualizado de FOLDERMODEL para CATEGORY_MODEL
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getCategoryById = async ({ header, folderId }) => { // Atualizado de getFolderById para getCategoryById
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${CATEGORY_MODEL}/${folderId}`; // Atualizado de FOLDERMODEL para CATEGORY_MODEL
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region POST
export const createCategory = async ({ header, params }) => { // Atualizado de createFolder para createCategory
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${CATEGORY_MODEL}`; // Atualizado de FOLDERMODEL para CATEGORY_MODEL
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateCategory = async ({ header, params }) => { // Atualizado de updateFolder para updateCategory
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${CATEGORY_MODEL}`; // Atualizado de FOLDERMODEL para CATEGORY_MODEL
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateCategoryChildren = async ({ header, params }) => { // Atualizado de updateFolderChildren para updateCategoryChildren
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${CATEGORY_MODEL}/categoryChildren`; // Atualizado de FOLDERMODEL para CATEGORY_MODEL
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateCategoryDocument = async ({ header, params }) => { // Atualizado de updateFolderDocument para updateCategoryDocument
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${CATEGORY_MODEL}/categoryDocument`; // Atualizado de FOLDERMODEL para CATEGORY_MODEL
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const moveDocument = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${CATEGORY_MODEL}/moveDocument`; // Atualizado de FOLDERMODEL para CATEGORY_MODEL
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

//#region DELETE
export const deleteCategory = async ({ header, params }) => { // Atualizado de deleteFolder para deleteCategory
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${CATEGORY_MODEL}`; // Atualizado de FOLDERMODEL para CATEGORY_MODEL
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};
//#endregion

import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const CATEGORY_MODEL = 'category';
const MANAGER = 'manager'

//#region GET
export const getCategory = async ({ header }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${CATEGORY_MODEL}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getCategoryById = async ({ header, folderId }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${CATEGORY_MODEL}/${folderId}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region POST
export const createCategory = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${CATEGORY_MODEL}`; 
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateCategory = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${CATEGORY_MODEL}`; 
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateCategoryChildren = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${CATEGORY_MODEL}/categoryChildren`; 
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateCategoryForm= async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${CATEGORY_MODEL}/categoryForm`; 
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const moveForm = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${CATEGORY_MODEL}/moveForm`; 
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

//#region DELETE
export const deleteCategory = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${CATEGORY_MODEL}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};
//#endregion

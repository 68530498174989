const INTERGER_TYPE = 1;
const FLOAT_TYPE = 2;
const DECIMAL_TYPE = 3;
const TEXT_TYPE = 4;
const RADIO_GROUP_TYPE = 5;
const CHECKBOX_TYPE = 6;
const DATETIME_TYPE = 7;
const DATE_TYPE = 8;
const TIME_TYPE = 9;
const PROFESSIONAL_TYPE = 10;
const PATIENT_TYPE = 11;
const FILE_TYPE = 12;
const IMAGE_TYPE = 13;
const INFO_LABEL_TYPE = 14;
const TABLE_TYPE = 15;
const TEXT_AREA_TYPE = 16;
const CHECKBOX_GROUP_TYPE = 17;
const PERIOD_DATETIME_TYPE = 18;
const PERIOD_DATE_TYPE = 19;
const PERIOD_TIME_TYPE = 20;
const EXAM_TYPE = 21;
const REPORT_TYPE = 22;
const SLIDER_TYPE = 23;
const SCORES_TYPE = 24;
const EXERCISES_TYPE = 25;
const CID_TYPE = 26;
const GRAPHIC_TYPE = 27;
const PAIN_MAP_BODY = 28;
const MARK_TYPE = 29;

export const isSimpleField = (dataType) =>
  dataType === INTERGER_TYPE ||
  dataType === FLOAT_TYPE ||
  dataType === DECIMAL_TYPE ||
  dataType === TEXT_TYPE ||
  dataType === TEXT_AREA_TYPE ||
  dataType === SLIDER_TYPE ||
  dataType === SCORES_TYPE;

export const isNumberField = (dataType) =>
  dataType === INTERGER_TYPE ||
  dataType === FLOAT_TYPE ||
  dataType === DECIMAL_TYPE;

export const isDataFieldDashboard = (dataType) =>
  dataType === INTERGER_TYPE ||
  dataType === FLOAT_TYPE ||
  dataType === DECIMAL_TYPE ||
  dataType === SLIDER_TYPE ||
  dataType === SCORES_TYPE ||
  dataType === RADIO_GROUP_TYPE ||
  dataType === CHECKBOX_GROUP_TYPE ||
  dataType === PAIN_MAP_BODY ||
  dataType === MARK_TYPE;

export const fieldTypeName = (dataType) => {
  switch (dataType) {
    case INTERGER_TYPE:
    case FLOAT_TYPE:
    case DECIMAL_TYPE:
      return "Número";
    case PERIOD_DATETIME_TYPE:
    case TIME_TYPE:
      return "Hora";
    case PERIOD_DATE_TYPE:
    case PERIOD_TIME_TYPE:
      return "Período";
    case TEXT_TYPE:
      return "Texto";
    case RADIO_GROUP_TYPE:
      return "Lista de Opções";
    case CHECKBOX_TYPE:
      return "Seleção Simples";
    case DATETIME_TYPE:
      return "Data e Hora";
    case DATE_TYPE:
      return "Data";
    case PROFESSIONAL_TYPE:
      return "Profissional";
    case PATIENT_TYPE:
      return "Paciente";
    case FILE_TYPE:
      return "Arquivo";
    case IMAGE_TYPE:
      return "Imagem";
    case INFO_LABEL_TYPE:
      return "Rótulo de Informação";
    case TABLE_TYPE:
      return "Tabela";
    case TEXT_AREA_TYPE:
      return "Texto";
    case CHECKBOX_GROUP_TYPE:
      return "Múltipla Escolha";
    case EXAM_TYPE:
      return "Exame";
    case REPORT_TYPE:
      return "Relatório";
    case SLIDER_TYPE:
      return "Escala Numérica";
    case SCORES_TYPE:
      return "Cálculo";
    case CID_TYPE:
      return "CID";
    case GRAPHIC_TYPE:
      return "Gráfico";
    case PAIN_MAP_BODY:
      return 'Mapa Corporal';
    case MARK_TYPE:
      return 'Objetivos'
  }
};

export const isCheckboxField = (dataType) => dataType === CHECKBOX_TYPE;

export const isRadioGroupField = (dataType) => dataType === RADIO_GROUP_TYPE;

export const isDatetimeField = (dataType) => dataType === DATETIME_TYPE;

export const isDateField = (dataType) => dataType === DATE_TYPE;

export const isTimeField = (dataType) => dataType === TIME_TYPE;

export const isProfessionalField = (dataType) => dataType === PROFESSIONAL_TYPE;

export const isCheckboxGroupField = (dataType) =>
  dataType === CHECKBOX_GROUP_TYPE;

export const isPeriodDatetimeField = (dataType) =>
  dataType === PERIOD_DATETIME_TYPE;

export const isPeriodDateField = (dataType) => dataType === PERIOD_DATE_TYPE;

export const isPeriodTimeField = (dataType) => dataType === PERIOD_TIME_TYPE;

export const isReportField = (dataType) => dataType === REPORT_TYPE;

export const isSliderField = (dataType) => dataType === SLIDER_TYPE;

export const isScoresField = (dataType) => dataType === SCORES_TYPE;

export const isGraphicField = (dataType) => dataType === GRAPHIC_TYPE;

export const isMapBodyField = (dataType) => dataType === PAIN_MAP_BODY;

export const isMarkField = (dataType) => dataType === MARK_TYPE;

export default {
  INTERGER_TYPE,
  FLOAT_TYPE,
  DECIMAL_TYPE,
  TEXT_TYPE,
  RADIO_GROUP_TYPE,
  CHECKBOX_TYPE,
  DATETIME_TYPE,
  DATE_TYPE,
  TIME_TYPE,
  PROFESSIONAL_TYPE,
  PATIENT_TYPE,
  FILE_TYPE,
  IMAGE_TYPE,
  INFO_LABEL_TYPE,
  TABLE_TYPE,
  TEXT_AREA_TYPE,
  CHECKBOX_GROUP_TYPE,
  PERIOD_DATETIME_TYPE,
  PERIOD_DATE_TYPE,
  PERIOD_TIME_TYPE,
  EXAM_TYPE,
  REPORT_TYPE,
  SLIDER_TYPE,
  SCORES_TYPE,
  CID_TYPE,
};

import initialState from './initialState';

export const SET_FIELD_DROP_END = (section, state, action) => {
  const {fields, fieldsIds, moduleId} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        fields,
        modules: {
          ...state[section].dynamicForms.modules,
          [moduleId]: {
            ...state[section].dynamicForms.modules[moduleId],
            fieldsIds
          }
        }
      }
    }
  });
};

export const CHANGE_INPUT_SECTIONS = (section, state, action) => {
  const {value, name, idSection} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        sections: {
          ...state[section].dynamicForms.sections,
          [idSection]: {
            ...state[section].dynamicForms.sections[idSection],
            [name]: value
          }
        }
      }
    }
  });
};

export const CHANGE_INPUT_MODULES = (section, state, action) => {
  const {value, name, idModule} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        modules: {
          ...state[section].dynamicForms.modules,
          [idModule]: {
            ...state[section].dynamicForms.modules[idModule],
            [name]: value
          }
        }
      }
    }
  });
};

export const CHANGE_INPUT_HEADER = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        forms: {
          ...state[section].dynamicForms.forms,
          [action.payload.name]: action.payload.value
        }
      }
    }
  });
};

export const CHANGE_INPUT_DATA_FORM = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        forms: {
          ...state[section].dynamicForms.forms,
          [action.payload.name]: action.payload.value
        }
      }
    }
  });
};


export const CHANGE_ATTACH_PATIENT_MODEL = (section, state, action) => {
  const {done} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        forms: {
          ...state[section].dynamicForms.forms,
          attachPatientModel: done
        }
      }
    }
  });
};

export const GET_LIST_FIELDS_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListFields: true,
      }
    }
  });
};

export const GET_LIST_FIELDS_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      isFirstLoading: false,
      fetching: {
        ...state[section].fetching,
        getListFields: false,
      },
      api: {
        ...state[section].api,
        getListFields: action.payload.data
      },
      listFields: {
        ...state[section].listFields,
        ...action.payload.listFields,
        customIdsOriginal: action.payload.listFields.customIds
      }
    }
  });
};

export const GET_LIST_FIELDS_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListFields: false,
      }
    }
  });
};

export const SET_SEARCH_FIELDS_TAB = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      listFields: {
        ...state[section].listFields,
        customIds: action.payload.customIds
      }
    }
  });
};

export const RESET_DYNAMIC_FORMS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        fields: {},
        modules: {},
        sections: {},
        forms: {
          idForm: '',
          name: '',
          description: '',
          attachPatientModel: false,
          sectionsIds: [],
          categoriesIds: [],
        }
      }
    }
  });
};

export const SET_NEW_SECTION = (section, state, action) => {
  const {sections, sectionsIds} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        sections,
        forms: {
          ...state[section].dynamicForms.forms,
          sectionsIds: [...sectionsIds]
        }
      }
    }
  });
};

export const SET_NEW_MODULES = (section, state, action) => {
  const {modules, modulesIds, idSection} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        modules,
        sections: {
          ...state[section].dynamicForms.sections,
          [idSection]: {
            ...state[section].dynamicForms.sections[idSection],
            modulesIds
          }
        }
      }
    }
  });
};

export const SET_NEW_FIELDS_IDS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      listFields: {
        ...state[section].listFields,
        customIds: action.payload.newFieldsIds
      }
    }
  });
};

export const SET_NEW_DYNAMIC_FIELDS = (section, state, action) => {
    return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        modules: {
          ...state[section].dynamicForms.modules,
          [action.payload.moduleId]: {
            ...state[section].dynamicForms.modules[action.payload.moduleId],
            fieldsIds: action.payload.moduleFields
          }
        },
        fields: action.payload.newFieldsObj
      }
    }
  });
};

export const GET_MODEL_BY_ID_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getModelById: true,
      }
    }
  });
};

export const GET_MODEL_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  const {idField, result} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getModelById: false,
      },
      dynamicForms: {
        ...state[section].dynamicForms,
        fields: {
          ...state[section].dynamicForms.fields,
          [idField]: {
            ...state[section].dynamicForms.fields[idField],
            ...result,
            perfomSearch: false
          }
        }
      }
    }
  });
};

export const GET_MODEL_BY_ID_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getModelById: false,
      }
    }
  });
};

export const REMOVED_FIELD = (section, state, action) => {
  const {fields, fieldsIds, idModule} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        fields,
        modules: {
          ...state[section].dynamicForms.modules,
          [idModule]: {
            ...state[section].dynamicForms.modules[idModule],
            fieldsIds
          }
        }
      }
    }
  });
};

export const REMOVED_MODULE = (section, state, action) => {
  const {fields, modules, modulesIds, idSection} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        fields,
        modules,
        sections: {
          ...state[section].dynamicForms.sections,
          [idSection]: {
            ...state[section].dynamicForms.sections[idSection],
            modulesIds: [...modulesIds]
          }
        }
      }
    }
  });
};

export const REMOVED_SECTION = (section, state, action) => {
  const {sections, sectionsIds, modules, fields} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        fields,
        modules,
        sections,
        forms: {
          ...state[section].dynamicForms.forms,
          sectionsIds: [...sectionsIds]
        }
      }
    }
  });
};

export const MODAL_OPEN_VISIBILE = (section, state, action) => {
  const {idModal, idField, field} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      modal: {
        ...state[section].modal,
        [idModal]: {
          ...state[section].modal[idModal],
          visible: true,
          titleFieldId: idField,
          field: {
            ...state[section].modal[idModal].field,
            [idField]: {
              ...field
            }
          }
        }
      }
    }
  });
};

export const MODAL_CLOSE_VISIBILE = (section, state, action) => {
  // const {idModal} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      modal: {
        ...initialState.modal,
      }
    }
  });
};

export const MODAL_CHANGE_INPUT = (section, state, action) => {
  const {field, idField, idModal} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      modal: {
        ...state[section].modal,
        [idModal]: {
          ...state[section].modal[idModal],
          field: {
            ...state[section].modal[idModal].field,
            [idField]: field
          }
        }
      }
    }
  });
};

export const MODAL_CHANGE_INPUT_SIZE = (section, state, action) => {
  const {field, idModal, idField} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      modal: {
        ...state[section].modal,
        [idModal]: {
          ...state[section].modal[idModal],
          field: {
            ...state[section].modal[idModal].field,
            [idField]: field
          }
        }
      }
    }
  });
};

export const MODAL_SAVE_FIELD = (section, state, action) => {
  const {field, idField} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        fields: {
          ...state[section].dynamicForms.fields,
          [idField]: {
            ...state[section].dynamicForms.fields[idField],
            ...field
          }
        }
      }
    }
  });
};

export const SAVE_VERSION_FIELD_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveVersionField: true,
      }
    }
  });
};

export const SAVE_VERSION_FIELD_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveVersionField: false,
      }
    }
  });
};

export const SAVE_VERSION_FIELD_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveVersionField: false,
      }
    }
  });
};

export const SET_FIELD_MODEL_VERSION = (section, state, action) => {
  const {items, fields} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      listFields: {
        ...state[section].listFields,
        items
      },
      dynamicForms: {
        ...state[section].dynamicForms,
        fields
      }
    }
  });
};

export const SAVE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveForm: true,
      }
    }
  });
};

export const SAVE_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveForm: false,
      }
    }
  });
};

export const SAVE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveForm: false,
      },
      error: {
        ...state[section].error,
        done: true
      }
    }
  });
};

export const GET_DRAFT_BY_ID_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getDraftById: true,
      }
    }
  });
};

export const GET_DRAFT_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  const {draftId, formOrigin, hideAttachPatientModel} = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      hideAttachPatientModel,
      formOrigin,
      fetching: {
        ...state[section].fetching,
        getDraftById: false,
      },
      dynamicForms: {
        ...state[section].dynamicForms,
        draftId,
        forms: {
          ...state[section].dynamicForms.forms,
          attachPatientModel: hideAttachPatientModel
        }
      }
    }
  });
};

export const GET_DRAFT_BY_ID_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getDraftById: false,
      }
    }
  });
};

export const SAVE_DRAFT_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveDraft: true,
      }
    }
  });
};

export const SAVE_DRAFT_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveDraft: false,
      }
    }
  });
};

export const SAVE_DRAFT_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveDraft: false,
      }
    }
  });
};

export const SET_BUILD_DRAFT_BY_ID = (section, state, action) => {
  const {draftId, fields, modules, sections, forms} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        draftId,
        fields,
        modules,
        sections,
        forms: {
          ...state[section].dynamicForms.forms,
          ...forms
        }
      }
    }
  });
};

export const SET_UPDATE_FIELD_BY_ID = (section, state, action) => {
  const {idField, field} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      dynamicForms: {
        ...state[section].dynamicForms,
        fields: {
          ...state[section].dynamicForms.fields,
          [idField]: {
            ...state[section].dynamicForms.fields[idField],
            ...field
          }
        }
      }
    }
  });
};

export const FORMS_CREATE_SAVE_NEW_AVATAR_FETCH_REQUEST =  (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveNewAvatar: true,
      }
    }
  });
};

export const FORMS_CREATE_SAVE_NEW_AVATAR_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveNewAvatar: false,
      }
    }
  });
}

export const FORMS_CREATE_SAVE_NEW_AVATAR_DATA = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      avatar: action.payload,
    }
  });
}

export const FORMS_CREATE_SAVE_NEW_AVATAR_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveNewAvatar: false,
      }
    }
  });
}

export const FORMS_CREATE_CREATE_AUTHOR_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        createAuthor: true,
      }
    }
  });
}

export const FORMS_CREATE_CREATE_AUTHOR_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        createAuthor: false,
      }
    }
  });
}

export const FORMS_CREATE_CREATE_AUTHOR_FETCH_ERROR = (section, state, action) => { 
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        createAuthor: false,
      }
    }
  });
}

export const FORMS_CREATE_UPDATE_AUTHOR_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updateAuthor: true,
      }
    }
  });
}

export const FORMS_CREATE_UPDATE_AUTHOR_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updateAuthor: false,
      }
    }
  });
}

export const FORMS_CREATE_UPDATE_AUTHOR_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updateAuthor: false,
      }
    }
  });
}

export const FORMS_CREATE_GET_LIST_AUTHOR_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListAuthor: true,
      }
    }
  });
}

export const FORMS_CREATE_GET_LIST_AUTHOR_FETCH_SUCCESS = (section, state, action) => {
  const { authors } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListAuthor: false,
      },
      authors
    }
  });
}

export const FORMS_CREATE_GET_LIST_AUTHOR_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListAuthor: false,
      }
    }
  });
}
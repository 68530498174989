import { applyMiddleware, combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import thunk from 'redux-thunk';
import multi from 'redux-multi';
// import { createLogger }                     from 'redux-logger';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import reduxMiddleware from 'react-block-ui/lib/reduxMiddleware';
import reduxPromiseMiddleware from 'redux-promise-middleware';

// import rootReducers   from '@App/reducers';
import * as reducers from './ducks';
import * as api from '@App/services';

export const history = createBrowserHistory();

const reducersAll = { router: connectRouter(history), form: formReducer, toastr: toastrReducer, ...reducers };

const rootStore = () => {
    const rootReducer = combineReducers(reducersAll);
    if (process.env.NODE_ENV !== 'production') {
        let store = configureStore({
            reducer: rootReducer,
            middleware: [
                multi,
                thunk.withExtraArgument(api),
                reduxMiddleware,
                reduxPromiseMiddleware(),
                routerMiddleware(history),
              ],
        }
        );

        if (module.hot) {
            module.hot.accept('./ducks', () => {
                const _rootReducer = require('./ducks');
                store.replaceReducer(createRootReducer(history));
            });
        }

        return store;
    } else {
        let store = configureStore({
            reducer: rootReducer,
            middleware: [
                multi,
                thunk.withExtraArgument(api),
                reduxMiddleware,
                reduxPromiseMiddleware(),
                routerMiddleware(history),
              ],
            });

        return store;
    }
};

export default rootStore();

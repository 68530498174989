import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const AUTHOR = 'author'; 
const MANAGER = 'manager';

//#region GET
export const getAuthors = async ({ header }) => { 
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${AUTHOR}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getAuthorById = async ({ header, authorId }) => { 
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${AUTHOR}/${authorId}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region POST
export const createAuthor = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${AUTHOR}`; 
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateAuthor = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${AUTHOR}`; 
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};


//#region DELETE
export const deleteAuthor = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${AUTHOR}`; 
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};
//#endregion

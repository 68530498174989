/*eslint-disable no-throw-literal*/
import axios        from 'axios';
// import interceptor  from './interceptor';

import * as types from '@App/constants/endpoints';

// const _axios = interceptor();

const LOGIN   = 'login';
const AUTH    = 'auth';
const MANAGER = 'manager';
const USER    = 'user';
const AUTO    = 'auto';
const TOKEN   = 'token';

export const signIn = ({email, password}) => {
  const pathUrl = `${types.API_AUTH}/${LOGIN}/${MANAGER}`;
  const params ={ email, password };

  return new Promise((resolve, reject) => {
    axios.post(pathUrl, params).then( response => {
      if (response && response.data && response.data.success) {
        resolve({...response.data.data, token: response.data.token});
      }
      else {
        reject({status: 401, message: 'Login inválido!'});
      }
    }).catch(reject);
  });
};

export const loginAsUser = (userId, token) => {
  return new Promise((resolve, reject) => {
    const pathUrl = `${types.API_AUTH}/${LOGIN}/${MANAGER}/as/${USER}`;

    const options = {
      url:  `${types.API_AUTH}/${LOGIN}/${MANAGER}/as/${USER}`,
      headers: { Authorization: `Bearer ${token}` }
    };

    axios.post(pathUrl, { loginAsUserId: userId }, options).then(response => resolve(response.data)).catch(reject);
  });
};

export const verifyToken = token => {
  return new Promise((resolve, reject) => {
    const options = {
      method: 'POST',
      url: `${types.API_AUTH}/${AUTH}/${MANAGER}`,
      headers: { Authorization: `Bearer ${token}` }
    };

    axios(options).then(response => resolve(response.data)).catch(reject);
  });
};


export const tokenLogin = token => {
  return new Promise((resolve, reject) => {
    const options = {
      method: 'POST',
      url: `${types.API_AUTH}/${LOGIN}/${AUTO}/${TOKEN}`,
      headers: { Authorization: `Bearer ${token}` }
    };

    axios(options).then(response => resolve({...response.data.data, token: response.data.token})).catch(reject);
  });
};
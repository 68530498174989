import typesCreate                    from './Create/constants';
import initialStateCreate             from './Create/initialState';
import * as commonReducerCreate       from './Create/reducerGenerator';

import typesAnswer                    from './Answer/config/constant';
// import initialStateAnswer             from './Answer/config/initialState';
import * as commonReducerAnswer       from './Answer/config/reducerGenerator';

const INITIAL_STATE = ({
  create      : { ...initialStateCreate },
  answer      : {},
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //#region CREATE
    case typesCreate.FORMS_CREATE_SET_FIELD_DROP_END                  : return commonReducerCreate.SET_FIELD_DROP_END(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_SET_SEARCH_FIELDS_TAB               : return commonReducerCreate.SET_SEARCH_FIELDS_TAB(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_CHANGE_INPUT_HEADER                 : return commonReducerCreate.CHANGE_INPUT_HEADER(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_CHANGE_INPUT_SECTIONS               : return commonReducerCreate.CHANGE_INPUT_SECTIONS(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_CHANGE_INPUT_MODULES                : return commonReducerCreate.CHANGE_INPUT_MODULES(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_CHANGE_INPUT_DATA_FORMS             : return commonReducerCreate.CHANGE_INPUT_DATA_FORM(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_CHANGE_ATTACH_PATIENT_MODEL         : return commonReducerCreate.CHANGE_ATTACH_PATIENT_MODEL(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_RESET_DYNAMIC_FORMS                 : return commonReducerCreate.RESET_DYNAMIC_FORMS(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_REMOVED_FIELD                       : return commonReducerCreate.REMOVED_FIELD(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_REMOVED_MODULE                      : return commonReducerCreate.REMOVED_MODULE(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_REMOVED_SECTION                     : return commonReducerCreate.REMOVED_SECTION(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_SET_NEW_SECTION                     : return commonReducerCreate.SET_NEW_SECTION(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_SET_NEW_MODULES                     : return commonReducerCreate.SET_NEW_MODULES(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_SET_NEW_FIELDS_IDS                  : return commonReducerCreate.SET_NEW_FIELDS_IDS(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_SET_NEW_DYNAMIC_FIELDS              : return commonReducerCreate.SET_NEW_DYNAMIC_FIELDS(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_GET_LIST_FIELDS_FETCH_REQUEST       : return commonReducerCreate.GET_LIST_FIELDS_FETCH_REQUEST(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_GET_LIST_FIELDS_FETCH_SUCCESS       : return commonReducerCreate.GET_LIST_FIELDS_FETCH_SUCCESS(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_GET_LIST_FIELDS_FETCH_ERROR         : return commonReducerCreate.GET_LIST_FIELDS_FETCH_ERROR(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_GET_MODEL_BY_ID_FETCH_REQUEST       : return commonReducerCreate.GET_MODEL_BY_ID_FETCH_REQUEST(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_GET_MODEL_BY_ID_FETCH_SUCCESS       : return commonReducerCreate.GET_MODEL_BY_ID_FETCH_SUCCESS(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_GET_MODEL_BY_ID_FETCH_ERROR         : return commonReducerCreate.GET_MODEL_BY_ID_FETCH_ERROR(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_SAVE_VERSION_FIELD_FETCH_REQUEST    : return commonReducerCreate.SAVE_VERSION_FIELD_FETCH_REQUEST(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_SAVE_VERSION_FIELD_FETCH_SUCCESS    : return commonReducerCreate.SAVE_VERSION_FIELD_FETCH_SUCCESS(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_SAVE_VERSION_FIELD_FETCH_ERROR      : return commonReducerCreate.SAVE_VERSION_FIELD_FETCH_ERROR(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_MODAL_CLOSE_VISIBILE                : return commonReducerCreate.MODAL_CLOSE_VISIBILE(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_MODAL_OPEN_VISIBILE                 : return commonReducerCreate.MODAL_OPEN_VISIBILE(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_MODAL_CHANGE_INPUT                  : return commonReducerCreate.MODAL_CHANGE_INPUT(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_MODAL_CHANGE_INPUT_SIZE             : return commonReducerCreate.MODAL_CHANGE_INPUT_SIZE(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_MODAL_SAVE_FIELD                    : return commonReducerCreate.MODAL_SAVE_FIELD(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_SET_FIELD_MODEL_VERSION             : return commonReducerCreate.SET_FIELD_MODEL_VERSION(typesCreate.SECTION, state, action);

    case typesCreate.FORM_CREATE_SAVE_FETCH_REQUEST                   : return commonReducerCreate.SAVE_FETCH_REQUEST(typesCreate.SECTION, state, action);
    case typesCreate.FORM_CREATE_SAVE_FETCH_SUCCESS                   : return commonReducerCreate.SAVE_FETCH_SUCCESS(typesCreate.SECTION, state, action);
    case typesCreate.FORM_CREATE_SAVE_FETCH_ERROR                     : return commonReducerCreate.SAVE_FETCH_ERROR(typesCreate.SECTION, state, action);

    case typesCreate.FORM_CREATE_GET_DRAFT_BY_ID_FETCH_REQUEST        : return commonReducerCreate.GET_DRAFT_BY_ID_FETCH_REQUEST(typesCreate.SECTION, state, action);
    case typesCreate.FORM_CREATE_GET_DRAFT_BY_ID_FETCH_SUCCESS        : return commonReducerCreate.GET_DRAFT_BY_ID_FETCH_SUCCESS(typesCreate.SECTION, state, action);
    case typesCreate.FORM_CREATE_GET_DRAFT_BY_ID_FETCH_ERROR          : return commonReducerCreate.GET_DRAFT_BY_ID_FETCH_ERROR(typesCreate.SECTION, state, action);

    case typesCreate.FORM_CREATE_SAVE_DRAFT_FETCH_REQUEST             : return commonReducerCreate.SAVE_DRAFT_FETCH_REQUEST(typesCreate.SECTION, state, action);
    case typesCreate.FORM_CREATE_SAVE_DRAFT_FETCH_SUCCESS             : return commonReducerCreate.SAVE_DRAFT_FETCH_SUCCESS(typesCreate.SECTION, state, action);
    case typesCreate.FORM_CREATE_SAVE_DRAFT_FETCH_ERROR               : return commonReducerCreate.SAVE_DRAFT_FETCH_ERROR(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_SET_BUILD_DRAFT_BY_ID               : return commonReducerCreate.SET_BUILD_DRAFT_BY_ID(typesCreate.SECTION, state, action)
    
    case typesCreate.FORMS_CREATE_SET_UPDATE_FIELD_BY_ID              : return commonReducerCreate.SET_UPDATE_FIELD_BY_ID(typesCreate.SECTION, state, action);

    case typesCreate.FORMS_CREATE_SAVE_NEW_AVATAR_FETCH_REQUEST                 : return commonReducerCreate.FORMS_CREATE_SAVE_NEW_AVATAR_FETCH_REQUEST(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_SAVE_NEW_AVATAR_FETCH_SUCCESS                 : return commonReducerCreate.FORMS_CREATE_SAVE_NEW_AVATAR_FETCH_SUCCESS(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_SAVE_NEW_AVATAR_DATA                   : return commonReducerCreate.FORMS_CREATE_SAVE_NEW_AVATAR_DATA(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_SAVE_NEW_AVATAR_FETCH_ERROR                   : return commonReducerCreate.FORMS_CREATE_SAVE_NEW_AVATAR_FETCH_ERROR(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_CREATE_AUTHOR_FETCH_REQUEST                   : return commonReducerCreate.FORMS_CREATE_CREATE_AUTHOR_FETCH_REQUEST(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_CREATE_AUTHOR_FETCH_SUCCESS                   : return commonReducerCreate.FORMS_CREATE_CREATE_AUTHOR_FETCH_SUCCESS(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_CREATE_AUTHOR_FETCH_ERROR                   : return commonReducerCreate.FORMS_CREATE_CREATE_AUTHOR_FETCH_ERROR(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_UPDATE_AUTHOR_FETCH_REQUEST                   : return commonReducerCreate.FORMS_CREATE_UPDATE_AUTHOR_FETCH_REQUEST(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_UPDATE_AUTHOR_FETCH_SUCCESS                   : return commonReducerCreate.FORMS_CREATE_UPDATE_AUTHOR_FETCH_SUCCESS(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_UPDATE_AUTHOR_FETCH_ERROR                   : return commonReducerCreate.FORMS_CREATE_UPDATE_AUTHOR_FETCH_ERROR(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_GET_LIST_AUTHOR_FETCH_REQUEST                   : return commonReducerCreate.FORMS_CREATE_GET_LIST_AUTHOR_FETCH_REQUEST(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_GET_LIST_AUTHOR_FETCH_SUCCESS                   : return commonReducerCreate.FORMS_CREATE_GET_LIST_AUTHOR_FETCH_SUCCESS(typesCreate.SECTION, state, action);
    case typesCreate.FORMS_CREATE_GET_LIST_AUTHOR_FETCH_ERROR                   : return commonReducerCreate.FORMS_CREATE_GET_LIST_AUTHOR_FETCH_ERROR(typesCreate.SECTION, state, action);

    //#endregion

    //#region ANSWER
    case typesAnswer.FORMS_ANSWER_SET_NAME_CONTEXT                    : return commonReducerAnswer.FORMS_ANSWER_SET_NAME_CONTEXT(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_GET_FORM_BY_ID_FETCH_REQUEST        : return commonReducerAnswer.FORMS_ANSWER_GET_FORM_BY_ID_FETCH_REQUEST(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_GET_FORM_BY_ID_FETCH_SUCCESS        : return commonReducerAnswer.FORMS_ANSWER_GET_FORM_BY_ID_FETCH_SUCCESS(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_GET_FORM_BY_ID_FETCH_ERROR          : return commonReducerAnswer.FORMS_ANSWER_GET_FORM_BY_ID_FETCH_ERROR(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_SET_FORM_BUILD                      : return commonReducerAnswer.FORMS_ANSWER_SET_FORM_BUILD(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_UPDATE_FIELD_BY_ID                  : return commonReducerAnswer.FORMS_ANSWER_UPDATE_FIELD_BY_ID(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_UPDATE_FIELD_PATIENT_BY_ID          : return commonReducerAnswer.FORMS_ANSWER_UPDATE_FIELD_PATIENT_BY_ID(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_SAVE_FORMS                          : return commonReducerAnswer.FORMS_ANSWER_SAVE_FORMS(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_GET_SEARCH_FETCH_REQUEST            : return commonReducerAnswer.FORMS_ANSWER_GET_SEARCH_FETCH_REQUEST(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_GET_SEARCH_FETCH_SUCCESS            : return commonReducerAnswer.FORMS_ANSWER_GET_SEARCH_FETCH_SUCCESS(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_GET_SEARCH_FETCH_ERROR              : return commonReducerAnswer.FORMS_ANSWER_GET_SEARCH_FETCH_ERROR(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_SET_RESET_SEARCH                    : return commonReducerAnswer.FORMS_ANSWER_SET_RESET_SEARCH(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_REQUEST   : return commonReducerAnswer.FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_REQUEST(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_SUCCESS   : return commonReducerAnswer.FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_SUCCESS(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_ERROR     : return commonReducerAnswer.FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_ERROR(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_CREATE_PATIENT_FETCH_REQUEST        : return commonReducerAnswer.FORMS_ANSWER_CREATE_PATIENT_FETCH_REQUEST(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_CREATE_PATIENT_FETCH_SUCCESS        : return commonReducerAnswer.FORMS_ANSWER_CREATE_PATIENT_FETCH_SUCCESS(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_CREATE_PATIENT_FETCH_ERROR          : return commonReducerAnswer.FORMS_ANSWER_CREATE_PATIENT_FETCH_ERROR(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_EXAM_FETCH_REQUEST                  : return commonReducerAnswer.FORMS_ANSWER_EXAM_FETCH_REQUEST(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_EXAM_FETCH_SUCCESS                  : return commonReducerAnswer.FORMS_ANSWER_EXAM_FETCH_SUCCESS(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_EXAM_FETCH_ERROR                    : return commonReducerAnswer.FORMS_ANSWER_EXAM_FETCH_ERROR(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_SET_SEARCH_EXAM_RESET               : return commonReducerAnswer.FORMS_ANSWER_SET_SEARCH_EXAM_RESET(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_REQUEST      : return commonReducerAnswer.FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_REQUEST(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_SUCCESS      : return commonReducerAnswer.FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_SUCCESS(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_ERROR        : return commonReducerAnswer.FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_ERROR(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_SET_ERROR_FIELD_BY_ID               : return commonReducerAnswer.FORMS_ANSWER_SET_ERROR_FIELD_BY_ID(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_SET_ERROR_FIELD_PATIENT             : return commonReducerAnswer.FORMS_ANSWER_SET_ERROR_FIELD_PATIENT(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_ON_CHANGE_REPORT                    : return commonReducerAnswer.FORMS_ANSWER_ON_CHANGE_REPORT(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_SET_NEW_REPORT_BY_ID                : return commonReducerAnswer.FORMS_ANSWER_SET_NEW_REPORT_BY_ID(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_SET_REMOVE_REPORT_BY_ID             : return commonReducerAnswer.FORMS_ANSWER_SET_REMOVE_REPORT_BY_ID(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_SET_STATUS_BAR_MESSAGE              : return commonReducerAnswer.FORMS_ANSWER_SET_STATUS_BAR_MESSAGE(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_REQUEST      : return commonReducerAnswer.FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_REQUEST(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_SUCCESS      : return commonReducerAnswer.FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_SUCCESS(typesAnswer.SECTION, state, action);
    case typesAnswer.FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_ERROR        : return commonReducerAnswer.FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_ERROR(typesAnswer.SECTION, state, action);

    case typesAnswer.FORMS_ANSWER_SET_ENABLE_HOUR_TABLE               : return commonReducerAnswer.FORMS_ANSWER_SET_ENABLE_HOUR_TABLE(typesAnswer.SECTION, state, action);
    //#endregion
    default: return state;
  }
};
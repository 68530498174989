import { createSelector } from 'reselect';

export default ({
  getField: state => state.modalField.field,
  getOptionsIds: state => state.modalField.field.optionsIds,
  getCheckPoints: state => state.modalField.field.checkPoints,
  getEmptyPointsWarning: state => state.modalField.field.emptyPointsWarning,
  getOptionsIndexed: state => state.modalField.optionsIndexed,
  getOptionsIndexedById: (state, optionId) => state.modalField.optionsIndexed[optionId],
  getDefaultExams: state => state.modalField.defaultExams,
  getContext: state => state.modalField.context,
  getFormOrigin: state => state.modalField.formOrigin,

  getSwipeIndex: createSelector(
    state => state.modalField.swipe.index,
    state => state
  ),
  getFieldDataType: createSelector(
    state => state.modalField.field.dataType,
    state => state
  ),
  getEnableObservation: createSelector(
    state => state.modalField.field.enableObservation,
    state => state
  ),
  getEnablePeriodInterval: createSelector(
    state => state.modalField.field.enablePeriodInterval,
    state => state
  ),
  getEnablePreProgrammed: createSelector(
    state => state.modalField.field.enablePreProgrammed,
    state => state
  ),
  getEnableConfigScore: createSelector(
    state => state.modalField.field.configScore,
    state => state
  ),
  getRefData: createSelector(
    state => state.modalField.field.refData,
    state => state
  ),
  getRangeMin: createSelector(
    state => state.modalField.field.rangeMin,
    state => state
  ),
  getTextInfoRangeMin: createSelector(
    state => state.modalField.field.textInfoMin,
    state => state
  ),
  getTextInfoRangeMax: createSelector(
    state => state.modalField.field.textInfoMax,
    state => state
  ),
  getScoreRangeMin: createSelector(
    state => state.modalField.field.scoreMin,
    state => state
  ),
  getScoreRangeMax: createSelector(
    state => state.modalField.field.scoreMax,
    state => state
  ),
  getQualifier: createSelector(
    state => state.modalField.field.qualifier,
    state => state
  ),
  getQualifierDeficiency: createSelector(
    state => state.modalField.field.qualifierDeficiency,
    state => state
  ),
  getQualifierId: createSelector(
    state => state.modalField.field.qualifierId,
    state => state
  ),
  getQualifierDeficiencyId: createSelector(
    state => state.modalField.field.qualifierDeficiencyId,
    state => state
  ),
  getReferenceField: createSelector(
    state => state.modalField.field.referenceField,
    state => state
  ),
  getDeficiencyCalc: createSelector(
    state => state.modalField.field.deficiencyCalc,
    state => state
  ),
  getTypeReference: createSelector(
    state => state.modalField.field.typeReference,
    state => state
  ),
  getAnalyzeBetter: createSelector(
    state => state.modalField.field.analyzeBetter,
    state => state
  ),
  getTypeScore: createSelector(
    state => state.modalField.field.typeScore,
    state => state
  ),
  getRangeMax: createSelector(
    state => state.modalField.field.rangeMax,
    state => state
  ),
  getTabDisaginerDisabled: createSelector(
    state => state.modalField.tabDesignerDisabled,
    state => state
  ),
  getEnableNewLine: createSelector(
    state => state.modalField.field.enableNewLine,
    state => state
  ),
  getEnableNewPage: createSelector(
    state => state.modalField.field.enableNewPage,
    state => state
  ),
  getEnableInline: createSelector(
    state => state.modalField.field.enableInline,
    state => state
  ),
  getEnableSelectListDesign: createSelector(
    state => state.modalField.field.enableSelectListDesign,
    state => state
  ),
  getFieldSize: createSelector(
    state => state.modalField.field.size,
    state => state
  ),
  getFieldTitle: createSelector(
    state => state.modalField.field.title,
    state => state
  ),
  getFieldTitleDisplay: createSelector(
    state => state.modalField.field.displayName,
    state => state
  ),
  getFieldMeasurementUnit: createSelector(
    state => state.modalField.field.measurementUnit,
    state => state
  ),
  getFieldCodeBlock: createSelector(
    state => state.modalField.field.code,
    state => state
  ),
  getFieldCodeConfig: createSelector(
    state => state.modalField.field.codeConfig,
    state => state
  ),
  getFieldDescription: createSelector(
    state => state.modalField.field.description,
    state => state
  ),
  getApiExamSearch: createSelector(
    state => state.modalField.api.examSearch,
    state => state
  ),
  getIsFetchingExamSearch: createSelector(
    state => state.modalField.fetching.examSearch,
    state => state
  ),
  getIsFetchingSave: createSelector(
    state => state.modalField.fetching.saveField,
    state => state
  ),
  getIsFetchingFieldById: createSelector(
    state => state.modalField.fetching.getFieldById,
    state => state
  ),
  getIsEditLoading: createSelector(
    state => state.modalField.fetching.editField,
    state => state
  ),
  getFetchUpdateFieldModelById: createSelector(
    state => state.modalField.fetching.updateFieldModelById,
    state => state
  ),
  getIsViewOnly: createSelector(
    state => state.modalField.viewOnly,
    state => state
  ),
  getQualifiers: createSelector(
    state => state.modalField.qualifiers,
    state => state
  ),
  getTypePainMapBody: createSelector(
    state => state.modalField.field.typePainMapBody,
    state => state
  ),
});
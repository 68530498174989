import uuidv4 from "uuid/v4";

const position = {
  "top-right": {
    vertical: "top",
    horizontal: "right",
  },
  "top-left": {
    vertical: "top",
    horizontal: "left",
  },
  "bottom-right": {
    vertical: "bottom",
    horizontal: "right",
  },
  "bottom-left": {
    vertical: "bottom",
    horizontal: "left",
  },
};

//#region INITIAL_STATE
const INITIAL_STATE = {
  done: false,
  message: "",
  anchorOrigin: position["bottom-right"],
  autoHideDuration: 1500,
  idMessage: "",
  variant: "default",
};
//#endregion

//#region Actions
export const types = {
  SNACKBAR_SHOW: "SNACKBAR_SHOW",
  SNACKBAR_HIDE: "SNACKBAR_HIDE",
};
//#endregion

//#region Action Creators
let timeout = null;

export const hideSnackbar = () => (dispatch, getState, api) => {
  clearTimeout(timeout);
  dispatch({ type: types.SNACKBAR_HIDE });
};

export const showSnackbar = (data) => (dispatch, getState, api) => {
  dispatch(hideSnackbar());

  clearTimeout(timeout);
  timeout = setTimeout(
    () => dispatch({ type: types.SNACKBAR_SHOW, payload: data }),
    500
  );
};

export const actions = {
  hideSnackbar,
  showSnackbar,
};
//#endregion

//#region Reducers
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SNACKBAR_SHOW: {
      const { message, variant, placement, autoHideDuration, ...rest } =
        action.payload;
      let _variant = variant || "default";
      let _autoHideDuration = _variant === "default" ? 1500 : autoHideDuration;

      return {
        ...state,
        done: true,
        idMessage: uuidv4(),
        message,
        variant: _variant,
        anchorOrigin: position[placement] || position["bottom-right"],
        autoHideDuration: _autoHideDuration,
        ...rest,
      };
    }
    case types.SNACKBAR_HIDE: {
      return {
        ...state,
        done: false,
      };
    }
    default:
      return state;
  }
};
//#endregion

import { types } from "../index";
import { push } from 'connected-react-router';
import { reset }  from 'redux-form';
import * as routes from '@Constants/router';

//#region INITIAL_STATE
export const initialStateSignIn = {
  message: '',
  fetching : false,
  fetchingError: false,
  authenticated: false,
  checkedLocalStorage: false,
  api: {
    signIn: {
      userId    : 0,
      userName  : '',
      token     : '',
      email     : '',
      companys  : []
    }
  },
  validation : {
    email           : false,
    emailFormat     : false,
    password        : false,
    passwordFormat  : false
  }
};
//#endregion

//#region Action Creators
export const setSignInInvalidEmail                = ()    => ({ type: types.LOGIN_SIGNIN_INVALID_EMAIL });
export const setSignInInvalidEmailFormat          = ()    => ({ type: types.LOGIN_SIGNIN_INVALID_EMAIL_FORMAT });
export const setSignInInvalidPassword             = ()    => ({ type: types.LOGIN_SIGNIN_INVALID_PASSWORD });
export const setSignInInvalidPasswordFormat       = ()    => ({ type: types.LOGIN_SIGNIN_INVALID_PASSWORD_FORMAT });
export const setClearFormError                    = ()    => ({ type: types.LOGIN_SIGNIN_CLEAR_FORM_ERROR });
export const setSignInFetchRequest                = ()    => ({ type: types.LOGIN_SIGNIN_FETCH_REQUEST });
export const setSignInFetchError                  = data  => ({ type: types.LOGIN_SIGNIN_FETCH_ERROR, payload: data });
export const setSignInFetchSuccess                = data  => ({ type: types.LOGIN_SIGNIN_FETCH_SUCCESS, payload: data });
export const setSignInAuthenticated               = data  => ({ type: types.LOGIN_SIGNIN_AUTHENTICATED, payload: data });
export const setSignInLogout                      = ()    => ({ type: types.LOGIN_SIGNIN_LOGOUT });
export const setAuth                              = data  => ({ type: types.LOGIN_SET_AUTH, payload: data });
export const setAuthPermission                    = data  => ({ type: types.LOGIN_SET_AUTH_PERMISSION, payload: data });
export const setUserName                          = data  => ({ type: types.LOGIN_SET_USER_NAME, payload: { newName: data } });
 
export const signIn = params => (dispatch, getState, api) => {
  dispatch(setSignInFetchRequest());
  return api.login.signIn(params).then( response => {
    const auth = {...response};
    localStorage.setItem('auth', JSON.stringify(auth));

    dispatch([
      setSignInFetchSuccess(response),
      setAuth({...auth}),
      reset('SignIn'),
      push(routes.ROUTE_MANAGER)
    ]);

  }).catch( error => {
    console.log('[catch]: ', error);

    if (error.response.status === 401 || error.response.status === 402 || error.response.status === 422) {
      dispatch(setSignInFetchError('Login inválido!'));
      return;
    }

    if (error.response.status === 403) {
      dispatch(setSignInFetchError('Usuário sem acesso ao Manager!'));
      return;
    }

    if (error.response.status === 404) {
      dispatch(setSignInFetchError('Usuário inválido!'));
      return;
    }

    dispatch(setSignInFetchError());
  });
};

export const verifyToken = token => (dispatch, getState, api) => {
  return api.login.verifyToken(token).then( data => {
    const state = getState();
    if(data.success) {
      if(!data.userId === state.login.auth.userId) {
        localStorage.removeItem('auth');
        dispatch(setSignInLogout());
        dispatch(push('/'));
        return;
      }

      // const auth = JSON.parse(localStorage.getItem('auth') || "{}");
    } else {
      localStorage.removeItem('auth');
      dispatch(setSignInLogout());
      dispatch(push('/'));
    }
  }).catch( error => {
    localStorage.removeItem('auth');
    dispatch(setSignInLogout());
    dispatch(push('/'))
    console.log('[catch]: ', error);
  });
};

export const actions = {
  setSignInInvalidEmail, setSignInInvalidEmailFormat, setSignInInvalidPassword, 
  setSignInInvalidPasswordFormat, setClearFormError, setSignInFetchRequest, setSignInFetchError, 
  setSignInFetchSuccess, setSignInAuthenticated, setSignInLogout, setAuth, setAuthPermission, setUserName, 
  signIn, verifyToken 
}
//#endregion
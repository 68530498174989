/*eslint-disable no-throw-literal*/
import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

const ACCOUNT = 'account';
const PLAN = 'plan';
const PAYMENTS= 'payments';
const HISTORY = 'history';
const MANAGER = 'manager';
const SEARCH = 'search';
const PACKAGE = 'package';
const PASSWORD = 'password';
const CHANGE = 'change';
const USER = 'user';
const GIVE = 'give';
const COUNT = 'count';
const EMAIL = 'email';
const NAME = 'name';
const ACTIVEUNTIL = 'activeuntil';
const INACTIVATE = 'inactivate'
const ACTIVATE = 'activate'
const WITHACCESS = 'withaccess';
const INVOICE = 'invoice';
const CONFIRMATION_EMAIL = 'confirmation_email';
const CONFIRM_REGISTRATION = 'confirm_registration';

const _get = async props => {
    const { axios, pathUrl } = props;

    try {
        return (await axios.get(pathUrl)).data;
    } catch (error) {
        if (error && error.response && error.response.status)
            throw {
                status: error.response.status,
                userFeedback: error.response.data.userFeedback,
            };
        throw error;
    }
};

const _post = async (axios, pathUrl, params = {}) => {
    try {
        return (await axios.post(pathUrl, params)).data;
    } catch (error) {
        if (error && error.response && error.response.status)
            throw {
                status: error.response.status,
                userFeedback: error.response.data.userFeedback,
                ...error.response.data
            };
        throw error;
    }
};

const _put = async (axios, pathUrl, params = {}) => {
    try {
        return (await axios.put(pathUrl, params)).data;
    } catch (error) {
        if (error && error.response && error.response.status)
            throw {
                status: error.response.status,
                userFeedback: error.response.data.userFeedback,
            };
        throw error;
    }
};

const _delete = async (axios, pathUrl, params = {}) => {
    try {
        return (await axios.delete(pathUrl, params)).data;
    } catch (error) {
        if (error && error.response && error.response.status)
            throw {
                status: error.response.status,
                userFeedback: error.response.data.userFeedback,
            };
        throw error;
    }
};

//#region GET
export const getAccounts = async ({ header }) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}s`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
};

export const getManagers = async ({ header }) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${USER}s/${WITHACCESS}`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
};

export const getUserByEmail = async ({ email, header }) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${USER}/${SEARCH}/${EMAIL}/${email}`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
};

export const getByName = async ({ name, header }) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${SEARCH}/${NAME}/${name}`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
};

export const getUser = async ({ userId, header }) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${USER}/${userId}`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
};

export const getUserCount = async ({ header }) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${USER}/${COUNT}`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
};

export const getPackage = async ({ packageId, header }) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${PACKAGE}/${packageId}`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
};
//#endregion

//#region PUT
export const activateAccount = async params => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}/${ACTIVATE}`;
    const axios = interceptor();
    return await _put(axios, pathUrl, params);
};

export const inactivateAccount = async params => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}/${INACTIVATE}`;
    const axios = interceptor();
    return await _put(axios, pathUrl, params);
};

export const setAccountActiveUntil = async params => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}/${ACTIVEUNTIL}`;
    const axios = interceptor();
    return await _put(axios, pathUrl, params);
};

export const updatePackage = async params => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${PACKAGE}`;
    const axios = interceptor();
    return await _put(axios, pathUrl, params);
};

export const setUserAsManager = async params => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${USER}/${MANAGER}/${GIVE}`;
    const axios = interceptor();
    return await _put(axios, pathUrl, params);
};

export const updateManagerPassword = async (managerId, params) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${managerId}/${PASSWORD}/${CHANGE}`;
    const axios = interceptor();
    return await _put(axios, pathUrl, params);
};

export const updateAndResendConfirmationEmail = async (params) => {
    const { userId } = params
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${USER}/${userId}/${CONFIRMATION_EMAIL}`;
    const axios = interceptor();
    return await _put(axios, pathUrl, params);
};
//#endregion

// #region GET
export const getClientData = async params => {
    const { clientId, header } = params;
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}/${clientId}/${PLAN}/${PAYMENTS}`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
}
export const getClientInvoices = async params => {
    const { header } = params;
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${PAYMENTS}/${HISTORY}`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
}
export const getInvoiceStatus = async params => {
    const { header, accountId, invoiceId } = params;
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}/${accountId}/${INVOICE}/${invoiceId}/status`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
}
export const getPdfInvoice = async params => {
    const { header, accountId, invoiceId } = params;
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}/${accountId}/${INVOICE}/${invoiceId}/pdf`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
}
export const getXmlInvoice = async params => {
    const { header, accountId, invoiceId } = params;
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}/${accountId}/${INVOICE}/${invoiceId}/xml`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
}

export const sendInvoiceToEmail = async params => {
    const { header, accountId, invoiceId } = params;
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}/${accountId}/${INVOICE}/${invoiceId}/email`;
    const axios = interceptor(header);
    return await _get({ axios, pathUrl });
}
//#endregion

//#region POST
export const createUser = async params => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${USER}`;
    const axios = interceptor();
    return _post(axios, pathUrl, params);
}

export const confirmUserRegistration = async (userId) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${USER}/${userId}/${CONFIRM_REGISTRATION}`;
    const axios = interceptor();
    return _post(axios, pathUrl, null);
}

export const createAccount = async params => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}`;
    const axios = interceptor();
    return await _post(axios, pathUrl, params);
}

export const registerManagerUser = async params => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/`;
    const axios = interceptor();
    return await _post(axios, pathUrl, params);
};

export const getClientInvoiceData = async (params) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}/${params.accountId}/invoice/data`;
    const axios = interceptor();
    return await _post(axios, pathUrl, params);
};

export const emitInvoice = async (params) => {
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}/${params.accountId}/invoice/emit`;
    const axios = interceptor();
    return await _post(axios, pathUrl, params);
};

//#endregion

//#region DELETE

export const removeUserManagerAccess = async params => {
    const { userId } = params;
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${userId}`;
    const axios = interceptor();
    return await _delete(axios, pathUrl, params);
};

export const deletePendingRegistrationUser = async params => {
    const { userId } = params;
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${USER}/${userId}`;
    const axios = interceptor();
    return await _delete(axios, pathUrl, params);
};

export const cancelInvoice = async params => {
    const { header, accountId, invoiceId } = params;
    const pathUrl = `${endpoints.API_ACCOUNT}/${MANAGER}/${ACCOUNT}/${accountId}/${INVOICE}/${invoiceId}`;
    const axios = interceptor();
    return await _delete(axios, pathUrl, params);
};

//#endregion

import * as endpoints   from '@App/constants/endpoints';
import axiosRef         from 'axios';
import interceptor      from '../interceptor';
import { post }         from '../util';

const UPLOAD          = 'upload';
const AVATAR          = 'avatar';
const USER            = 'user';
const PATIENT         = 'patient';
const MEDICALRECORD   = 'medicalrecords';
const MEDIA           = 'media';
const ATTACHMENT      = 'attachment';
const WORKSPACE       = 'workspace';
const IMAGE           = 'image';
const DOCS            = 'docs';
const DOCUMENT        = 'document';
const PDF             = 'pdf';
const ORIGINAL        = 'original';
const SIGNATURE       = 'signature';
const AUTHOR          = 'author';
const MANAGER         = 'manager';

//#region POST
export const uploadNewUserAvatar = async ({header, params}) => {
  const { file } = params;
  const formData = new FormData();
  formData.append("file", file); 
  const pathUrl = `${endpoints.API_FILES}/${UPLOAD}/${AVATAR}/${USER}`;
  const axios   = interceptor({
    ...header,
    'Content-Type': 'multipart/form-data'
  });
  return await post(axios, pathUrl, formData);
};

export const uploadAuthorAvatar = async ({header, params}) => {
  const { file } = params;
  const formData = new FormData();
  formData.append("file", file); 
  const pathUrl = `${endpoints.API_FILES}/${UPLOAD}/${AVATAR}/${AUTHOR}`;
  const axios   = interceptor({
    ...header,
    'Content-Type': 'multipart/form-data'
  });
  return await post(axios, pathUrl, formData);
};

export const uploadNewWorkspaceAvatar = async ({header, params}) => {
  const { file } = params;
  const formData = new FormData();
  formData.append("file", file); 
  const pathUrl = `${endpoints.API_FILES}/${UPLOAD}/${AVATAR}/${WORKSPACE}`;
  const axios   = interceptor({
    ...header,
    'Content-Type': 'multipart/form-data'
  });
  return await post(axios, pathUrl, formData);
};

export const uploadImageDocs = async ({header, params}) => {
  const { file } = params;
  const formData = new FormData();
  formData.append("file", file); 
  const pathUrl = `${endpoints.API_FILES}/${UPLOAD}/${MANAGER}/${IMAGE}/${DOCS}`;
  const axios   = interceptor({
    ...header,
    'Content-Type': 'multipart/form-data'
  });
  return await post(axios, pathUrl, formData);
};

export const uploadAttachmentDocs = async ({header, params}) => {
  const { file } = params;
  const formData = new FormData();
  formData.append("file", file); 
  const pathUrl = `${endpoints.API_FILES}/${UPLOAD}/${MANAGER}/${ATTACHMENT}/${DOCS}`;
  const axios   = interceptor({
    ...header,
    'Content-Type': 'multipart/form-data'
  });
  return await post(axios, pathUrl, formData);
};

export const uploadImageSignatureDocs = async ({params}) => {
  const { file, type } = params;
  const formData = new FormData();
  formData.append("file",file);
  const pathUrl = `${endpoints.API_FILES}/${UPLOAD}/${SIGNATURE}/${IMAGE}/${type}`;
  const axios   = interceptor({
    'Content-Type': 'multipart/form-data'
  });
  return await post(axios, pathUrl, formData);
};

export const uploadDocumentPDF = async ({header, params}) => {
  const { file } = params;
  const formData = new FormData();
  formData.append("file", file);
  const pathUrl = `${endpoints.API_FILES}/${UPLOAD}/${DOCUMENT}/${PDF}/${ORIGINAL}`;
  const axios   = interceptor({
    ...header,
    'Content-Type': 'multipart/form-data'
  });
  return await post(axios, pathUrl, formData);
};

export const uploadNewPatientAvatar = async ({header, params}, patientId) => {
  const { file } = params;
  const formData = new FormData();
  formData.append("file", file); 
  const pathUrl = `${endpoints.API_FILES}/${UPLOAD}/${AVATAR}/${PATIENT}/${patientId}`;
  const axios   = interceptor({
    ...header,
    'Content-Type': 'multipart/form-data'
  });
  return await post(axios, pathUrl, formData);
};

export const uploadMedicalRecordByIdMedia = async ({header, params, attendanceId, config, callback}) => {
  const { file }    = params;
  const formData    = new FormData();

  formData.append("file", file); 

  const pathUrl     = `${endpoints.API_FILES}/${UPLOAD}/${MEDICALRECORD}/${attendanceId}/${MEDIA}`;
  const axios       = interceptor({ ...header, 'Content-Type': 'multipart/form-data' });

  const CancelToken = axiosRef.CancelToken;
  const source      = CancelToken.source();

  config.cancelRequest  = source.cancel;
  config.cancelToken    = source.token;

  callback(config);

  return await post(axios, pathUrl, formData, config);
};

export const uploadMedicalRecordByIdAttachment = async ({header, params, attendanceId, config, callback}) => {
  const { file }    = params;
  const formData    = new FormData();

  formData.append("file", file); 

  const pathUrl     = `${endpoints.API_FILES}/${UPLOAD}/${MEDICALRECORD}/${attendanceId}/${ATTACHMENT}`;
  const axios       = interceptor({ ...header, 'Content-Type': 'multipart/form-data' });

  const CancelToken = axiosRef.CancelToken;
  const source      = CancelToken.source();

  config.cancelRequest  = source.cancel;
  config.cancelToken    = source.token;

  callback(config);

  return await post(axios, pathUrl, formData, config);
};
//#endregion
import React, { Component, Fragment }   from 'react';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

import { routeChange }   from '@State/ducks/config';

const enhanceRouter = data => ComponentWrapper => {
  class EnhanceRouter extends Component {

    componentDidMount () {
      this.setNameRouter();
    }

    componentWillReceiveProps (nextProps) {
      this.setNameRouter();
    }

    setNameRouter () {
      this.props.routeChange(data);
    }

    render () {
      return (
        <Fragment>
          <ComponentWrapper { ...this.props } {...data} />
       </Fragment>)
    };
  }

  const mapDispatchToProps  = dispatch => bindActionCreators({ routeChange }, dispatch);

  EnhanceRouter = connect(null, mapDispatchToProps)(EnhanceRouter);

  return EnhanceRouter;
};

export default enhanceRouter;

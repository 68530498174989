import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import { actions as actionsConfig } from '@State/ducks/config/';
import { actions as actionLoginSign } from '@State/ducks/login/signIn';
import { actions as actionLogin } from '@State/ducks/login/';

export default function protectedRouters(Component) {
    class AuthenticatedComponent extends React.Component {
        componentWillMount() {
            const {
                authenticated,
                checkedLocalStorage,
                setSignInAuthenticated,
                setSignInLogout,
                setAuth,
                routeRedirect,
            } = this.props;

            const auth = JSON.parse(localStorage.getItem('auth') || '{}');
            if (
                auth.hasOwnProperty('token') &&
                auth.token.length > 0 &&
                auth.hasOwnProperty('id') && auth.id > 0 &&
                auth.hasOwnProperty('email') && auth.email.length > 0
            ) {
                if (!authenticated && !checkedLocalStorage) {
                    setSignInAuthenticated(auth);
                    setAuth({ ...auth });
                } else if (!authenticated && checkedLocalStorage) {
                    setSignInLogout();
                    routeRedirect('/');
                }
            }
        }

        render() {
            const auth = JSON.parse(localStorage.getItem('auth') || '{}');

            if (
                auth.hasOwnProperty('token') &&
                auth.hasOwnProperty('id') &&
                // && auth.hasOwnProperty('name')
                auth.hasOwnProperty('email')
            ) {
                return (
                    <Fragment>
                        <Component {...this.props} />
                    </Fragment>
                );
            }
            return <Redirect to="/" />;
        }

        componentWillReceiveProps(nextProps) {
            const { verifyToken } = this.props;
            verifyToken(nextProps.token);
        }
    }

    const mapStateToProps = state => ({
        authenticated: state.login.signIn.authenticated,
        token: state.login.signIn.api.signIn.token,
        checkedLocalStorage: state.login.signIn.checkedLocalStorage,
    });

    const mapDispatchToProps = dispatch =>
        bindActionCreators(
            {
                routeRedirect: actionsConfig.routeRedirect,
                setSignInAuthenticated: actionLoginSign.setSignInAuthenticated,
                setAuth: actionLoginSign.setAuth,
                // setSelectedBusiness     : actionStarted.setSelectedBusiness,
                verifyToken: actionLogin.verifyToken,
            },
            dispatch
        );

    AuthenticatedComponent = connect(
        mapStateToProps,
        mapDispatchToProps
    )(AuthenticatedComponent);

    return withRouter(AuthenticatedComponent);
}
